import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import MarkdownViewer from '@/features/markdown-viewer';

type IziCashProps = {
    referenceNumber: string;
    onCopy: (paycode: string, title: string) => void;
}

const IziCash: FC<IziCashProps> = ({ referenceNumber, onCopy }) => {
    const { t } = useTranslation();

    const handleCopyPaycode = useCallback(() => {
        onCopy(referenceNumber, t('views.PaymentMethods.Cash.Izi.voucherCopiedLabel'));
    }, [referenceNumber, onCopy]);

    return (
        <MarkdownViewer>
            {
                [
                    { type: MarkdownViewer.COPY_BLOCK, content: {
                        title: referenceNumber,
                        subtitle: t('views.PaymentMethods.Cash.Izi.referenceTitle'),
                        onClick: handleCopyPaycode,
                    } },
                    { type: MarkdownViewer.ALERT, content: t('views.PaymentMethods.Cash.Izi.instructionStep4') },
                    {
                        type: MarkdownViewer.NUMBERED_LIST,
                        content: [
                            t('views.PaymentMethods.Cash.Izi.instructionStep1'),
                            t('views.PaymentMethods.Cash.Izi.instructionStep2'),
                            t('views.PaymentMethods.Cash.Izi.instructionStep3'),
                        ],
                    },
                ]
            }
        </MarkdownViewer>
    );
};

export default IziCash;
