import styled from 'styled-components';

import { Text16Medium } from '@/shared/typography/text';

export const DefaultInput = styled.input`
  ${Text16Medium};

  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
`;
