import { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FillForm from '@/features/payment-fill-form';
import { FormValues } from '@/lib/domain/forms/forms';
import FormSection from '@/lib/domain/forms/forms/form-section';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { Countries } from '@/lib/locale/countries';
import FormInput from '@/shared/ui/core/form-input';
import SubmitForm from '@/shared/ui/domain/submit-form';

import Resources from '../../../../../../resources';
import { AppData } from '../../../../index';

type YunoSavedCardProps = {
    country: Countries;
    savedDocuments: AppData['paymentInfo']['savedDocuments'];
    panValue: string;
    formFields: FormSection[];
    onSubmit: (values: FormValues) => Promise<GQLErrorType>;
}

const YunoSavedCardView: FC<YunoSavedCardProps> = ({
    country,
    savedDocuments,
    panValue,
    formFields,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const paymentFillFormRef = useRef<FillForm>(null);

    const handleSubmit = useCallback(async () => {
        let data: Record<string, string> = {};

        if (paymentFillFormRef.current) {
            if (!paymentFillFormRef.current.validate()) {
                return;
            }

            data = paymentFillFormRef.current.getData();
        }

        setLoading(true);

        const error = await onSubmit(data);
        if (error) {
            if (paymentFillFormRef.current) {
                paymentFillFormRef.current.setError(error);
            }

            setLoading(false);
        }
    }, [paymentFillFormRef]);

    return (
        <SubmitForm
            loading={loading}
            onSubmit={handleSubmit}
        >
            <FormInput
                disabled
                title={t('shared.ui.domain.CreditCardOutline.panCaption')}
                data-id={Resources.test.existedCard}
                value={panValue}
            />

            {
                formFields.length > 0 ? (
                    <FillForm
                        ref={paymentFillFormRef}
                        country={country}
                        savedDocuments={savedDocuments}
                        fields={formFields}
                    />
                ) : <></>
            }
        </SubmitForm>
    );
};

export default YunoSavedCardView;
