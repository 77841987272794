import { FormValues } from '@/lib/domain/forms/forms';
import {
    BILLING_CITY_ID,
    FULL_BILLING_ADDRESS_ID,
    CITY_ID,
    FULL_ADDRESS_ID,
    CONFIRMATION_CODE_ID,
    EMAIL_ID
} from '@/lib/domain/forms/forms/indentificators';
import { DOCUMENT_ID } from '@/lib/domain/forms/forms/indentificators';
import Credorax from '@/lib/domain/integrations/credorax';
import {
    YunoGenerateTokenOptions,
    YunoInstance,
    YunoSecureFields
} from '@/lib/domain/integrations/yuno/types';
import PaymentMethod  from '@/lib/domain/payments/payment-method';
import { VendorName } from '@/lib/domain/payments/vendors';
import ErrorStatus from '@/lib/errors/status';
import { Countries } from '@/lib/locale/countries';
import AnalyticsService from '@/services/analytics-service';
import NavigatorService from '@/services/navigator-service';
import PaymentInfoService from '@/services/payment-info-service';
import PaymentProviderService from '@/services/payment-provider-service';

import {
    TopupWithCredoraxCardInput
} from '../../graphql/bank-card/credorax/top-up-with-credorax-card';
import { CreateCardDlocalInput } from '../../graphql/bank-card/dlocal/create-card-dlocal';
import { CreateBankTransferDlocalInput } from '../../graphql/bank-transfer/dlocal/create-dlocal-bank-transfer';
import { WooppaySession } from '../../graphql/phone-balance/woopay';
import Resources from '../../resources';

class TopupService {
    private paymentInfoService: PaymentInfoService;
    private analyticService: AnalyticsService;
    private navigatorService: NavigatorService;
    private process: PaymentProviderService['process'];
    private yunoInstance?: YunoInstance;
    private yunoSecureFields?: YunoSecureFields;
    private wooppaySession?: WooppaySession;

    constructor(
        paymentProviderService: PaymentProviderService,
        paymentInfoService: PaymentInfoService,
        analyticService: AnalyticsService,
        navigatorService: NavigatorService,
    ) {
        this.analyticService = analyticService;
        this.paymentInfoService = paymentInfoService;
        this.navigatorService = navigatorService;
        this.process = paymentProviderService.process.bind(paymentProviderService);
    }

    public async topUp(
        amount: string,
        method: PaymentMethod<any>,
        values: FormValues,
        country: Countries,
    ) {
        this.analyticService.event('processing.topup_pay.click', {
            payment_option: method.getPaymentType(),
            provider: method.providerName,
            sum: parseInt(amount, 10),
        });

        if (method.isPayrails()) {
            if (method.isBankCard()) {
                return this.topUpWithPayrailsCard(amount, method, values);
            }

            return this.topupPayrailsGenericMethod(amount, method, values);
        }

        if (method.isCredorax()) {
            return this.topUpWithCredorax(amount, method, values);
        }

        if (method.isDlocal()) {
            if (method.isBankCard()) {
                return this.createCardDlocal(amount, method, values, country);
            }

            if (method.isWallet()) {
                return this.topUpWithDlocalWallet(amount, method, values);
            }

            if (method.isCash()) {
                return this.createDlocalCash(amount, method, values);
            }

            if (method.isBankTransfer()) {
                return this.createDlocalBankTransfer(amount, method, values, country);
            }
        }

        if (method.isYuno()) {
            if (method.isBankCard()) {
                return this.topUpWithYunoBankCard(amount, method, values, country);
            }

            return this.topUpWithYuno(amount, method, values);
        }

        if (method.isBcel()) {
            return this.topupWithBCEL(amount, values);
        }

        if (method.isOneVision()) {
            return this.topupWithOneVision(amount, method, values);
        }

        if (method.isSimpaisa()) {
            return this.topupWithSimpaisa(amount, values);
        }

        if (method.isIyzico()) {
            if (method.isWallet()) {
                return this.topupWithIyzicoWallet(amount, values);
            }

            if (method.isBankCard()) {
                return this.topupWithIyzicoCard(amount, values);
            }
        }

        if (method.isIzi()) {
            if (method.isCash()) {
                return this.topupWithIziCash(amount, values);
            }

            if (method.isBankCard()) {
                return this.topUpWithIziCreditCard(amount);
            }
        }

        if (method.isFawry()) {
            if (method.isCash()) {
                return this.topupWithFawryCash(amount, values);
            }
        }

        if (method.isSlick()) {
            if (method.isBankCard()) {
                return this.topupWithSlick(amount);
            }
        }

        if (method.isWooppay()) {
            if (method.isPhoneBalance()) {
                return this.topupWooppay(values);
            }
        }

        if (method.isNuvei()) {
            if (method.isBankCard()) {
                return this.authorizeWithNuvei(values);
            }
        }

        return undefined;
    }

    private topupWithOneVision = (
        amount: string,
        method: PaymentMethod<VendorName.OneVision>,
        values: FormValues
    ) => {
        return this.process(async (req) => {
            const { data } = await req.topUpWithOneVision({
                amount: parseInt(amount, 10),
                phone: values.phone,
                paymentMethodCode: method.session.paymentMethodCode,
            });

            if (data?.topupWithOneVision.redirectUrl) {
                this.navigatorService.goOutside(data.topupWithOneVision.redirectUrl);

                return;
            }

            if (data?.topupWithOneVision.redirectUrl === '') {
                await this.navigatorService.showFinalPage('error');
            }
        });
    };

    private topupWithSimpaisa = (amount: string, values: FormValues) => {
        return this.process(async (req) => {
            const { data } = await req.topUpWithSimpaisa({
                phone: values.phone,
                amount: parseInt(amount, 10),
            });

            if (data?.topupWithSimpaisa.redirectUrl) {
                this.navigatorService.goOutside(data.topupWithSimpaisa.redirectUrl);

                return;
            }

            if (data?.topupWithSimpaisa.redirectUrl === '') {
                await this.navigatorService.showFinalPage('error');
            }
        });
    };

    private topupWithIyzicoWallet = (amount: string, values: FormValues) => {
        return this.process(async (req) => {
            const { data } = await req.topupWithIyzicoWallet({
                amount: parseInt(amount, 10),
                document: values[DOCUMENT_ID],
                city: values[CITY_ID],
                address: values[FULL_ADDRESS_ID],
                billingCity: values[BILLING_CITY_ID] || values[CITY_ID],
                billingAddress: values[FULL_BILLING_ADDRESS_ID] || values[FULL_ADDRESS_ID],
            });

            if (data) {
                const redirect = data.topupWithIyzicoWallet.redirectUrl;
                this.navigatorService.goOutside(redirect);

                return;
            }

            await this.navigatorService.showFinalPage('error', {
                errorStatus: ErrorStatus.COMMON_INTERNAL_ERROR,
            });
        });
    };

    private topupWithIyzicoCard = (amount: string, values: FormValues) => {
        return this.process(async (req) => {
            const { data } = await req.topupWithIyzicoCard({
                amount: parseInt(amount, 10),
                document: values[DOCUMENT_ID],
                city: values[CITY_ID],
                address: values[FULL_ADDRESS_ID],
                billingCity: values[BILLING_CITY_ID] || values[CITY_ID],
                billingAddress: values[FULL_BILLING_ADDRESS_ID] || values[FULL_ADDRESS_ID],
            });

            if (data) {
                const redirect = data.topupWithIyzicoCard.redirectUrl;
                this.navigatorService.goOutside(redirect);

                return;
            }

            await this.navigatorService.showFinalPage('error', {
                errorStatus: ErrorStatus.COMMON_INTERNAL_ERROR,
            });
        });
    };

    private topupWithIziCash = (amount: string, values: FormValues) => {
        return this.process(async (req) => {
            const { data } = await req.topupWithIziCash({
                email: values[EMAIL_ID],
                amount: parseInt(amount, 10),
                document: values[DOCUMENT_ID],
            });

            if (data?.topupWithIziCash.voucherReference) {
                this.paymentInfoService.setIziCashSessionBeforeDisplay({
                    voucherReference: data.topupWithIziCash.voucherReference,
                });
            }
        });
    };

    private topUpWithIziCreditCard = (amount: string) => {
        return this.process(async (req) => {
            const { data } = await req.topUpWithIziCreditCard({
                amount: parseInt(amount, 10),
            });

            if (data?.topupWithIziCard.redirectUrl) {
                this.navigatorService.goOutside(data.topupWithIziCard.redirectUrl);

                return;
            }

            if (data?.topupWithIziCard.redirectUrl === '') {
                await this.navigatorService.showFinalPage('error');
            }
        });
    };

    private topupWithFawryCash = (amount: string, values: FormValues) => {
        return this.process(async (req) => {
            const { data } = await req.topupWithFawryCash({
                email: values.email,
                amount: parseInt(amount, 10),
            });

            if (data?.topupWithFawryCash) {
                this.paymentInfoService.setFawryCashSessionBeforeDisplay(data.topupWithFawryCash);
            }
        });
    };

    private topupWithSlick = (amount: string) => {
        return this.process(async (req) => {
            const { data } = await req.topupWithSlick({
                amount: parseInt(amount, 10),
            });

            if (data?.topupWithSlick.redirectURL) {
                this.navigatorService.goOutside(data.topupWithSlick.redirectURL);

                return;
            }

            this.navigatorService.showFinalPage('success');
        });
    };

    private topupWooppay = async (values: FormValues) => {
        return this.process(async (req) => {
            if (!this.wooppaySession) {
                return;
            }

            const { data } = await req.topupWooppay({
                session: this.wooppaySession,
                [CONFIRMATION_CODE_ID]: values[CONFIRMATION_CODE_ID],
            });

            if (data?.topupWooppay.result) {
                this.navigatorService.showFinalPage('success');

                return;
            }

            this.navigatorService.showFinalPage('error');
        });
    };

    private authorizeWithNuvei = async (values: FormValues) => {
        return this.process(async (req) => {
            const { data } = await req.authorizeWithNuvei({
                email: values.email,
                paymentID: values.paymentID,
                providerSession: values.providerSession,
                cardToken: values.cardToken,
                cardHolderName: values.cardHolderName,
            });

            if (data) {
                await this.navigatorService.showFinalPage('success');

                return;
            }
        });
    };

    private topupWithBCEL = (amount: string, values: FormValues) => {
        return this.process(async (req) => {
            const { data } = await req.topupWithBCEL({
                amount: parseInt(amount, 10),
                email: values.email,
                city: values.city,
                houseNumber: values.houseNumber,
                state: values.state,
                street: values.street,
                zipCode: values.zipCode,
            });

            if (data?.topupWithBCEL?.parameters) {
                const parameters = data.topupWithBCEL.parameters;

                const form = document.createElement('form');
                form.method = 'POST';
                form.action = Resources.BCEL_ENDPOINT_URL;
                form.style.display = 'none';

                for (const { key, value } of parameters) {
                    const hidenInput = document.createElement('input');
                    hidenInput.type = 'hidden';
                    hidenInput.name = key;
                    hidenInput.value = value;
                    form.appendChild(hidenInput);
                }

                document.body.appendChild(form);
                form.submit();

                return;
            }
        });
    };

    private topUpWithPayrailsCard = (
        amount: string,
        method: PaymentMethod<VendorName.Payrails>,
        values: FormValues
    ) => {
        return this.process(async (req) => {
            if (method.isInstrument()) {
                const { data } = await req.topUpWithSavedPayrailsCard({
                    session: {
                        sessionId: method.session.session,
                        transactionId: method.session.paymentId,
                        amount,
                    },
                    instrumentID: method.session.instrumentId,
                    amount: parseInt(amount, 10),
                    document: values[DOCUMENT_ID],
                    securityCodeToken: values.encryptedData,
                    requestedResult: values.payrailsRequestedResult,
                });

                if (data?.topupWithSavedCardPayrails.redirectUrl) {
                    this.navigatorService.goOutside(
                        data.topupWithSavedCardPayrails.redirectUrl,
                    );

                    return;
                }

                this.navigatorService.showFinalPage('success');
            }

            const payrailsData = values?.numberToken ? {
                numberToken: values.numberToken,
                securityCodeToken: values.securityCodeToken,
                vaultedToken: values.vaultedToken,
            } : {
                collectData: {
                    vaultProviderConfigId: values.vaultProviderConfigId,
                    encryptedData: values.encryptedData,
                },
            };

            const { data } = await req.topUpWithPayrailsCard({
                session: {
                    sessionId: method.session.session,
                    transactionId: method.session.paymentId,
                    amount,
                },
                ...payrailsData,
                document: values[DOCUMENT_ID],
                requestedResult: values.payrailsRequestedResult,
            });

            if (data?.topupWithCardPayrails.redirectUrl) {
                this.navigatorService.goOutside(
                    data.topupWithCardPayrails.redirectUrl,
                );

                return;
            }

            this.navigatorService.showFinalPage('success');
        });
    };

    private topupPayrailsGenericMethod = (
        amount: string,
        method: PaymentMethod<VendorName.Payrails>,
        values: FormValues
    ) => {
        return this.process(async (req) => {
            const { data } = await req.topupPayrailsGenericMethod({
                session: {
                    sessionId: method.session.session,
                    transactionId: method.session.paymentId,
                    amount: amount,
                },
                paymentMethodType: method.getPaymentType(),
                paymentMethodCode: method.session.paymentMethodCode,
                document: values.document || '',
                requestedResult: values.payrailsRequestedResult,
            });

            if (data?.topupPayrailsGenericMethod.redirectUrl) {
                this.navigatorService.goOutside(data.topupPayrailsGenericMethod.redirectUrl);

                return;
            }

            this.navigatorService.showFinalPage('success');
        });
    };

    private topUpWithCredorax = async (
        amount: string,
        method: PaymentMethod<VendorName.Credorax>,
        values: FormValues
    ) => {
        const input: TopupWithCredoraxCardInput = method.savedInstruments?.bankCard ? {
            cardId: method.savedInstruments?.bankCard[0].id,
            amount: parseInt(amount, 10),
            firstName: values.firstName,
            lastName: values.lastName,
            houseNumber: '', // todo rm with backend
        } : {
            amount: parseInt(amount, 10),
            pKeyResponse: JSON.stringify(
                await Credorax.getPKey(
                    {
                        cardNumber: values.cardNumber,
                        expirationMonth: values.expirationMonth,
                        expirationYear: values.expirationYear,
                        cvv: values.cvv,
                        cardHolder: values.cardHolder,
                    },
                    method.session.merchantID ?? '',
                    method.session.staticKey ?? ''
                ),
            ),
            email: values.email,
            zipCode: values.zipCode,
            city: values.city,
            street: values.street,
            houseNumber: values.houseNumber,
            firstName: values.cardHolder.split(' ')[0],
            lastName: values.cardHolder.split(' ')[1],
        };

        return this.process(async (req) => {
            await req.topUpWithCredorax(input);

            this.navigatorService.showFinalPage('success');
        });
    };

    private createCardDlocal = (
        amount: string,
        method: PaymentMethod<VendorName.Dlocal>,
        values: FormValues,
        country: Countries
    ) => {
        let input: CreateCardDlocalInput = {
            token: values.token,
            amount: parseInt(amount, 10),
            cardHolderName: values.cardHolderName,
            email: values.email,
            document: values.document,
        };

        if (country === Countries.India || country === Countries.SouthAfrica) {
            input.state = values.state;
            input.city = values.city;
            input.zipCode = values.zipCode;
            input.street = values.street;
            input.houseNumber = values.houseNumber;
        }

        if (method.savedInstruments?.bankCard) {
            input = {
                cardId:method.savedInstruments.bankCard[0].id,
                amount: parseInt(amount, 10),
                document: values.document,
                email: values.email,
                token: country === Countries.India ? values.token : undefined,
            };
        }

        return this.process(async (req) => {
            const { data } = await req.createCardDlocal(input);

            if (data?.createCardDlocal.threeDSecureRedirectUrl === '') {
                await this.navigatorService.showFinalPage('success');

                return;
            }

            if (data?.createCardDlocal.threeDSecureRedirectUrl) {
                this.navigatorService.goOutside(data?.createCardDlocal?.threeDSecureRedirectUrl);
            }
        });
    };

    private topUpWithDlocalWallet = (
        amount: string,
        method: PaymentMethod<VendorName.Dlocal>,
        values: FormValues
    ) => {
        return this.process(async (req) => {
            const { data } = await req.topUpWithDlocalWallet({
                ...values,
                username: values.username,
                email: values.email,
                document: values.document,
                amount: parseInt(amount, 10),
                paymentMethodCode: method.session.paymentMethodCode,
            });

            if (data?.topupWithDlocalWallet?.redirectUrl === '') {
                this.navigatorService.showFinalPage('success');

                return;
            }

            if (data?.topupWithDlocalWallet?.redirectUrl) {
                this.navigatorService.goOutside(data.topupWithDlocalWallet.redirectUrl);
            }
        });
    };

    private createDlocalCash = (
        amount: string,
        method: PaymentMethod<VendorName.Dlocal>,
        values: FormValues
    ) => {
        return this.process(async (req) => {
            const { data } = await req.createDlocalCash({
                ...values,
                username: values.username,
                email: values.email,
                document: values.document,
                amount: parseInt(amount, 10),
                paymentMethodCode: method.session.paymentMethodCode,
            });

            if (data?.createCashDlocal?.redirectUrl === '') {
                await this.navigatorService.showFinalPage('success');
            }

            if (data?.createCashDlocal?.redirectUrl) {
                await this.navigatorService.goOutside(data.createCashDlocal.redirectUrl);
            }
        });
    };

    private createDlocalBankTransfer = (
        amount: string,
        method: PaymentMethod<VendorName.Dlocal>,
        values: FormValues,
        country: Countries
    ) => {
        return this.process(async (req) => {
            const input: CreateBankTransferDlocalInput = {
                ...values,
                username: values.username,
                email: values.email,
                document: values.document,
                amount: parseInt(amount, 10),
                paymentMethodCode: method.session.paymentMethodCode,
            };

            if (country === Countries.SouthAfrica) {
                input.state = values.state;
                input.city = values.city;
                input.zipCode = values.zipCode;
                input.street = values.street;
                input.houseNumber = values.houseNumber;
            }

            const { data } = await req.createDlocalBankTransfer(input);

            if (data?.createBankTransferDlocal?.redirectUrl === '') {
                await this.navigatorService.showFinalPage('success');
            }

            if (data?.createBankTransferDlocal?.redirectUrl) {
                await this.navigatorService.goOutside(data.createBankTransferDlocal.redirectUrl);
            }
        });
    };

    public setYunoInstance = (instance: YunoInstance) => {
        this.yunoInstance = instance;
    };

    public setYunoSecureFields = (secureFields: YunoSecureFields) => {
        this.yunoSecureFields = secureFields;
    };

    public setWooppaySession = (session?: WooppaySession) => {
        this.wooppaySession = session;
    };

    private topUpWithYunoBankCard = (
        amount: string,
        method: PaymentMethod<VendorName.Yuno>,
        values: FormValues,
        country: Countries,
    ) => {
        return this.process(async (req) => {
            if (!this.yunoInstance || !this.yunoSecureFields) {
                // todo throw proper message
                return;
            }

            const customer: YunoGenerateTokenOptions['customer'] = {};

            if (
                country === Countries.Brazil ||
                country === Countries.Colombia ||
                country === Countries.Argentina
            ) {
                customer.document = {
                    document_number: values.document,
                };
            }

            if (
                country === Countries.Mexico ||
                country === Countries.Peru ||
                country === Countries.Chile ||
                country === Countries.Panama
            ) {
                customer.email = values.email;
                customer.first_name = values.firstName;
                customer.last_name = values.lastName;
            }

            const creditCardPayload: YunoGenerateTokenOptions = method.isRemovable() ? {
                _: 'saved',
                vaultedToken: method.session.vaultedToken ?? '',
                customer,
            } : {
                _: 'new',
                cardHolderName: values.cardHolderName,
                saveCard: true,
                customer
            };

            const oneTimeToken = await this.yunoSecureFields.generateToken(creditCardPayload);

            const { data } = await req.topUpWithYuno({
                methodName: method.session.methodName,
                vaultedToken: method.session.vaultedToken,
                session: method.session.sessionToken,
                paymentMethodCode: method.paymentMethodCode || '',
                amount: parseInt(amount, 10),
                email: values.email,
                oneTimeToken,
            });

            if (data?.topupWithYuno.sdkActionIsRequired) {
                this.yunoInstance.continuePayment();

                return;
            }

            this.navigatorService.showFinalPage('success');
        });
    };

    private topUpWithYuno = (
        amount: string,
        method: PaymentMethod<VendorName.Yuno>,
        values: FormValues,
    ) => {
        return this.process(async (req) => {
            const { data } = await req.topUpWithYuno({
                methodName: method.session.methodName,
                vaultedToken: method.session.vaultedToken,
                session: method.session.sessionToken,
                paymentMethodCode: method.paymentMethodCode || '',
                amount: parseInt(amount, 10),
                email: values.email,
                oneTimeToken: values.oneTimeToken,
            });

            if (!this.yunoInstance) {
                // todo throw proper message
                return;
            }

            if (data?.topupWithYuno.sdkActionIsRequired) {
                this.yunoInstance.continuePayment();

                return;
            }

            this.navigatorService.showFinalPage('success');
        });
    };
}

export default TopupService;
