import { ThemeProvider } from '@indriver/nova';
import * as React from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import DevPanel from '@/features/dev-panel';
import AppResources from '@/services/app-resources/context';
import AuthorizationService from '@/services/authorization-service';
import ConfigurationService from '@/services/configuration-service';
import LogsService from '@/services/logs-service';
import MobilePlatformService from '@/services/mobile-platform-service';
import AppLayout from '@/shared/ui/layout/app-layout';

import AppRouter from './singlepage/router';

import '@indriver/yrel/dist/fonts/noto.css';
import '@indriver/yrel/dist/theme-light/theme.css';
import '@indriver/yrel/dist/theme-dark/theme.css';
import './shared/globals.css';

class AppRoot {
    private logsService: LogsService;
    private mobilePlatformService: MobilePlatformService;
    private authorizationService: AuthorizationService;
    private configurationService: ConfigurationService;

    constructor(
        logsService: LogsService,
        mobilePlatformService: MobilePlatformService,
        authorizationService: AuthorizationService,
        configurationService: ConfigurationService,
    ) {
        this.logsService = logsService;
        this.mobilePlatformService = mobilePlatformService;
        this.authorizationService = authorizationService;
        this.configurationService = configurationService;
    }

    public render() {
        let appNode = window.document.querySelector<Element>('#app');
        if (appNode === null) {
            this.logsService.write('app does not exist');

            appNode = window.document.createElement('div');
            appNode.id = 'app';
            document.body.appendChild(appNode);
        }

        React.createRoot(appNode).render(
            <>
                <DevPanel
                    confugurationService={this.configurationService}
                    authorizationService={this.authorizationService}
                    logsService={this.logsService}
                />

                <AppResources>
                    <ThemeProvider>
                        <BrowserRouter>
                            <AppLayout>
                                <AppRouter
                                    mobilePlatformService={this.mobilePlatformService}
                                    logsService={this.logsService}
                                    authorizationService={this.authorizationService}
                                    configurationService={this.configurationService}
                                />
                            </AppLayout>
                        </BrowserRouter>
                    </ThemeProvider>
                </AppResources>
            </>
        );
    }
}

export default AppRoot;
