import * as Sentry from '@sentry/react';

import LogsService from '@/services/logs-service';

import { VendorName } from '../vendors';

import Resources from '../../../../resources';

type VendorSdkMap = {
    [VendorName.InDriver]: typeof window.indriveSecure.collect;
    [VendorName.Dlocal]: typeof window.dlocal;
    [VendorName.Yuno]: typeof window.Yuno.initialize;
    [VendorName.Payrails]: any;
    [VendorName.Nuvei]: typeof window.SafeCharge;
}

class SDK {
    private logsService: LogsService;

    constructor(logsService: LogsService) {
        this.logsService = logsService;
    }

    static sources: Record<VendorName, string> = {
        [VendorName.InDriver]: Resources.INDRIVER_SECURE_SDK,
        [VendorName.Dlocal]: Resources.DLOCAL_SDK_URL,
        [VendorName.Yuno]: Resources.YUNO_SDK,
        [VendorName.Nuvei]: Resources.NUVEI_SDK.URL,
        [VendorName.Credorax]: '',
        [VendorName.Slick]: '',
        [VendorName.Puntoxpress]: '',
        [VendorName.Fawry]: '',
        [VendorName.KaspiKz]: '',
        [VendorName.QiwiKz]: '',
        [VendorName.Fortumo]: '',
        [VendorName.Wooppay]: '',
        [VendorName.Kassa24]: '',
        [VendorName.Payrails]: '',
        [VendorName.Gifty]: '',
        [VendorName.ImePay]: '',
        [VendorName.Iyzico]: '',
        [VendorName.Aamarpay]: '',
        [VendorName.Izi]: '',
        [VendorName.OneVision]: '',
        [VendorName.Simpaisa]: '',
        [VendorName.Onoi]: '',
        [VendorName.Bcel]: '',
        [VendorName.JetPay]: '',
    };

    private getSdkResolver = (vendor: VendorName): () => boolean => {
        switch (vendor) {
            case VendorName.InDriver:
                return () => Boolean(window?.indriveSecure?.apiReady);
            case VendorName.Nuvei:
                return () => Boolean(window?.SafeCharge);
            case VendorName.Dlocal:
                return () => Boolean(window?.dlocal);
            case VendorName.Yuno:
                return () => Boolean(window?.Yuno?.initialize);
            default:
                return () => true;
        }
    };

    private waitFor = (vendor: VendorName, fn: () => boolean, periodCheck = 150): Promise<boolean> =>
        new Promise((resolve, reject) => {
            const timeoutOfRefresh = setTimeout(() => {
                reject(`Timeout of SDK ${vendor}`);
            }, 59_000);

            const repeat = (count = 0): void => {
                if (fn()) {
                    clearTimeout(timeoutOfRefresh);
                    resolve(true);

                    return;
                }

                setTimeout(() => {
                    repeat(count + 1);
                }, periodCheck);
            };

            repeat();
        });

    private getSdkAwaiter = (vendor: VendorName): Promise<boolean> => {
        return this.waitFor(vendor, this.getSdkResolver(vendor));
    };

    private appendScript = (vendor: VendorName): void => {
        const scriptSrc = SDK.sources[vendor] || '';

        const alreadyInjected = document.head.querySelector(`script[src="${scriptSrc}"]`);
        if (alreadyInjected) {
            return;
        }

        const scriptNode = window.document.createElement('script');
        scriptNode.src = scriptSrc;
        scriptNode.async = true;

        const firstScriptNode = window.document.head.querySelector('script');

        if (firstScriptNode?.parentNode) {
            firstScriptNode.parentNode.insertBefore(scriptNode, firstScriptNode);
        }
    };

    public async get<K extends keyof VendorSdkMap>(vendor: K): Promise<VendorSdkMap[K]> {
        if (vendor === VendorName.Payrails) {
            return await import('@payrails/web-sdk');
        }

        this.appendScript(vendor);

        const isReady = await this.getSdkAwaiter(vendor);

        if (!isReady) {
            const msg = `${vendor} sdk is not ready`;
            this.logsService.write(msg);
            Sentry.captureMessage(msg);
        }

        switch (vendor) {
            case VendorName.InDriver:
                return window.indriveSecure.collect;
            case VendorName.Dlocal:
                return window.dlocal;
            case VendorName.Nuvei:
                return window.SafeCharge;
            case VendorName.Yuno:
                return window.Yuno.initialize.bind(window.Yuno);
            default:
                return null;
        }
    }
}

export default SDK;
