import { DoneFilled } from '@indriver/mireska';
import { variables } from '@indriver/yrel';
import { Component, RefObject } from 'react';

import * as UI from './ui';

export type CallbackOptions = {
    timeout?: number;
    caption: string;
    onClick?: VoidFunction,
}

export type NotificationDesignType = 'success'|'alert';

type NotificationViewProps = {
    ref: RefObject<NotificationView>;
    text: string;
    design: NotificationDesignType;
    callbackOptions?: CallbackOptions;
    onClose: VoidFunction;
}

type NotificationViewState = {
    exitAnimation: boolean;
}

class NotificationView extends Component<NotificationViewProps, NotificationViewState> {
    private static EXIT_TIMEOUT_MS = 250;
    state = {
        exitAnimation: false
    };
    constructor(props: NotificationViewProps) {
        super(props);
    }

    public close = () => {
        this.setState({ exitAnimation: true });

        setTimeout(() => {
            this.props.onClose();
        }, NotificationView.EXIT_TIMEOUT_MS);
    };

    private getContentProps() {
        switch (this.props.design) {
            case 'alert':
                return {
                    label: 'alert',
                    icon: <DoneFilled size={24} />,
                    color: variables['extensions-background-error']
                };
            case 'success':
            default:
                return {
                    label: 'success',
                    icon: <DoneFilled size={24} />,
                    color: variables['extensions-background-inverseprimary']
                };
        }
    }

    private handleClick = () => {
        if (this.props.callbackOptions?.onClick) {
            this.props.callbackOptions.onClick();
        }

        this.close();
    };

    public render = () => {
        const contentProps = this.getContentProps();

        return (
            <UI.Wrapper
                exittimeoutms={NotificationView.EXIT_TIMEOUT_MS}
                exitanimation={this.state.exitAnimation ? 1 : 0}
            >
                <UI.Content color={contentProps.color}>
                    <UI.Text>
                        <UI.Icon>
                            {contentProps.icon}
                        </UI.Icon>

                        {this.props.text}
                    </UI.Text>

                    {
                        this.props.callbackOptions?.onClick? (
                            <UI.Button onClick={this.handleClick}>
                                {this.props.callbackOptions.caption}
                            </UI.Button>
                        ): null
                    }
                </UI.Content>
            </UI.Wrapper>
        );
    };
}

export default NotificationView;
