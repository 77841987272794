import { Button } from '@indriver/nova';
import i18n from 'i18next';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import PaymentMethod  from '@/lib/domain/payments/payment-method';
import DisplayedError from '@/lib/errors/displayed-error';
import ErrorStatus from '@/lib/errors/status';
import { PaymentInfo } from '@/services/payment-info-service';

import InternalErrorPage from '../../internal-error-page';

import GenericCheckoutController from './checkouts/generic-checkout';
import YunoCheckoutController from './checkouts/yuno-checkout/controller';
import CredoraxBankCardController from './credorax-bank-card/controller';
import DlocalBankCardController from './dlocal-bank-card/controller';
import NuveiBankCardController from './nuvei-bank-card/controller';
import PayrailsBankCardController from './payrails-bank-card/controller';
import * as UI from './ui';
import WooppayPhoneTransferController from './wooppay-phone-balance/controller';
import YunoBankCardController from './yuno-bank-card/controller';

import Resources from '../../../../../resources';
import { AppData } from '../../../index';

export type PaymentFormProps = {
    amount: string;
    method: PaymentMethod<any>;
    hideGoBack: boolean;
    paymentInfo: PaymentInfo;
    services: AppData['services'];
    onGoBack: VoidFunction;
    onHideGoBack: VoidFunction;
} & WithTranslation;

class PaymentForm extends Component<PaymentFormProps> {
    public componentDidMount() {
        this.props.services.navigatorService.showNativeBackArrow();
    }

    private renderForm() {
        const { method } = this.props;

        if (method.isDlocal()) {
            if (method.isBankCard()) {
                return (
                    <DlocalBankCardController
                        amount={this.props.amount}
                        method={method}
                        paymentInfo={this.props.paymentInfo}
                        topupService={this.props.services.topupService}
                        analyticService={this.props.services.analyticService}
                        navigatorService={this.props.services.navigatorService}
                        logsService={this.props.services.logsService}
                    />
                );
            }
        }

        if (method.isYuno()) {
            if (method.isBankCard()) {
                return (
                    <YunoBankCardController
                        amount={this.props.amount}
                        method={method}
                        paymentInfo={this.props.paymentInfo}
                        topupService={this.props.services.topupService}
                        logsService={this.props.services.logsService}
                        onHideGoBack={this.props.onHideGoBack}
                    />
                );
            }

            return (
                <YunoCheckoutController
                    amount={this.props.amount}
                    method={method}
                    paymentInfo={this.props.paymentInfo}
                    topupService={this.props.services.topupService}
                    logsService={this.props.services.logsService}
                    navigatorService={this.props.services.navigatorService}
                    onHideGoBack={this.props.onHideGoBack}
                />
            );
        }

        if (method.isCredorax()) {
            if (method.isBankCard()) {
                return (
                    <CredoraxBankCardController
                        amount={this.props.amount}
                        method={method}
                        paymentInfo={this.props.paymentInfo}
                        topupService={this.props.services.topupService}
                        analyticService={this.props.services.analyticService}
                    />
                );
            }
        }

        if (method.isPayrails()) {
            if (method.isBankCard()) {
                return (
                    <PayrailsBankCardController
                        amount={this.props.amount}
                        method={method}
                        paymentInfo={this.props.paymentInfo}
                        topupService={this.props.services.topupService}
                        navigatorService={this.props.services.navigatorService}
                        logsService={this.props.services.logsService}
                        viewService={this.props.services.viewService}
                        configurationService={this.props.services.configurationService}
                        paymentProviderService={this.props.services.paymentProviderService}
                    />
                );
            }
        }

        if (method.isNuvei()) {
            if (method.isBankCard()) {
                return (
                    <NuveiBankCardController
                        amount={this.props.amount}
                        method={method}
                        paymentInfo={this.props.paymentInfo}
                        topupService={this.props.services.topupService}
                        navigatorService={this.props.services.navigatorService}
                        logsService={this.props.services.logsService}
                        configurationService={this.props.services.configurationService}
                        paymentProviderService={this.props.services.paymentProviderService}
                    />
                );
            }
        }

        if (method.isWooppay()) {
            if (method.isPhoneBalance()) {
                return (
                    <WooppayPhoneTransferController
                        amount={this.props.amount}
                        method={method}
                        paymentInfo={this.props.paymentInfo}
                        topupService={this.props.services.topupService}
                        paymentProviderService={this.props.services.paymentProviderService}
                    />
                );
            }
        }

        if (method.haveNoFields()) {
            return this.renderErrorForm();
        }

        return (
            <GenericCheckoutController
                amount={this.props.amount}
                method={method}
                paymentInfo={this.props.paymentInfo}
                topupService={this.props.services.topupService}
            />
        );
    }

    private renderErrorForm() {
        const { props } = this;

        return (
            <InternalErrorPage
                navigatorService={props.services.navigatorService}
                error={
                    new DisplayedError({
                        title: i18n.t('lib.errors.unsupportedPaymentMethodLabel'),
                        messages: [
                            i18n.t('lib.errors.unsupportedPaymentMethodMessage', {
                                method: props.method.methodName
                            }),
                        ],
                        errorStatus: ErrorStatus.COMMON_INTERNAL_ERROR,
                    })
                }
            />
        );
    }

    public render() {
        return (
            <>
                <UI.Wrapper>
                    {this.renderForm()}
                </UI.Wrapper>

                {
                    !this.props.hideGoBack ? (
                        <UI.GoBackWrapper>
                            <Button
                                data-id={Resources.test.backButton}
                                design='secondary'
                                type='submit'
                                onClick={this.props.onGoBack}
                            >
                                {this.props.t('shared.ui.domain.SubmitForm.goBackCaption')}
                            </Button>
                        </UI.GoBackWrapper>
                    ) : null
                }
            </>
        );
    }
}

export default withTranslation()(PaymentForm);
