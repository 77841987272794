import i18n from 'i18next';

import FormField from '@/lib/domain/forms/forms/form-field';
import FormSection from '@/lib/domain/forms/forms/form-section';
import Text from '@/lib/format/text';
import Validation from '@/lib/forms/validation';
import { Countries } from '@/lib/locale/countries';
import ConfigurationService from '@/services/configuration-service';

import PaymentMethod from '../../payments/payment-method';

interface FieldConfig {
    id: string;
    section: string;
    required?: boolean;
    size?: [number, number][];
    mask?: string;
    formatter?: string;
    pattern?: 'email' | 'zip';
    hidden?: boolean;
}

class Fields {
    private configurationService: ConfigurationService;

    constructor(configurationService: ConfigurationService) {
        this.configurationService = configurationService;
    }

    public getFormFieldsConfig = (
        method: PaymentMethod<any>,
        country: Countries,
    ): FieldConfig[] => {
        if (method.isPayrails()) {
            const hidden = this.configurationService.isProd();

            if (method.isBankCard()) {
                return [
                    {
                        id: 'document',
                        section: 'personalDetails',
                        required: true,
                        size: [[5, 20]]
                    },
                    {
                        id: 'payrailsRequestedResult',
                        section: 'debugSection',
                        hidden,
                    }
                ];
            }

            switch (country) {
                case Countries.Egypt:
                    return [
                        {
                            id: 'document',
                            section: 'personalDetails',
                            required: true,
                            mask: '00000000000000',
                            size: [[14, 14]]
                        },
                        {
                            id: 'payrailsRequestedResult',
                            section: 'debugSection',
                            hidden,
                        }
                    ];
                case Countries.Pakistan:
                    return [
                        {
                            id: 'document',
                            section: 'personalDetails',
                            required: true,
                            mask: '00000-0000000-0',
                            size: [[13, 13]]
                        },
                        {
                            id: 'payrailsRequestedResult',
                            section: 'debugSection',
                            hidden,
                        }
                    ];
                case Countries.India:
                    return [
                        {
                            id: 'document',
                            section: 'personalDetails',
                            required: true,
                            mask: 'aaaaa0000a',
                            size: [[10, 10]],
                        },
                        {
                            id: 'payrailsRequestedResult',
                            section: 'debugSection',
                            hidden,
                        }
                    ];
                default:
                    return [
                        {
                            id: 'payrailsRequestedResult',
                            section: 'debugSection',
                            hidden,
                        }
                    ];
            }
        }

        if (method.isNuvei()) {
            return [
                {
                    id: 'email',
                    section: 'personalDetails',
                    formatter: 'replaceIndriverEmail',
                    pattern: 'email',
                    required: true,
                }
            ];
        }

        if (method.isIyzico()) {
            return [
                {
                    id: 'document',
                    section: 'personalDetails',
                    required: true,
                    mask: '00000000000',
                    size: [[11, 11]]
                },
                {
                    id: 'city',
                    section: 'homeAddress',
                    required: true,
                    size: [[1, 50]],
                },
                {
                    id: 'address',
                    section: 'homeAddress',
                    required: true,
                    size: [[1, 50]],
                }
            ];

            // todo additional
            return [
                {
                    id: 'billingCity',
                    section: 'billingAddress',
                    required: true,
                    size: [[1, 50]],
                },
                {
                    id: 'billingAddress',
                    section: 'billingAddress',
                    required: true,
                    size: [[1, 50]],
                }
            ];
        }

        if (method.isSimpaisa()) {
            if (method.isProviderControlled()) {
                return [
                    {
                        id: 'phone',
                        section: 'personalDetails',
                        mask: '+{0}00000000000000',
                        required: true,
                    }
                ];
            }

            return [];
        }

        if (method.isWooppay()) {
            if (method.isPhoneBalance()) {
                if (country === Countries.Kazakhstan) {
                    return [
                        {
                            id: 'phone',
                            section: 'personalDetails',
                            mask: '+{7}(000)000-00-00',
                            required: true,
                        }
                    ];
                }

                return [
                    {
                        id: 'phone',
                        section: 'personalDetails',
                        required: true,
                    }
                ];
            }

            return [];
        }

        if (method.isDlocal()) {
            if (method.isBankCard()) {
                if (method.savedInstruments?.bankCard) {
                    if (country === Countries.SouthAfrica) {
                        return [
                            {
                                id: 'email',
                                section: 'personalDetails',
                                formatter: 'replaceIndriverEmail',
                                pattern: 'email',
                                required: true,
                            }
                        ];
                    }

                    return [
                        {
                            id: 'email',
                            section: 'personalDetails',
                            formatter: 'replaceIndriverEmail',
                            pattern: 'email',
                            required: true,
                        },
                        {
                            id: 'document',
                            section: 'personalDetails',
                            required: true,
                            ...this.getDlocalDocumentParametersBy(country),
                        },
                    ];
                }

                switch (country) {
                    case Countries.India:
                        return [
                            {
                                id: 'email',
                                section: 'personalDetails',
                                formatter: 'replaceIndriverEmail',
                                required: true,
                                pattern: 'email',
                            },
                            {
                                id: 'document',
                                section: 'personalDetails',
                                required: true,
                                ...this.getDlocalDocumentParametersBy(country),
                            },
                            {
                                id: 'state',
                                section: 'billingAddress',
                                required: true,
                                size: [[3, 50]],
                            },
                            {
                                id: 'city',
                                section: 'billingAddress',
                                required: true,
                                size: [[3, 50]],
                            },
                            {
                                id: 'street',
                                section: 'billingAddress',
                                required: true,
                                size: [[3, 50]],
                            },
                            {
                                id: 'houseNumber',
                                section: 'billingAddress',
                                mask: 'Number',
                                required: true,
                                size: [[1, 16]],
                            },
                            {
                                id: 'zipCode',
                                section: 'billingAddress',
                                required: true,
                                pattern: 'zip',
                                size: [[1, 9]],
                            },
                        ];
                    case Countries.SouthAfrica:
                        if (method.isCustomerSaved) {
                            return [
                                {
                                    id: 'email',
                                    section: 'personalDetails',
                                    formatter: 'replaceIndriverEmail',
                                    required: true,
                                    pattern: 'email',
                                },
                            ];
                        }

                        return [
                            {
                                id: 'email',
                                section: 'personalDetails',
                                formatter: 'replaceIndriverEmail',
                                required: true,
                                pattern: 'email',
                            },
                            {
                                id: 'state',
                                section: 'billingAddress',
                                required: true,
                                size: [[3, 50]],
                            },
                            {
                                id: 'city',
                                section: 'billingAddress',
                                required: true,
                                size: [[3, 50]],
                            },
                            {
                                id: 'street',
                                section: 'billingAddress',
                                required: true,
                                size: [[3, 50]],
                            },
                            {
                                id: 'houseNumber',
                                section: 'billingAddress',
                                mask: 'Number',
                                required: true,
                                size: [[1, 16]],
                            },
                            {
                                id: 'zipCode',
                                section: 'billingAddress',
                                required: true,
                                pattern: 'zip',
                                size: [[1, 9]],
                            },
                        ];
                    case Countries.Morocco:
                        return [
                            {
                                id: 'email',
                                section: 'personalDetails',
                                formatter: 'replaceIndriverEmail',
                                required: true,
                                pattern: 'email',
                            },
                            {
                                id: 'document',
                                section: 'personalDetails',
                                required: true,
                                ...this.getDlocalDocumentParametersBy(country),
                            },
                        ];
                    default:
                        return [
                            {
                                id: 'email',
                                section: 'personalDetails',
                                formatter: 'replaceIndriverEmail',
                                required: true,
                                pattern: 'email',
                            },
                            {
                                id: 'document',
                                section: 'personalDetails',
                                required: true,
                                ...this.getDlocalDocumentParametersBy(country),
                            },
                        ];
                }
            }

            if (method.isWallet() || method.isBankTransfer()) {
                if (country === Countries.SouthAfrica) {
                    if (method.isCustomerSaved) {
                        return [
                            {
                                id: 'username',
                                section: 'personalDetails',
                                required: true,
                                size: [[3, 255]],
                            },
                            {
                                id: 'email',
                                section: 'personalDetails',
                                required: true,
                                pattern: 'email',
                            },
                        ];
                    }

                    return [
                        {
                            id: 'username',
                            section: 'personalDetails',
                            required: true,
                            size: [[3, 255]],
                        },
                        {
                            id: 'email',
                            section: 'personalDetails',
                            formatter: 'replaceIndriverEmail',
                            required: true,
                            pattern: 'email',
                        },
                        {
                            id: 'state',
                            section: 'billingAddress',
                            required: true,
                            size: [[3, 50]],
                        },
                        {
                            id: 'city',
                            section: 'billingAddress',
                            required: true,
                            size: [[3, 50]],
                        },
                        {
                            id: 'street',
                            section: 'billingAddress',
                            required: true,
                            size: [[3, 50]],
                        },
                        {
                            id: 'houseNumber',
                            section: 'billingAddress',
                            mask: 'Number',
                            required: true,
                            size: [[1, 16]],
                        },
                        {
                            id: 'zipCode',
                            section: 'billingAddress',
                            required: true,
                            pattern: 'zip',
                            size: [[1, 9]],
                        },
                    ];
                } else {
                    return [
                        {
                            id: 'username',
                            section: 'personalDetails',
                            required: true,
                            size: [[3, 255]],
                        },
                        {
                            id: 'email',
                            section: 'personalDetails',
                            formatter: 'replaceIndriverEmail',
                            required: true,
                            pattern: 'email',
                        },
                        {
                            id: 'document',
                            section: 'personalDetails',
                            required: true,
                            ...this.getDlocalDocumentParametersBy(country),
                        },
                    ];
                }
            }

            if (method.isCash()) {
                if (country === Countries.Morocco) {
                    return [
                        {
                            id: 'username',
                            section: 'personalDetails',
                            required: true,
                            size: [[3, 255]],
                        },
                        {
                            id: 'email',
                            section: 'personalDetails',
                            formatter: 'replaceIndriverEmail',
                            required: true,
                            pattern: 'email',
                        },
                    ];
                }

                return [
                    {
                        id: 'username',
                        section: 'personalDetails',
                        required: true,
                        size: [[3, 255]],
                    },
                    {
                        id: 'email',
                        section: 'personalDetails',
                        formatter: 'replaceIndriverEmail',
                        required: true,
                        pattern: 'email',
                    },
                    {
                        id: 'document',
                        section: 'personalDetails',
                        required: true,
                        ...this.getDlocalDocumentParametersBy(country),
                    },
                ];
            }

            return [];
        }

        if (method.isCredorax()) {
            if (method.isBankCard()) {
                if (method.savedInstruments?.bankCard) {
                    return [
                        {
                            id: 'firstName',
                            section: 'personalDetails',
                            required: true,
                            size: [[3, 255]],
                        },
                        {
                            id: 'lastName',
                            section: 'personalDetails',
                            required: true,
                            size: [[3, 255]],
                        }
                    ];
                }

                return [
                    {
                        id: 'email',
                        section: 'personalDetails',
                        required: true,
                        pattern: 'email',
                    },
                    {
                        id: 'city',
                        section: 'billingAddress',
                        required: true,
                        size: [[3, 30]],
                    },
                    {
                        id: 'street',
                        section: 'billingAddress',
                        required: true,
                        size: [[4, 50]],
                    },
                    {
                        id: 'houseNumber',
                        section: 'billingAddress',
                        required: true,
                        size: [[1, 16]],
                    },
                    {
                        id: 'zipCode',
                        section: 'billingAddress',
                        required: true,
                        size: [[1, 9]],
                        pattern: 'zip',
                    },
                ];
            }

            return [];
        }

        if (method.isOneVision()) {
            if (method.isBankCard() || method.isPhoneBalance()) {
                return [
                    {
                        id: 'phone',
                        section: 'personalDetails',
                        mask: '+{000} 000 000 000',
                        required: true,
                    }
                ];
            }

            return [];
        }

        if (method.isIzi()) {
            if (method.isBankCard() || method.isCash()) {
                return [
                    {
                        id: 'document',
                        section: 'personalDetails',
                        mask: '00000000',
                        size: [[8, 8]],
                    }
                ];
            }

            return [];
        }

        if (method.isYuno()) {
            if (method.isBankCard()) {
                if (country === Countries.Brazil) {
                    return [
                        {
                            id: 'document',
                            section: 'personalDetails',
                            required: true,
                            /**
                             * CPF Generator
                             * @link {https://www.freetool.dev/cpf-generator-validator}
                             */
                            // example: 123.456.789-09
                            mask: '000.000.000-00',
                            size: [[9, 11]]
                        },
                        {
                            id: 'email',
                            section: 'personalDetails',
                            formatter: 'replaceIndriverEmail',
                            required: true,
                            pattern: 'email',
                        }
                    ];
                }
                if (
                    country === Countries.Mexico ||
                    country === Countries.Peru ||
                    country === Countries.Chile ||
                    country === Countries.Panama
                ) {
                    return [
                        {
                            id: 'firstName',
                            section: 'personalDetails',
                            required: true,
                            size: [[3, 255]],
                        },
                        {
                            id: 'lastName',
                            section: 'personalDetails',
                            required: true,
                            size: [[3, 255]],
                        },
                        {
                            id: 'email',
                            section: 'personalDetails',
                            formatter: 'replaceIndriverEmail',
                            required: true,
                            pattern: 'email',
                        }
                    ];
                }

                if (country === Countries.Colombia) {
                    return [
                        {
                            id: 'email',
                            section: 'personalDetails',
                            formatter: 'replaceIndriverEmail',
                            required: true,
                            pattern: 'email',
                        },
                        {
                            id: 'document',
                            section: 'personalDetails',
                            required: true,
                            mask: '0000000000',
                            size: [[6, 10]]
                        }
                    ];
                }

                if (country === Countries.Argentina) {
                    return [
                        {
                            id: 'email',
                            section: 'personalDetails',
                            formatter: 'replaceIndriverEmail',
                            required: true,
                            pattern: 'email',
                        },
                        {
                            id: 'document',
                            section: 'personalDetails',
                            required: true,
                            mask: '00000000000',
                            size: [[7, 9], [11, 11]]
                        }
                    ];
                }

                return [
                    {
                        id: 'email',
                        section: 'personalDetails',
                        formatter: 'replaceIndriverEmail',
                        required: true,
                        pattern: 'email',
                    },
                ];
            }

            return [];
        }

        if (method.isFawry()) {
            if (method.isCash()) {
                return [
                    {
                        id: 'email',
                        section: 'personalDetails',
                        required: true,
                        pattern: 'email',
                    },
                ];
            }

            return [];
        }

        if (method.isBcel()) {
            return [
                {
                    id: 'email',
                    section: 'personalDetails',
                    required: true,
                    pattern: 'email',
                },
                {
                    id: 'state',
                    section: 'homeAddress',
                    required: true,
                    size: [[3, 50]],
                },
                {
                    id: 'city',
                    section: 'homeAddress',
                    required: true,
                    size: [[3, 50]],
                },
                {
                    id: 'street',
                    section: 'homeAddress',
                    required: true,
                    size: [[3, 50]],
                },
                {
                    id: 'houseNumber',
                    section: 'homeAddress',
                    mask: 'Number',
                    required: true,
                    size: [[1, 16]],
                },
                {
                    id: 'zipCode',
                    section: 'homeAddress',
                    required: true,
                    pattern: 'zip',
                    size: [[1, 9]],
                },
            ];
        }

        return [];
    };

    private getDlocalDocumentParametersBy = (country: Countries): {
        mask?: string,
        length?: [number, number][]
    } => {
        switch (country) {
            case Countries.Mexico:
                // example: GAPI930504HGRXNR01
                return {
                    mask: 'aaaa000000aaaaaa00',
                    length: [[10, 18]]
                };
            case Countries.Bolivia:
                // example: 1234567
                return {
                    mask: '00000000000000000000',
                    length: [[5, 20]]
                };
            case Countries.Colombia:
                // example: 123456789
                return {
                    mask: '0000000000',
                    length: [[6, 10]]
                };
            case Countries.Ecuador:
                // example: 1712345678
                return {
                    mask: '0000000000',
                    length: [[10, 10]]
                };
            case Countries.Peru:
                // example: 12345678
                return {
                    mask: '00000000',
                    length: [[8, 8]]
                };
            case Countries.DominicanRepublic:
                // example: 001-1234567-8
                return {
                    mask: '000-0000000-0',
                    length: [[10, 11]]
                };
            case Countries.Chile:
                // example: CI 12.345.678-9
                // example: RUT 76.123.456-8
                return {
                    mask: '00.000.000-a',
                    length: [[6, 9]]
                };
            case Countries.Egypt:
                // example: 12345678901234
                return {
                    mask: '00000000000000',
                    length: [[14, 14]]
                };
            case Countries.Indonesia:
                // example: 3512115501990001
                return {
                    mask: '0000000000000000',
                    length: [[16, 16]]
                };
            case Countries.Nicaragua:
                // example: 123-456789-0004C
                return {
                    mask: '000-000000-0000a',
                    length: [[14, 14]]
                };
            case Countries.India:
                // example: ABCDE1234F
                return {
                    mask: 'aaaaa0000a',
                    length: [[10, 10]]
                };
            case Countries.Morocco:
                // example: A12345
                return {
                    mask: 'a[a]00000000000000000000',
                    length: [[5, 20]]
                };
            case Countries.Malaysia:
                // example: 930101142019
                return {
                    mask: '000000000000',
                    length: [[12, 12]]
                };
            case Countries.Kenya:
                // example: 12345678
                return {
                    mask: '00000000',
                    length: [[7, 8]]
                };
            case Countries.SouthAfrica:
                return {
                    mask: '0000000000000',
                    length: [[13, 13]]
                };
            case Countries.Nigeria:
                // example: 12345678901
                return {
                    mask: '00000000000',
                    length: [[11, 11]]
                };
            case Countries.CostaRica:
                return {
                    mask: '000000000',
                    length: [[9, 9]]
                };
            case Countries.Thailand:
                return {
                    mask: '0 0000 00000 00 0',
                    length: [[13, 13]]
                };
            case Countries.Pakistan:
                return {
                    mask: '00000-0000000-0',
                    length: [[13, 13]]
                };
            case Countries.Panama:
                return {
                    mask: '0-000-0000',
                    length: [[6, 8]]
                };
            case Countries.Tanzania:
                return {
                    mask: '00000000000000000000',
                    length: [[20, 20]]
                };
            default:
                return {};
        }
    };

    private getSectionSortOrder = (config: FieldConfig[]) => {
        const order = [];

        for (let i = 0; i < config.length; i++) {
            if (order[order.length - 1] !== config[i].section) {
                order.push(config[i].section);
            }
        }

        return order;
    };

    private mapSectionTitle = (id: string) => {
        switch (id) {
            case 'topUpAmount':
                return i18n.t('services.formBuilder.sections.amountTitle');
            case 'personalDetails':
                return i18n.t('services.formBuilder.sections.personalDetailTitle');
            case 'billingAddressSection':
                return i18n.t('services.formBuilder.sections.billingTitle');
            case 'homeAddressSection':
                return i18n.t('services.formBuilder.sections.homeAddress');
            default:
                return id;
        }
    };

    private mapFieldTitle = (id: string, country: Countries) => {
        switch (id) {
            case 'phone':
                return i18n.t('services.formBuilder.fields.phoneTitle');
            case 'email':
                return i18n.t('services.formBuilder.fields.emailTitle');
            case 'amount':
                return i18n.t('services.formBuilder.fields.amountTitle');
            case 'state':
                return i18n.t('services.formBuilder.fields.stateTitle');
            case 'city':
                return i18n.t('services.formBuilder.fields.cityTitle');
            case 'billingCity':
                return i18n.t('services.formBuilder.fields.cityTitle');
            case 'street':
                return i18n.t('services.formBuilder.fields.streetTitle');
            case 'houseNumber':
                return i18n.t('services.formBuilder.fields.houseTitle');
            case 'zipCode':
                return i18n.t('services.formBuilder.fields.zipTitle');
            case 'firstName':
                return i18n.t('services.formBuilder.fields.firstnameTitle');
            case 'lastName':
                return i18n.t('services.formBuilder.fields.lastnameTitle');
            case 'username':
                return i18n.t('services.formBuilder.fields.usernameTitle');
            case 'address':
                return i18n.t('services.formBuilder.fields.fullAddressTitle');
            case 'billingAddress':
                return i18n.t('services.formBuilder.fields.fullAddressTitle');
            case 'confirmationCode':
                return i18n.t('services.formBuilder.fields.confirmationCodeTitle');
            case 'document':
                switch (country) {
                    case Countries.Mexico:
                        return i18n.t('services.formBuilder.documents.mexicoId');
                    case Countries.Bolivia:
                        return i18n.t('services.formBuilder.documents.boliviaId');
                    case Countries.Colombia:
                        return i18n.t('services.formBuilder.documents.colombiaId');
                    case Countries.Brazil:
                        return i18n.t('services.formBuilder.documents.brazilId');
                    case Countries.Ecuador:
                        return i18n.t('services.formBuilder.documents.ecuadorId');
                    case Countries.Peru:
                        return i18n.t('services.formBuilder.documents.peruId');
                    case Countries.DominicanRepublic:
                        return i18n.t('services.formBuilder.documents.dominicanRepublicId');
                    case Countries.Chile:
                        return i18n.t('services.formBuilder.documents.chileId');
                    case Countries.Egypt:
                        return i18n.t('services.formBuilder.documents.egyptId');
                    case Countries.Indonesia:
                        return i18n.t('services.formBuilder.documents.indonesiaId');
                    case Countries.Nicaragua:
                        return i18n.t('services.formBuilder.documents.nicaraguaId');
                    case Countries.India:
                        return i18n.t('services.formBuilder.documents.indiaId');
                    case Countries.Morocco:
                        return i18n.t('services.formBuilder.documents.moroccoId');
                    case Countries.Malaysia:
                        return i18n.t('services.formBuilder.documents.malaysiaId');
                    case Countries.Kenya:
                        return i18n.t('services.formBuilder.documents.kenyaId');
                    case Countries.SouthAfrica:
                        return i18n.t('services.formBuilder.documents.southAfricaId');
                    case Countries.Nigeria:
                        return i18n.t('services.formBuilder.documents.nigeriaId');
                    case Countries.CostaRica:
                        return i18n.t('services.formBuilder.documents.costaricaId');
                    case Countries.Thailand:
                        return i18n.t('services.formBuilder.documents.thailandId');
                    case Countries.Pakistan:
                        return i18n.t('services.formBuilder.documents.pakistanId');
                    case Countries.Panama:
                        return i18n.t('services.formBuilder.documents.panamaId');
                    case Countries.Argentina:
                        return i18n.t('services.formBuilder.documents.argentinaId');
                    case Countries.Tanzania:
                        return i18n.t('services.formBuilder.documents.tanzaniaId');
                    case Countries.Turkey:
                        return i18n.t('services.formBuilder.documents.turkeyId');
                    case Countries.Tunisia:
                        return i18n.t('services.formBuilder.documents.tunisiaId');
                    default:
                        return i18n.t('services.formBuilder.documents.defaultId');
                }
            case 'payrailsRequestedResult':
                return 'Payrails requested result';
            default:
                return id;
        }
    };

    private mapMask = (value?: string) => {
        switch (value) {
            case 'Number':
                return Number;
            default:
                return value;
        }
    };

    private mapFormatter = (formatterSlug?: string) => {
        switch (formatterSlug) {
            case 'replaceIndriverEmail':
                return Text.replaceIndriverEmail;
        }

        return undefined;
    };

    private mapValidators = (field: FieldConfig) => {
        const validators = [];

        switch (field.pattern) {
            case 'email':
                validators.push(Validation.email);
                break;
            case 'zip':
                validators.push(Validation.zipCode);
                break;
        }

        if (field.size && field.size.length === 1) {
            const [min, max] = field.size[0];
            validators.push(Validation.stringSizeValidator(min, max));
        }

        if (field.size && field.size.length > 1) {
            const stringSizeValidators = [];

            for (let i = 0; i < field.size.length; i++) {
                const [min, max] = field.size[i];
                stringSizeValidators.push(Validation.stringSizeValidator(min, max));
            }

            validators.push(Validation.or(stringSizeValidators));
        }

        if (field.required) {
            validators.unshift(Validation.required);
        } else {
            return [Validation.optional(validators)];
        }

        return validators;
    };

    public buildSections = (
        config: FieldConfig[],
        country: Countries,
    ): FormSection[] => {
        const sections = config.reduce<Record<string, FormSection>>((s, field) => {
            if (!s[field.section]) {
                s[field.section] = FormSection.create({
                    id: field.section,
                    title: this.mapSectionTitle(field.section),
                });
            }

            const formField = FormField.create({
                id: field.id,
                title: this.mapFieldTitle(field.id, country),
                mask: this.mapMask(field.mask),
                formatter: this.mapFormatter(field.formatter),
                validators: this.mapValidators(field),
                hidden: field.hidden,
            });

            s[field.section].addField(formField);

            return s;
        }, {});

        return this.getSectionSortOrder(config)
            .map(sectionSlug => sections[sectionSlug]);
    };
}

export default Fields;
