import { FC, useCallback } from 'react';

import { FormValues } from '@/lib/domain/forms/forms';
import PaymentMethod from '@/lib/domain/payments/payment-method';
import { VendorName } from '@/lib/domain/payments/vendors';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import AnalyticsService from '@/services/analytics-service';
import LogsService from '@/services/logs-service';
import NavigatorService from '@/services/navigator-service';
import { PaymentInfo } from '@/services/payment-info-service';
import TopupService from '@/services/topup-service';

import DLocalNewBankCardView from './new-card';
import DlocalSavedCardView from './saved-card';


type DlocalBankCardControllerProps = {
    amount: string;
    method: PaymentMethod<VendorName.Dlocal>;
    paymentInfo: PaymentInfo;
    topupService: TopupService;
    analyticService: AnalyticsService;
    navigatorService: NavigatorService;
    logsService: LogsService;
}

const DlocalBankCardController: FC<DlocalBankCardControllerProps> = ({
    amount,
    method,
    paymentInfo,
    topupService,
    analyticService,
    navigatorService,
    logsService,
}) => {
    const handleSubmit = (values: FormValues): Promise<GQLErrorType> => {
        return topupService.topUp(
            amount,
            method,
            values,
            paymentInfo.country,
        );
    };

    const handleSavedTopUpClick = () => {
        analyticService.event('processing.topup_saved_card_pay.click', undefined);
    };

    const handleError = useCallback((error: string) => {
        navigatorService.showFinalPage('error', {
            message: error,
        });
    }, []);

    return method.savedInstruments?.bankCard ? (
        <DlocalSavedCardView
            apiKey={method.session.apiKey}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            panValue={method.savedInstruments?.bankCard[0].last4}
            cvvOnly={method.isCvvForSavedCards()}
            formFields={method.fields}
            logsService={logsService}
            onTopUpClick={handleSavedTopUpClick}
            onSubmit={handleSubmit}
            onError={handleError}
        />
    ) : (
        <DLocalNewBankCardView
            apiKey={method.session.apiKey}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={method.fields}
            logsService={logsService}
            onSubmit={handleSubmit}
            onError={handleError}
        />
    );
};

export default DlocalBankCardController;
