import i18n from 'i18next';

import { formatLast4toShortDots } from '@/lib/domain/payments/credit-card/format';
import PaymentMethod from '@/lib/domain/payments/payment-method';
import { VendorName } from '@/lib/domain/payments/vendors';
import { CurrencyCode } from '@/lib/locale';
import { Countries } from '@/lib/locale/countries';
import PaymentMethodIcon from '@/shared/ui/icons/payment-method';

import {
    PAYRAILS_TYPE_ALFA,
    PAYRAILS_TYPE_CARD,
    PAYRAILS_TYPE_EASYPAISA,
    PAYRAILS_TYPE_FAWRY,
    PAYRAILS_TYPE_JAZZCASH,
    PAYRAILS_TYPE_KONNECT,
    PAYRAILS_TYPE_WAFA_CASH
} from '../../graphql/payrails';

export type PaymentMethodViewItem = {
    id: string;
    dataMethodTypeId: string;
    name: string;
    show: boolean;
    description: string;
    isRemovable: boolean;
    oneStepFormShow: boolean;
    originalMethod: PaymentMethod<any>;
    icon: React.ReactElement;
};

export type PaymentMethodAutoTopupItem = {
    name: string;
    originalMethod: PaymentMethod<any>;
    icon: React.ReactElement;
}

class ViewService {
    public isOneStepMethod = (method: PaymentMethod<any>) => {
        switch (method.providerName) {
            case VendorName.Puntoxpress:
            case VendorName.ImePay:
            case VendorName.Gifty:
            case VendorName.Kassa24:
            case VendorName.JetPay:
            case VendorName.QiwiKz:
            case VendorName.KaspiKz:
            case VendorName.Onoi:
                return true;
            default:
                return this.requiresSessionBeforeDisplay(method);
        }
    };

    private requiresSessionBeforeDisplay = (
        method: PaymentMethod<any>
    ) => {
        switch (method.providerName) {
            case VendorName.Fawry:
                return method.isCash() && Boolean(method.session);
            case VendorName.Izi:
                return method.isCash() && Boolean(method.session);
            default:
                return false;
        }
    };

    private getPaymentMethodCaption = (method: PaymentMethod<any>, country: Countries): string => {
        if (
            country === Countries.Kyrgyzstan &&
            method.isOneVision() &&
            method.isPhoneBalance()
        ) {
            return i18n.t('views.PaymentMethods.mbankCaption');
        }

        if (method.isOnoi()) {
            if (method.isBankTransfer() || method.isWallet()) {
                return i18n.t('views.PaymentMethods.cashAndOtherCaption');
            }

            if (method.isCash()) {
                return i18n.t('views.PaymentMethods.onoiCashCaption');
            }
        }

        if (method.isYuno()) {
            if (method.methodName === 'Card') {
                return i18n.t('views.PaymentMethods.creditCardCaption');
            }

            // https://indriver.atlassian.net/browse/PRC-2007
            if (method.isBankCard() && method.methodName === 'Tarjeta de crédito/débito') {
                return i18n.t('views.PaymentMethods.creditCardCaption');
            }

            if (method.isBankTransfer() && method.methodName === 'Transferencia bancaria') {
                return i18n.t('views.PaymentMethods.bankAccountTransferCaption');
            }

            if (method.getPaymentType() || method.isInstrument()) {
                return this.formatYunoMethodName(method.methodName);
            }
        }

        if (method.isDlocal()) {
            // TODO https://indriver.atlassian.net/browse/PRC-2208
            if (method.isInstrument()) {
                const savedCard = method.savedInstruments?.bankCard;
                if (savedCard?.[0]) {
                    const formatted = formatLast4toShortDots(savedCard[0].last4);

                    return `${i18n.t('views.PaymentMethods.savedCardCaption')} (${formatted})`;
                }
            }

            if (method.paymentMethodCode && method.isBankCard()) {
                return method.methodName;
            }
        }

        if (method.isNuvei()) {
            if (method.isInstrument()) {
                const savedCard = method.savedInstruments?.bankCard;
                if (savedCard?.[0]) {
                    return i18n.t('views.PaymentMethods.savedCardCaption');
                }
            }
        }

        if (method.isPayrails()) {
            if (method.isInstrument()) {
                if (method.isBankCard()) {
                    return this.formatPayrailsMethodName(method.methodName);
                }

                return method.methodName;
            }

            if (method.paymentMethodCode) {
                if (method.paymentMethodCode === PAYRAILS_TYPE_FAWRY) {
                    return i18n.t('views.PaymentMethods.fawryCashCaption');
                }

                if (method.paymentMethodCode === PAYRAILS_TYPE_EASYPAISA) {
                    return i18n.t('views.PaymentMethods.easyPaisaCaption');
                }

                if (method.paymentMethodCode === PAYRAILS_TYPE_JAZZCASH) {
                    return i18n.t('views.PaymentMethods.jazzCashCaption');
                }

                if (method.paymentMethodCode === PAYRAILS_TYPE_ALFA) {
                    return i18n.t('views.PaymentMethods.alfaCaption');
                }

                if (method.paymentMethodCode === PAYRAILS_TYPE_KONNECT) {
                    return i18n.t('views.PaymentMethods.konnectCaption');
                }

                if (method.paymentMethodCode === PAYRAILS_TYPE_WAFA_CASH) {
                    return i18n.t('views.PaymentMethods.wafaCashCaption');
                }

                if (method.paymentMethodCode === PAYRAILS_TYPE_CARD) {
                    return i18n.t('views.PaymentMethods.creditCardCaption');
                }
            }

            return method.methodName;
        }
        if (
            method.isCredorax() &&
            method.isBankCard() &&
            method.isInstrument()
        ) {
            const savedCard = method.savedInstruments?.bankCard;
            if (savedCard?.[0]) {
                const formatted = formatLast4toShortDots(savedCard[0].last4);

                return `${i18n.t('views.PaymentMethods.savedCardCaption')} (${formatted})`;
            }
        }

        switch (method.providerName) {
            case VendorName.KaspiKz:
                return i18n.t('views.PaymentMethods.kaspiCaption');
            case VendorName.Kassa24:
                return i18n.t('views.PaymentMethods.kassa24Caption');
            case VendorName.JetPay:
                return i18n.t('views.PaymentMethods.jetpayCaption');
            default:
                if (method.isBankCard()) {
                    return i18n.t('views.PaymentMethods.creditCardCaption');
                }

                if (method.isBankTransfer()) {
                    return i18n.t('views.PaymentMethods.bankAccountTransferCaption');
                }

                if (method.isCash()) {
                    return i18n.t('views.PaymentMethods.cashCaption');
                }

                if (method.isWallet()) {
                    return i18n.t('views.PaymentMethods.walletCaption');
                }

                if (method.isPhoneBalance()) {
                    return i18n.t('views.PaymentMethods.phoneBalanceCaption');
                }

                if (method.isTerminal()) {
                    return i18n.t('views.PaymentMethods.terminalCaption');
                }
        }

        return method.methodName;
    };

    public adaptPaymentMethods = (
        methods: PaymentMethod<any>[],
        currency: CurrencyCode,
        country: Countries
    ): PaymentMethodViewItem[] => {
        return methods.map((method, index) => {
            const methodName = this.getPaymentMethodCaption(method, country);

            // for data-ids in e2e test
            let dataMethodTypeId: string = method.getPaymentType();
            if (method.isInstrument()) {
                dataMethodTypeId = `SAVED_${dataMethodTypeId}`;
            }

            return {
                id: method.getPaymentType() + method.paymentMethodCode + methodName + index,
                dataMethodTypeId,
                show: method.available,
                name: methodName,
                description: `${currency} ${method.limits.min}–${method.limits.max}`,
                isRemovable: method.isRemovable(),
                oneStepFormShow: this.isOneStepMethod(method),
                originalMethod: method,
                icon: <PaymentMethodIcon method={method} />
            };
        });
    };

    public getAutoTopupMethods = (
        methods: PaymentMethod<any>[],
        country: Countries
    ): PaymentMethodAutoTopupItem[] => {
        return methods.map((method) => {
            const methodName = this.getPaymentMethodCaption(method, country);

            return {
                name: methodName,
                originalMethod: method,
                icon: <PaymentMethodIcon method={method} />
            };
        });
    };

    private formatYunoMethodName = (value: string) => {
        if (/\*\*\*\*/g.test(value)) {
            const [cardType, last4] = value.split(' ****');

            return `${cardType} ${formatLast4toShortDots(last4)}`;
        }

        return value;
    };

    public formatPayrailsMethodName = (value: string) => {
        if (/\*\*\*\*/g.test(value)) {
            const [cardType, last4] = value.split(' ****');

            return `${cardType} ${formatLast4toShortDots(last4)}`;
        }

        return value;
    };
}

export default ViewService;
