import FormField from '@/lib/domain/forms/forms/form-field';
import { FormId } from '@/lib/domain/forms/forms/index';

class FormSection {
    public id: FormId;
    public title: string;
    public fields: FormField[];

    constructor(
        id: FormId,
        title: string,
        fields: FormField[],
    ) {
        this.id = id;
        this.title = title;
        this.fields = fields;
    }

    static create = (options: {
        id: FormId,
        title: string,
    }) => {
        return new FormSection(
            options.id,
            options.title,
            [],
        );
    };

    public isHidden = () => {
        return this.fields.every(field => field.hidden);
    };

    public addField = (field: FormField) => {
        this.fields.push(field);
    };
}

export default FormSection;
