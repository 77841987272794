import { getCardMask } from '@/lib/domain/payments/credit-card/format';
import Validation, { ValidationError } from '@/lib/forms/validation';

export const isDateFilled = (date: string): boolean => date.length === 2;

export const isCardFilled = (cardNumber: string): boolean => {
    const value = cardNumber.replace(/\D/g, '');
    const mask = getCardMask(value);

    if (!mask) {
        return false;
    }

    return value.length === mask.reduce((sum, n) => sum + n, 0);
};

export const validateCardValue = (value: string): ValidationError => {
    return Validation.validate(
        value,
        [
            Validation.required,
            Validation.cardNumber,
        ]
    );
};

export const validateExpirationMonth = (value: string): ValidationError => {
    return Validation.validate(
        value,
        [
            Validation.required,
            Validation.expirationMonth,
        ]
    );
};

export const validateExpirationYear = (month: string, year: string): ValidationError => {
    return Validation.validate(
        year,
        [
            Validation.required,
            Validation.expirationYear,
            Validation.expirationDate(month, year)

        ]
    );
};

export const validateCVV = (value: string, isAmex: boolean): ValidationError => {
    return Validation.validate(
        value,
        [
            Validation.required,
            Validation.cvv(isAmex),
        ]
    );
};
