import { FC, useCallback } from 'react';

import LogsService from '@/services/logs-service';
import NavigatorService, {
    CashlessRedirectPayload
} from '@/services/navigator-service';

import CashlessForm from './cashless-form';

type CashlessPageProps = {
    navigatorService: NavigatorService;
    logsService: LogsService;
}

const CashlessPage: FC<CashlessPageProps> = ({ navigatorService, logsService }) => {
    const handleTokenData = useCallback((payload: CashlessRedirectPayload) => {
        navigatorService.cashlessRedirect(payload);
    }, []);

    const handleError = useCallback((error: string) => {
        navigatorService.showFinalPage('error', {
            message: error,
        });
    }, []);

    return (
        <CashlessForm
            logsService={logsService}
            onTokenData={handleTokenData}
            onError={handleError}
        />
    );
};

export default CashlessPage;
