import { FC, Suspense, useEffect, useState } from 'react';

import initializeI18N from '../../i18n';
import CenterLoader from '../../shared/ui/layout/center-loader';

type AppResourcesProps = {
    children: React.ReactNode;
}

const AppResources: FC<AppResourcesProps> = ({ children }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        initializeI18N(() => setLoading(false));
    }, []);

    return (
        <Suspense fallback={<CenterLoader loading />}>
            <CenterLoader loading={loading}>
                {children}
            </CenterLoader>
        </Suspense>
    );
};

export default AppResources;
