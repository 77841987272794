import { FC } from 'react';

import { FormValues } from '@/lib/domain/forms/forms';
import PaymentMethod from '@/lib/domain/payments/payment-method';
import { VendorName } from '@/lib/domain/payments/vendors';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import AnalyticsService from '@/services/analytics-service';
import { PaymentInfo } from '@/services/payment-info-service';
import TopupService from '@/services/topup-service';

import CredoraxNewBankCardView from './new-card';
import CredoraxSavedCardView from './saved-card';

type CredoraxBankCardControllerProps = {
    amount: string;
    method: PaymentMethod<VendorName.Credorax>;
    paymentInfo: PaymentInfo;
    topupService: TopupService;
    analyticService: AnalyticsService;
}

/**
 * https://indriver.atlassian.net/wiki/spaces/PRC/pages/567443737/Credorax
 */
const CredoraxBankCardController: FC<CredoraxBankCardControllerProps> = ({
    amount,
    method,
    paymentInfo,
    topupService,
    analyticService,
}) => {
    const handleSavedTopUpClick = () => {
        analyticService.event('processing.topup_saved_card_pay.click', undefined);
    };

    const handleSubmit = (values: FormValues): Promise<GQLErrorType> => {
        return topupService.topUp(
            amount,
            method,
            values,
            paymentInfo.country,
        );
    };

    /*TODO https://indriver.atlassian.net/browse/PRC-2208*/
    return method.savedInstruments?.bankCard ? (
        <CredoraxSavedCardView
            country={paymentInfo.country}
            panValue={method.savedInstruments?.bankCard[0].last4}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={method.formFields}
            onTopUpClick={handleSavedTopUpClick}
            onSubmit={handleSubmit}
        />
    ) : (
        <CredoraxNewBankCardView
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={method.formFields}
            onSubmit={handleSubmit}
        />
    );
};

export default CredoraxBankCardController;
