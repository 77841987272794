import { variables } from '@indriver/yrel';
import styled from 'styled-components';

import { Text14Regular, Text16Medium } from '@/shared/typography/text';
import UnstyledAutoTopupIcon from '@/shared/ui/icons/auto-topup';
import AutoTopupSuspendedIcon from '@/shared/ui/icons/auto-topup/suspended';

export const Wrapper = styled.div`
    height: fit-content;
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    border: 1px solid transparent;
    background: ${variables['background-primary']};
`;

export const InfoWrapper = styled.div`
    display: flex;
    flex: 1;
    gap: 14px;
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
`;

export const IconWrapper = styled.div`
    & > svg > path {
        fill: ${variables['text-and-icon-secondary']};
    }
`;

export const IconWrapperOnTop = styled.div`
    margin-top: 4px;

    & > svg > path {
        fill: ${variables['text-and-icon-secondary']};
    }
`;

export const Title = styled.div`
    ${Text16Medium};
`;

export const Description = styled.span`
    ${Text14Regular};

    color: ${variables['text-and-icon-secondary']};
`;

export const AutoTopupIcon = styled(UnstyledAutoTopupIcon)`
    width: 24px;
    height: 24px;

    & > path {
        &.fill {
            fill: ${variables['text-and-icon-primary']};
        }

        &.stroke {
            stroke: ${variables['text-and-icon-primary']};
        }
    }
`;
export const AutoTopupIconSuspended = styled(AutoTopupSuspendedIcon)`
    width: 24px;
    height: 24px;
    & > path {
        &.fill {
            fill: ${variables['extensions-background-error']};
        }
    }
`;

export const ActiveLabel = styled.span`
    color: ${variables['background-accent']};
`;

export const SuspendedLabel = styled.span`
    color: ${variables['extensions-background-error']};
`;
