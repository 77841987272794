import { FC, useEffect, useState } from 'react';

import { FormValues } from '@/lib/domain/forms/forms';
import { YunoSecureFields } from '@/lib/domain/integrations/yuno/types';
import PaymentMethod from '@/lib/domain/payments/payment-method';
import SDK from '@/lib/domain/payments/sdk';
import { VendorName } from '@/lib/domain/payments/vendors';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { getCountryCode } from '@/lib/locale';
import LogsService from '@/services/logs-service';
import { PaymentInfo } from '@/services/payment-info-service';
import TopupService from '@/services/topup-service';
import CenterLoader from '@/shared/ui/layout/center-loader';

import YunoNewBankCardView from './new-card';
import YunoSavedCardView from './saved-card';

type YunoBankCardControllerProps = {
    amount: string;
    method: PaymentMethod<VendorName.Yuno>;
    paymentInfo: PaymentInfo;
    topupService: TopupService;
    logsService: LogsService;
    onHideGoBack: VoidFunction;
}

const YunoBankCardController: FC<YunoBankCardControllerProps> = ({
    amount,
    method,
    paymentInfo,
    topupService,
    logsService,
    onHideGoBack,
}) => {
    const [yunoSecureFields, setYunoSecureFields] = useState<YunoSecureFields | undefined>();

    useEffect(() => {
        onHideGoBack();

        const sdk = new SDK(logsService);
        sdk.get(VendorName.Yuno).then((initialize => {
            const yunoInstance = initialize(method.session.apiKey);

            const secureFields = yunoInstance.secureFields({
                countryCode: getCountryCode(paymentInfo.country),
                checkoutSession: method.session.sessionToken,
            });

            topupService.setYunoInstance(yunoInstance);
            topupService.setYunoSecureFields(secureFields);

            setYunoSecureFields(secureFields);
        }));
    }, []);

    const handleSubmit = async (
        values: FormValues
    ): Promise<GQLErrorType> => {
        return topupService.topUp(
            amount,
            method,
            values,
            paymentInfo.country,
        );
    };

    if (!yunoSecureFields) {
        return <CenterLoader loading />;
    }

    {/*TODO https://indriver.atlassian.net/browse/PRC-2208*/}

    return method.isRemovable() ? (
        <YunoSavedCardView
            panValue={method.methodName}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={method.formFields}
            onSubmit={handleSubmit}
        />
    ) : (
        <YunoNewBankCardView
            secureFields={yunoSecureFields}
            formFields={method.formFields}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            onSubmit={handleSubmit}
        />
    );
};

export default YunoBankCardController;
