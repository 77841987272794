import { InputMask } from '@indriver/nova';
import { createRef, Component, ChangeEvent, RefObject } from 'react';

import { focusOn } from '@/lib/forms/actions';
import { ValidationError } from '@/lib/forms/validation';

import { formatDate } from '../lib/format';
import { isDateFilled, validateExpirationMonth, validateExpirationYear } from '../lib/validate';

import * as UI from './ui';

type ExpirationDateProps = {
    ref: RefObject<ExpirationDate>;
    onError: (error: ValidationError) => void;
    onChange: (month: string, year: string) => void;
    onCompleted: VoidFunction;
}

type ExpirationDateState = {
    month: string;
    year: string;
}

class ExpirationDate extends Component<ExpirationDateProps, ExpirationDateState> {
    private monthRef: RefObject<HTMLInputElement>;
    private yearRef: RefObject<HTMLInputElement>;

    constructor(props: ExpirationDateProps) {
        super(props);

        this.state = {
            year: '',
            month: '',
        };

        this.monthRef = createRef();
        this.yearRef = createRef();
    }

    private getMonthRefValue = (): string =>
        this.monthRef.current ? this.monthRef.current.value : '';

    private getYearRefValue = (): string =>
        this.yearRef.current ? this.yearRef.current.value : '';

    private handleChangeCardExpirationMonth = (evt: ChangeEvent<HTMLInputElement>) => {
        const month = formatDate(evt.target.value);
        this.props.onChange(month, this.state.year);
        this.setState({ month });
        this.props.onError(undefined);

        if (isDateFilled(month)) {
            const error = validateExpirationMonth(month);

            if (error) {
                this.props.onError(error);

                return;
            }

            focusOn(this.yearRef)();
        }
    };

    private handleBlurCardExpirationMonth = () => {
        const { onError } = this.props;
        const month = this.getMonthRefValue();
        const expirationValidateErrorMonth = validateExpirationMonth(month);

        onError(expirationValidateErrorMonth);
    };

    private handleChangeCardExpirationYear = (evt: ChangeEvent<HTMLInputElement>) => {
        const year = formatDate(evt.target.value);
        this.props.onChange(this.state.month, year);
        this.setState({ year });
        this.props.onError(undefined);

        if (isDateFilled(year)) {
            const month = this.getMonthRefValue();
            const error = validateExpirationYear(month, year);

            if (error) {
                this.props.onError(error);

                return;
            }

            this.props.onCompleted();
        }

        const shouldFocusOnMonth = year.length === 0;

        if (shouldFocusOnMonth) {
            focusOn(this.monthRef)();
        }
    };

    private handleBlurCardExpirationYear = () => {
        this.validate();
    };

    private handleFocusYear = () => {
        const month = this.getMonthRefValue();
        const year = this.getYearRefValue();

        const shouldFocusOnMonth = year.length === 0 && month.length === 0;

        if (shouldFocusOnMonth) {
            focusOn(this.monthRef)();
        }
    };

    public validate = (): boolean => {
        const year = this.getYearRefValue();
        const month = this.getMonthRefValue();

        const expirationValidateErrorYear = validateExpirationYear(month, year);
        this.props.onError(expirationValidateErrorYear);

        let error;
        if (!expirationValidateErrorYear) {
            error = validateExpirationMonth(month);

            if (error) {
                this.props.onError(error);
            }
        }

        return !expirationValidateErrorYear || !error;
    };

    public focus = (): void => {
        if (this.monthRef.current) {
            this.monthRef.current.focus();
        }
    };

    public render() {
        return (
            <UI.Wrapper>
                <InputMask
                    //@ts-ignore
                    ref={this.monthRef}
                    InputView={UI.MonthInput}
                    pattern='\d*'
                    maskConfig={
                        {
                            mask: '00',
                        }
                    }
                    // @ts-ignore todo
                    onChange={this.handleChangeCardExpirationMonth}
                    onBlur={this.handleBlurCardExpirationMonth}
                    autoComplete='cc-exp'
                    maxLength={2}
                />

                <UI.Divider />

                <InputMask
                    //@ts-ignore
                    ref={this.yearRef}
                    InputView={UI.YearInput}
                    pattern='\d*'
                    maskConfig={
                        {
                            mask: '00',
                        }
                    }
                    // @ts-ignore todo
                    onChange={this.handleChangeCardExpirationYear}
                    onBlur={this.handleBlurCardExpirationYear}
                    onFocus={this.handleFocusYear}
                    autoComplete='cc-exp'
                    maxLength={2}
                />
            </UI.Wrapper>
        );
    }
}

export default ExpirationDate;
