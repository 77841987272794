import AppRoot from './app';
import AuthorizationService from './services/authorization-service';
import ConfigurationService from './services/configuration-service';
import LogsService from './services/logs-service';
import MobilePlatformService from './services/mobile-platform-service';

(function main() {
    window.access('mainstart');

    const configurationService = new ConfigurationService();
    const logsService = new LogsService(configurationService);

    logsService.writeInitialLogLine();
    logsService.handleGlobalError();
    logsService.handleCSPErrors();

    const mobilePlatformService = new MobilePlatformService(
        configurationService,
        logsService
    ).listen();

    const authorizationService = new AuthorizationService(
        mobilePlatformService,
        logsService
    );

    const app = new AppRoot(
        logsService,
        mobilePlatformService,
        authorizationService,
        configurationService
    );

    app.render();
})();
