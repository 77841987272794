import { FC, useCallback, useRef, useState } from 'react';

import { FormValues } from '@/lib/domain/forms/forms';
import FormSection from '@/lib/domain/forms/forms/form-section';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { Countries } from '@/lib/locale/countries';

import SectionForm from '../../shared/ui/domain/section-form';
import SubmitForm from '../../shared/ui/domain/submit-form';
import { AppData } from '../../singlepage/app';

import FillForm from '../payment-fill-form';

type FormFieldViewProps = {
    title?: string;
    country: Countries;
    savedDocuments: AppData['paymentInfo']['savedDocuments'];
    formFields: FormSection[];
    children?: JSX.Element;
    onSubmit: (values: FormValues) => Promise<GQLErrorType>;
    childrenTitle?: string;
}

const FormFieldView: FC<FormFieldViewProps> = ({
    title,
    country,
    savedDocuments,
    formFields,
    onSubmit,
    children,
    childrenTitle,
}) => {
    const paymentFillFormRef = useRef<FillForm>(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = useCallback(async () => {
        if (loading) { // double click prevent
            return;
        }

        if (!paymentFillFormRef.current?.validate()) {
            return;
        }

        const paymentFillFormData = paymentFillFormRef.current.getData();

        setLoading(true);

        const error = await onSubmit(paymentFillFormData);

        if (error) {

            if (paymentFillFormRef.current) {
                paymentFillFormRef.current.setError(error);
            }
        }

        setLoading(false);
    }, [onSubmit]);

    let childrenSection = <></>;
    if (children) {
        childrenSection = childrenTitle ? (
            <SectionForm title={childrenTitle}>
                {children}
            </SectionForm>
        ) : children;
    }

    return (
        <SubmitForm
            title={title}
            loading={loading}
            onSubmit={handleSubmit}
        >
            {childrenSection}

            <FillForm
                ref={paymentFillFormRef}
                country={country}
                savedDocuments={savedDocuments}
                fields={formFields}
            />
        </SubmitForm>
    );
};

export default FormFieldView;
