import * as Sentry from '@sentry/react';

import EventEmitter from '@/lib/subscriber';
import ConfigurationService from '@/services/configuration-service';
import LogsService from '@/services/logs-service';

import Resources from '../../resources';

type JSInterfaceAndroid = {
    postMessage: (value: string) => void;
}

type JSInterfaceIos = {
    messageHandlers?: {
        inDriveriOSHandler: {
            postMessage: (message: { action: string }) => void;
        };
    };
}

declare global {
    interface Window {
        callWebView?: (data: CallWebviewEvent) => void;
        Android?: JSInterfaceAndroid;
        webkit?: JSInterfaceIos;
    }
}

type CallWebviewEvent<T = string> = {
    action: ParentAction;
    success: boolean;
    payload: {
        data: T;
    };
}

enum ParentAction {
    refreshJWT = 'refresh_jwt',
    // force kill current app activity screen
    dismiss = 'dismiss',
    paymentSuccess = 'payment_success',
    paymentInProgress = 'payment_in_progress',
    paymentFailed = 'payment_failed',
    getShieldId = 'get_shield_session_id',
    getTopupPayload = 'get_topup_payload',
    getSelectedProviderPayload = 'get_selected_provider_payload',
    setupNavigationBar = 'setup_navigation_bar',
    goBackAction = 'go_back_action',
}

type ParentRequestMessage = {
    action: string;
    data?: {
        old_jwt?: string;
    } | {
        title?: string;
        behavior?: 'v2' | 'legacy';
        left_button_type?: 'burger-menu' | 'back' | null;
        left_button_action?: ParentAction;
    } | {
        title?: string;
    } | {
        name: string;
        payload?: Record<string, string | number | boolean>,
    };
}

/* eslint-disable max-len */
/**
 * https://github.com/inDriver/inDriverAndroid/blob/f388c7cbc17ee221bc00bf71eb3b8c0d29ec8c6f/features/webview/docs/javascript_api.md
 * This is message-oriented middleware for inter process communication between Android or iOS platforms.
 *
 * You can find some tips, values and datastructures in below Android Webview interface
 * https://github.com/inDriver/inDriverAndroid/blob/dev/app/src/main/java/sinet/startup/inDriver/webview/WebViewLayout.java
 */
/* eslint-enable max-len */
class MobilePlatformService extends EventEmitter<{
    'onJWTRefresh': string;
    'onShieldId': string;
    'onGetTopupPayload': string;
    'onGetSelectedProviderPayload': string;
    'onBackArrowLeftClick': void;
}>{
    private configurationService: ConfigurationService;
    private logsService: LogsService;
    private shieldId?: string;

    constructor(
        configurationService: ConfigurationService,
        logsService: LogsService,
    ) {
        super();

        this.configurationService = configurationService;
        this.logsService = logsService;
        this.shieldId = undefined;
    }

    private hasAndroidInterface = (jsInterface?: JSInterfaceAndroid): jsInterface is JSInterfaceAndroid => {
        return Boolean(jsInterface?.postMessage);
    };

    private hasIosInterface = (jsInterface?: JSInterfaceIos): jsInterface is JSInterfaceIos => {
        return Boolean(jsInterface?.messageHandlers?.inDriveriOSHandler?.postMessage);
    };

    private sendMessageToPlatform = (message: ParentRequestMessage) => {
        this.logsService.write(`pmsg:${message.action}`);
        let called = false;

        try {
            if (this.hasAndroidInterface(window.Android)) {
                window.Android.postMessage(JSON.stringify(message));
                called = true;
            }

            if (this.hasIosInterface(window.webkit)) {
                window.webkit.messageHandlers?.inDriveriOSHandler?.postMessage(message);
                called = true;
            }

            if (this.configurationService.isDev()) {
                /** put your window.callWebView invokes here for debuggin without device */

                // if (message.action === ParentAction.getTopupPayload) {
                //     window.callWebView({
                //         action: ParentAction.getTopupPayload,
                //         success: true,
                //         payload: {
                //             data: '{"amount": 321}',
                //         },
                //     });
                // }
            }

            if (!called) {
                this.logsService.write('pmsg was not called');
            }
        } catch (e) {
            Sentry.captureException(e);
            if (e instanceof Error) {
                this.logsService.write(`pmsg fail ${e.message}`);
            }
        }

        return called;
    };

    public listen() {
        this.logsService.write('started listen mobile platform');

        window.callWebView = (data: CallWebviewEvent) => {
            try {
                const { success, payload, action } = data;

                if (success) {
                    this.logsService.write(`success callWebView action ${action}`);
                } else {
                    Sentry.captureEvent({
                        message: 'Unsuccessful callWebView from platform',
                        level: 'info',
                        extra: {
                            data,
                        }
                    });
                }

                if (action === ParentAction.refreshJWT) {
                    this.dispatch('onJWTRefresh', payload.data);
                }

                if (action === ParentAction.getTopupPayload) {
                    this.dispatch('onGetTopupPayload', payload.data);
                }

                if (action === ParentAction.getSelectedProviderPayload) {
                    this.dispatch('onGetSelectedProviderPayload', payload.data);
                }

                if (action === ParentAction.getShieldId) {
                    this.dispatch('onShieldId', payload.data);
                }

                if (action === ParentAction.goBackAction) {
                    this.dispatch('onBackArrowLeftClick', undefined);
                }
            } catch (e) {
                Sentry.captureException(e);
            }
        };

        return this;
    }

    private putDomFlagForE2ETests = () => {
        const element = document.createElement('div');
        element.dataset.id = Resources.test.requestAuth;
        document.body.append(element);
    };

    public async refreshJWT(oldJwt?: string): Promise<string> {
        this.logsService.write('call refreshJWT');
        this.putDomFlagForE2ETests();

        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => {
                reject('Timeout of token refresh');
            }, 5_000);

            const handleJWTRefresh = (jwtValue: string) => {
                window.clearTimeout(timeout);
                resolve(jwtValue);

                this.removeEventListener('onJWTRefresh', handleJWTRefresh);
            };

            this.addEventListener('onJWTRefresh', handleJWTRefresh);

            const called = this.sendMessageToPlatform({
                action: ParentAction.refreshJWT,
                data: {
                    old_jwt: oldJwt,
                }
            });

            if (!called) {
                this.removeEventListener('onJWTRefresh', handleJWTRefresh);

                reject('platform message wasnt called');
            }
        });
    }

    public getShieldId(): Promise<string> {
        if (this.shieldId) {
            return Promise.resolve(this.shieldId);
        }

        this.logsService.write('call shieldId');

        return new Promise((resolve) => {
            const timeout = setTimeout(() => {
                this.logsService.write('resolve shield id by timeout');

                resolve('');
            }, 50);

            const handleShieldId = (shieldId: string) => {
                this.shieldId = shieldId;
                window.clearTimeout(timeout);
                resolve(shieldId);

                this.removeEventListener('onShieldId', handleShieldId);
            };

            this.addEventListener('onShieldId', handleShieldId);
            const called = this.sendMessageToPlatform({
                action: ParentAction.getShieldId,
            });

            if (!called) {
                this.removeEventListener('onShieldId', handleShieldId);

                resolve('');
            }
        });
    }

    public getTopupPayload(): Promise<string> {
        this.logsService.write('call get_topup_payload');

        return new Promise((resolve) => {
            const timeout = setTimeout(() => {
                this.logsService.write('resolve get_topup_payload by timeout');

                resolve('');
            }, 50);

            const handleGetToupPayload = (payload: string) => {
                window.clearTimeout(timeout);

                try {
                    const data = JSON.parse(payload);
                    if (data?.amount) {
                        resolve(data.amount);
                    } else {
                        resolve('');
                    }
                } catch (e) {
                    resolve('');
                    // todo https://indriver.atlassian.net/browse/DRA-3049
                    Sentry.captureException(e);
                }

                this.removeEventListener('onGetTopupPayload', handleGetToupPayload);
            };

            this.addEventListener('onGetTopupPayload', handleGetToupPayload);
            this.sendMessageToPlatform({
                action: ParentAction.getTopupPayload,
            });
        });
    }

    public getSelectedProviderPayload(): Promise<string> {
        this.logsService.write('call get_selected_provider_payload');

        return new Promise((resolve) => {
            const timeout = setTimeout(() => {
                this.logsService.write('resolve get_selected_provider_payload by timeout');

                resolve('');
            }, 50);

            const handleGetSelectedProviderPayload = (payload: string) => {
                window.clearTimeout(timeout);

                try {
                    const data = JSON.parse(payload);
                    if (data?.provider) {
                        resolve(data.provider.toUpperCase());
                    } else {
                        resolve('');
                    }
                } catch (e) {
                    resolve('');
                    Sentry.captureException(e);
                }

                this.removeEventListener('onGetSelectedProviderPayload', handleGetSelectedProviderPayload);
            };

            this.addEventListener('onGetSelectedProviderPayload', handleGetSelectedProviderPayload);
            this.sendMessageToPlatform({
                action: ParentAction.getSelectedProviderPayload,
            });
        });
    }

    public dismiss(): void {
        this.sendMessageToPlatform({
            action: ParentAction.dismiss,
        });
    }

    public sendPaymentInProgress(): void {
        this.sendMessageToPlatform({
            action: ParentAction.paymentInProgress,
        });
    }

    public sendPaymentFailed(): void {
        this.sendMessageToPlatform({
            action: ParentAction.paymentFailed,
        });
    }

    /**
     * Docs
     * https://indriver.atlassian.net/browse/PL-6564
     */
    public showNativeNavgationBar(): void {
        this.sendMessageToPlatform({
            action: ParentAction.setupNavigationBar,
            data: {
                behavior: 'v2',
                left_button_type: 'back',
                left_button_action: ParentAction.goBackAction,
            }
        });
    }

    public hideNativeNavgationBar(): void {
        this.sendMessageToPlatform({
            action: ParentAction.setupNavigationBar,
            data: {
                behavior: 'v2',
                left_button_type: null,
            }
        });
    }

    /**
     * Docs
     * https://github.com/inDriver/webview-tools/tree/main/analytics
     */
    public sendAnalyticsMessage = (event: {
        name: string;
        payload?: Record<string, string | number | boolean>,
    }) => {
        this.sendMessageToPlatform({
            action: 'webview_send_analytics',
            data: event,
        });
    };
}

export default MobilePlatformService;
