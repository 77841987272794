import {
    ApolloCache,
    ApolloError,
    DefaultContext,
    FetchPolicy,
    OperationVariables,
    TypedDocumentNode,
} from '@apollo/client';
import { DocumentNode } from 'graphql';

import DisplayedError from '@/lib/errors/displayed-error';
import GQLError, { GQLErrorType } from '@/lib/errors/graphql-error';
import PaymentProviderError from '@/lib/errors/payment-provider-error';
import ErrorStatus from '@/lib/errors/status';
import AuthorizationService from '@/services/authorization-service';
import ConfigurationService from '@/services/configuration-service';
import LogsService from '@/services/logs-service';
import MobilePlatformService from '@/services/mobile-platform-service';
import NavigatorService from '@/services/navigator-service';
import { SavedAutoTopup } from '@/services/payment-info-service';

import { createApolloClient } from '../../graphql/apollo';
import TOP_UP_WITH_CREDORAX_CARD, {
    TopupWithCredoraxCardInput,
    TopupWithCredoraxCardMutation,
    TopupWithCredoraxCardResponse,
} from '../../graphql/bank-card/credorax/top-up-with-credorax-card';
import MUTATION_DELETE_CARD from '../../graphql/bank-card/delete-card';
import CREATE_CARD_DLOCAL, {
    CreateCardDlocalInput,
    CreateCardDlocalMutation,
    CreateCardDlocalResponse,
} from '../../graphql/bank-card/dlocal/create-card-dlocal';
import TOP_UP_WITH_IYZICO_CARD, {
    TopupWithIyzicoCardInput,
    TopupWithIyzicoCardMutation,
    TopupWithIyzicoCardResponse,
} from '../../graphql/bank-card/iyzico/top-up-with-iyzico-card';
import TOP_UP_WITH_IZI_CREDIT_CARD, {
    TopupWithIziCardInput,
    TopupWithIziCardMutation,
    TopupWithIziCardResponse
} from '../../graphql/bank-card/izi/create-card-izi';
import AUTHORIZE_WITH_NUVEI, {
    AuthorizeWithNuveiInput,
    AuthorizeWithNuveiMutation,
    AuthorizeWithNuveiResponse

} from '../../graphql/bank-card/nuvei/authorize-with-nuvei';
import TOPUP_WITH_NUVEI, {
    TopupWithNuveiInput, TopupWithNuveiMutation, TopupWithNuveiResponse
} from '../../graphql/bank-card/nuvei/top-up-with-nuvei';
import TOPUP_WITH_NUVEI_SAVED_CARD, {
    TopupWithNuveiSavedCardInput, TopupWithNuveiSavedCardMutation
} from '../../graphql/bank-card/nuvei/top-up-with-nuvei-saved-card';
import TOP_UP_WITH_ONEVISION_CREDIT_CARD, {
    TopupWithOneVisionInput,
    TopupWithOneVisionMutation,
    TopupWithOneVisionResponse
} from '../../graphql/bank-card/one-vision/topup-with-onevision';
import TOP_UP_WITH_SIMPAISA, {
    TopupWithSimpaisaInput,
    TopupWithSimpaisaMutation,
    TopupWithSimpaisaResponse
} from '../../graphql/bank-card/simpaisa/topup-with-simpaisa';
import TOP_UP_WITH_SLICK, {
    TopupWithSlickInput,
    TopupWithSlickMutation,
    TopupWithSlickResponse,
} from '../../graphql/bank-card/slick/top-up-with-slick';
import CREATE_DLOCAL_BANK_TRANSFER, {
    CreateBankTransferDlocalInput,
    CreateBankTransferDlocalMutation,
    CreateBankTransferDlocalResponse,
} from '../../graphql/bank-transfer/dlocal/create-dlocal-bank-transfer';
import CREATE_CASH_DLOCAL, {
    CreateCashDlocalInput,
    CreateCashDlocalMutation,
    CreateCashDlocalResponse,
} from '../../graphql/cash/dlocal/create-dlocal-cash';
import TOP_UP_WITH_FAWRY, {
    TopupWithFawryCashInput,
    TopupWithFawryCashMutation,
    TopupWithFawryCashResponse,
} from '../../graphql/cash/fawry/top-up-with-fawry-cash';
import TOP_TOP_WITH_IZI_CASH, {
    TopupWithIziCashInput,
    TopupWithIziCashMutation,
    TopupWithIziCashResponse
} from '../../graphql/cash/izi/topup-with-izi-cash';
import DELETE_PAYRAILS_PAYMENT_METHOD, {
    DeletePayrailsPaymentMethodInput,
} from '../../graphql/payrails/delete-payrails-payment-method';
import GET_PAYRAILS_INIT_OPTIONS, {
    PayrailsInitOptionsInput,
    PayrailsInitOptionsResponse,
} from '../../graphql/payrails/payrails-init-options';
import TOP_UP_PAYRAILS_GENERIC_METHOD, {
    TopupWithGenericMethodInput,
    TopupWithGenericMethodPayrailsResponse,
} from '../../graphql/payrails/top-up-payrails-generic-method';
import TOPUP_WITH_CARD_PAYRAILS, {
    TopupWithCardPayrailsInput,
    TopupWithCardPayrailsResponse,
} from '../../graphql/payrails/top-up-with-card-payrails';
import TOP_UP_WITH_SAVED_CARD, {
    TopupWithSavedCardPayrailsInput,
    TopupWithSavedCardPayrailsResponse,
} from '../../graphql/payrails/top-up-with-saved-card-payrails';
import CREATE_WOOPPAY_SESSION, {
    CreateWooppaySessionInput,
    CreateWooppaySessionMutation,
    CreateWooppaySessionResponse,
} from '../../graphql/phone-balance/woopay/create-wooppay-session';
import TOPUP_WOOPPAY, {
    TopupWooppayInput,
    TopupWooppayMutation,
    TopupWooppayResponse,
} from '../../graphql/phone-balance/woopay/topup-wooppay';
import TOPUP_WITH_BCEL, {
    TopupWithBCELInput,
    TopupWithBCELMutation,
    TopupWithBCELResponse
} from '../../graphql/wallet/bcel/top-up-with-bcel';
import TOP_UP_WITH_DLOCAL_WALLET, {
    TopupWithDlocalWalletInput,
    TopupWithDlocalWalletMutation,
    TopupWithDlocalWalletResponse,
} from '../../graphql/wallet/dlocal/top-up-with-dlocal-wallet';
import TOP_UP_WITH_IYZICO_WALLET, {
    TopupWithIyzicoWalletInput,
    TopupWithIyzicoWalletMutation,
    TopupWithIyzicoWalletResponse,
} from '../../graphql/wallet/iyzico/top-up-with-iyzico-wallet';
import MUTATION_DELETE_YUNO_PAYMENT_METHOD from '../../graphql/yuno/delete-yuno-payment-method';
import TOP_UP_WITH_YUNO, {
    TopupWithYunoInput,
    TopupWithYunoMutation,
    TopupWithYunoResponse,
} from '../../graphql/yuno/top-up-with-yuno';
import PaymentProviderI18N from '../../i18n/payment-provider';

import { PaymentInfoData } from './api/init';

type CreatePaymentPayload = {
    card_token: string;
    amount: number;
};

class PaymentProviderService {
    static MAX_RETRIES = 3;
    private navigatorService: NavigatorService;
    private authorizationService: AuthorizationService;
    private mobilePlatformService: MobilePlatformService;
    private logsService: LogsService;
    private configurationService: ConfigurationService;

    constructor(
        navigatorService: NavigatorService,
        authorizationService: AuthorizationService,
        logsService: LogsService,
        mobilePlatformService: MobilePlatformService,
        configurationService: ConfigurationService,
    ) {
        this.navigatorService = navigatorService;
        this.authorizationService = authorizationService;
        this.logsService = logsService;
        this.mobilePlatformService = mobilePlatformService;
        this.configurationService = configurationService;
    }

    private logQuery = (gql: DocumentNode): void => {
        try {
            // @ts-ignore
            this.logsService.write(`gql_${gql.definitions[0]?.name?.value}`);
        } catch (_) {
            /** nothing */
        }
    };

    private withRetryClient = async <T>(
        query: (client: Awaited<ReturnType<typeof createApolloClient>>) => T
    ): Promise<T> => {
        for (let i = 0; i < PaymentProviderService.MAX_RETRIES; i++) {
            try {
                const auth = await this.authorizationService.getJWT().getBearerFormat();
                const shiledId = await this.mobilePlatformService.getShieldId();
                const client = await createApolloClient(
                    auth,
                    shiledId,
                    this.configurationService.isDev(),
                    this.configurationService.getPaymentProviderGraphQLApi()
                );

                return await query(client);
            } catch (e) {
                if (e instanceof ApolloError) {
                    const isUnauthorized = e.networkError?.message?.includes('401');
                    if (isUnauthorized) {
                        await this.authorizationService.refresh();
                        this.logsService.write('on_apollo401error_retry');

                        continue;
                    }

                    const gqlError = GQLError.create(e);
                    if (gqlError?.isValidationError()) {
                        this.logsService.write('PP_VALIDATION_ERROR');

                        throw gqlError;
                    } else if (gqlError?.isProfileError()) {
                        this.logsService.write('PP_PROFILE_ERROR');

                        throw gqlError;
                    } else if (gqlError?.isInternalError()) {
                        const ppError = new PaymentProviderError();

                        throw new DisplayedError({
                            title: ppError.getTitleFor(ErrorStatus.PAYMENT_PROVIDER_INTERNAL_SERVER_ERROR),
                            messages: [gqlError.getMessage()],
                            errorStatus: ErrorStatus.PAYMENT_PROVIDER_INTERNAL_SERVER_ERROR,
                        });
                    }
                }

                if (e instanceof Error) {
                    const ppError = new PaymentProviderError();

                    throw new DisplayedError({
                        title: ppError.getTitleFor(ErrorStatus.COMMON_INTERNAL_ERROR),
                        messages: [e.message],
                        errorStatus: ErrorStatus.COMMON_INTERNAL_ERROR,
                    });
                }
            }
        }

        const ppError = new PaymentProviderError();
        throw new DisplayedError(ppError.unauhthorized());
    };

    private query = async <T, R>(gql: DocumentNode, input: T, fetchPolicy?: FetchPolicy) => {
        this.logQuery(gql);

        return this.withRetryClient(client => {
            return client.query<R>({
                query: gql,
                variables: { input },
                fetchPolicy,
            });
        });
    };

    private mutation = async<
        TData = any,
        TVariables extends OperationVariables = OperationVariables,
        TContext extends Record<string, any> = DefaultContext,
        TCache extends ApolloCache<any> = ApolloCache<any>,
    >(mutation: DocumentNode | TypedDocumentNode<TData, TVariables>, variables: TVariables) => {
        this.logQuery(mutation);

        return this.withRetryClient(client => {
            return client.mutate<TData, TVariables, TContext, TCache>({
                mutation,
                variables
            });
        });
    };

    public deleteCard(input: { cardId: string }) {
        return this.mutation(MUTATION_DELETE_CARD, { input });
    }

    public topUpWithDlocalWallet(input: TopupWithDlocalWalletInput) {
        return this.mutation<
            TopupWithDlocalWalletResponse,
            TopupWithDlocalWalletMutation
        >(TOP_UP_WITH_DLOCAL_WALLET, { input });
    }

    public topupWithFawryCash(input: TopupWithFawryCashInput) {
        return this.mutation<
            TopupWithFawryCashResponse,
            TopupWithFawryCashMutation
        >(TOP_UP_WITH_FAWRY, { input });
    }

    public topupWithIziCash(input: TopupWithIziCashInput) {
        return this.mutation<
            TopupWithIziCashResponse,
            TopupWithIziCashMutation
        >(TOP_TOP_WITH_IZI_CASH, { input });
    }

    public topUpWithCredorax(input: TopupWithCredoraxCardInput) {
        return this.mutation<
            TopupWithCredoraxCardResponse,
            TopupWithCredoraxCardMutation
        >(TOP_UP_WITH_CREDORAX_CARD, { input });
    }

    public topupWithSlick(input: TopupWithSlickInput) {
        return this.mutation<
            TopupWithSlickResponse,
            TopupWithSlickMutation
            >(TOP_UP_WITH_SLICK, { input });
    }
    public topupWithIyzicoCard(input: TopupWithIyzicoCardInput) {
        return this.mutation<
            TopupWithIyzicoCardResponse,
            TopupWithIyzicoCardMutation
        >(TOP_UP_WITH_IYZICO_CARD, { input });
    }

    public topupWithIyzicoWallet(input: TopupWithIyzicoWalletInput) {
        return this.mutation<
            TopupWithIyzicoWalletResponse,
            TopupWithIyzicoWalletMutation
        >(TOP_UP_WITH_IYZICO_WALLET, { input });
    }

    public createCardDlocal(input: CreateCardDlocalInput) {
        return this.mutation<
            CreateCardDlocalResponse,
            CreateCardDlocalMutation
        >(CREATE_CARD_DLOCAL, { input });
    }

    public topUpWithIziCreditCard(input: TopupWithIziCardInput) {
        return this.mutation<
            TopupWithIziCardResponse,
            TopupWithIziCardMutation
        >(TOP_UP_WITH_IZI_CREDIT_CARD, { input });
    }

    public topUpWithOneVision(input: TopupWithOneVisionInput) {
        return this.mutation<
            TopupWithOneVisionResponse,
            TopupWithOneVisionMutation
        >(TOP_UP_WITH_ONEVISION_CREDIT_CARD, { input });
    }

    public topUpWithSimpaisa(input: TopupWithSimpaisaInput) {
        return this.mutation<
            TopupWithSimpaisaResponse,
            TopupWithSimpaisaMutation
        >(TOP_UP_WITH_SIMPAISA, { input });
    }

    public createDlocalCash(input: CreateCashDlocalInput) {
        return this.mutation<
            CreateCashDlocalResponse,
            CreateCashDlocalMutation
        >(CREATE_CASH_DLOCAL, { input });
    }

    public createDlocalBankTransfer(input: CreateBankTransferDlocalInput) {
        return this.mutation<
            CreateBankTransferDlocalResponse,
            CreateBankTransferDlocalMutation
        >(CREATE_DLOCAL_BANK_TRANSFER, { input });
    }

    public topUpWithYuno(input: TopupWithYunoInput) {
        return this.mutation<
            TopupWithYunoResponse,
            TopupWithYunoMutation
        >(TOP_UP_WITH_YUNO, { input });
    }

    public deleteYunoPaymentMethod(input: { token: string }) {
        return this.mutation(MUTATION_DELETE_YUNO_PAYMENT_METHOD, { input });
    }

    public async getPayrailsInitOptions(input: PayrailsInitOptionsInput) {
        return this.query<
            PayrailsInitOptionsInput,
            PayrailsInitOptionsResponse
        >(GET_PAYRAILS_INIT_OPTIONS, input, 'no-cache');
    }

    public async topUpWithSavedPayrailsCard(input: TopupWithSavedCardPayrailsInput) {
        return this.query<
            TopupWithSavedCardPayrailsInput,
            TopupWithSavedCardPayrailsResponse
        >(TOP_UP_WITH_SAVED_CARD, input, 'no-cache');
    }

    public async topUpWithPayrailsCard(input: TopupWithCardPayrailsInput) {
        return this.query<
            TopupWithCardPayrailsInput,
            TopupWithCardPayrailsResponse
        >(TOPUP_WITH_CARD_PAYRAILS, input, 'no-cache');
    }

    public async topupPayrailsGenericMethod(input: TopupWithGenericMethodInput) {
        return this.query<
            TopupWithGenericMethodInput,
            TopupWithGenericMethodPayrailsResponse
        >(TOP_UP_PAYRAILS_GENERIC_METHOD, input, 'no-cache');
    }

    public createWoopaySession(input: CreateWooppaySessionInput) {
        return this.mutation<
            CreateWooppaySessionResponse,
            CreateWooppaySessionMutation
        >(CREATE_WOOPPAY_SESSION, { input });
    }
    public topupWooppay(input: TopupWooppayInput) {
        return this.mutation<
            TopupWooppayResponse,
            TopupWooppayMutation
        >(TOPUP_WOOPPAY, { input });
    }

    public topupWithNuvei(input: TopupWithNuveiInput) {
        return this.mutation<TopupWithNuveiResponse, TopupWithNuveiMutation>(
            TOPUP_WITH_NUVEI,
            { input }
        );
    }

    public authorizeWithNuvei(input: AuthorizeWithNuveiInput) {
        return this.mutation<AuthorizeWithNuveiResponse, AuthorizeWithNuveiMutation>(
            AUTHORIZE_WITH_NUVEI,
            { input }
        );
    }

    public topupWithNuveiSavedCard(input: TopupWithNuveiSavedCardInput) {
        return this.mutation<TopupWithNuveiSavedCardInput, TopupWithNuveiSavedCardMutation>(
            TOPUP_WITH_NUVEI_SAVED_CARD,
            { input }
        );
    }

    public topupWithBCEL(input: TopupWithBCELInput) {
        return this.mutation<TopupWithBCELResponse, TopupWithBCELMutation>(
            TOPUP_WITH_BCEL,
            { input }
        );
    }

    public async deletePayrailsPaymentMethod(input: DeletePayrailsPaymentMethodInput) {
        return this.mutation(DELETE_PAYRAILS_PAYMENT_METHOD, { input });
    }

    public async process(
        procedure: (req: PaymentProviderService) => Promise<void>
    ): Promise<GQLErrorType> {
        try {
            await procedure(this);
        } catch (e) {
            if (e instanceof GQLError) {
                if (e.isProfileError()) {
                    this.navigatorService.showFinalPage('profile', {
                        message: e.getMessage(),
                    });

                    return undefined;
                }

                return e;
            }

            if (e instanceof DisplayedError) {
                this.navigatorService.showFinalPage('error', {
                    code: e.title,
                    message: e.messages[0],
                    errorStatus: e.errorStatus,
                });

                return undefined;
            }

            this.navigatorService.showFinalPage('error', {
                errorStatus: ErrorStatus.COMMON_INTERNAL_ERROR,
            });
        }

        return undefined;
    }

    private multiRequest = async <T>(arm: string, payload?: any): Promise<T> => {
        let status = null;
        let data: any = {};

        for (let i = 0; i < PaymentProviderService.MAX_RETRIES; i++) {
            const auth = this.authorizationService.getJWT().getBearerFormat();
            const shieldId = await this.mobilePlatformService.getShieldId();

            const options: RequestInit = {
                headers: {
                    'authorization': auth,
                    'shield-session-id': shieldId,
                }
            };

            if (payload) {
                options.method = 'POST';
                options.body = JSON.stringify(payload);
            }

            const url = this.configurationService.getPaymentProviderHost();
            try {
                const res = await fetch(url + arm, options);
                status = res.status;
                data = await res.json();
            } catch (e) {
                window.access('initfailed');
                console.error(e);
            }

            const shouldTryAgainWithAuthRefresh = status === 401;
            if (shouldTryAgainWithAuthRefresh) {
                await this.authorizationService.refresh();

                continue;
            }

            break;
        }

        if (status === 200) {
            return data;
        }

        if (status === 401) {
            const ppError = new PaymentProviderError();
            throw new DisplayedError(ppError.unauhthorized());
        }

        if (status === 500) {
            const ppI18N = new PaymentProviderI18N();
            const error = ppI18N.localizeError(data);

            throw new DisplayedError({
                title: error.title,
                messages: error.messages,
                errorStatus: ErrorStatus.PAYMENT_PROVIDER_INTERNAL_SERVER_ERROR,
            });
        }

        const ppError = new PaymentProviderError();
        throw new DisplayedError(ppError.internalServer());
    };

    public async createPayment(data: CreatePaymentPayload): Promise<void> {
        const response = await this.multiRequest('/api/public-form/payments', data);

        console.log(response);
    }

    public async getPaymentInfo() {
        return await this.multiRequest<PaymentInfoData>('/api/v2/init');
    }

    public async saveAutoTopup(input: SavedAutoTopup) {
        return await this.multiRequest('/api/v2/autotopup', input);
    }
}

export default PaymentProviderService;
