import i18n from 'i18next';

import { YunoSecureField, YunoSecureFieldChangeEvent, YunoSecureFields } from '@/lib/domain/integrations/yuno/types';
import { ValidationError } from '@/lib/forms/validation';
import EventEmitter from '@/lib/subscriber';

import * as UI from './ui';


type FieldType =
    typeof YunoFields.ELEMENT_CVV |
    typeof YunoFields.ELEMENT_PAN |
    typeof YunoFields.ELEMENT_EXPIRATION;

class YunoFields extends EventEmitter<{
    'allFieldsRendered': void;
    'error': { field: FieldType; error?: ValidationError };
}> {
    static ELEMENT_CVV = 'yuno-secure-cvv';
    static ELEMENT_PAN = 'yuno-secure-pan';
    static ELEMENT_EXPIRATION = 'yuno-secure-expiration';

    private secureFieldsSDK: YunoSecureFields;
    private events: Record<FieldType, YunoSecureFieldChangeEvent|undefined>;
    private readiness: Record<FieldType, boolean>;
    private fields: Record<FieldType, YunoSecureField|undefined>;

    constructor(secureFieldsSDK: YunoSecureFields) {
        super();

        this.secureFieldsSDK = secureFieldsSDK;

        this.events = {
            [YunoFields.ELEMENT_CVV]: undefined,
            [YunoFields.ELEMENT_PAN]: undefined,
            [YunoFields.ELEMENT_EXPIRATION]: undefined,
        };

        this.readiness = {
            [YunoFields.ELEMENT_CVV]: false,
            [YunoFields.ELEMENT_PAN]: false,
            [YunoFields.ELEMENT_EXPIRATION]: false,
        };

        this.fields = {
            [YunoFields.ELEMENT_CVV]: undefined,
            [YunoFields.ELEMENT_PAN]: undefined,
            [YunoFields.ELEMENT_EXPIRATION]: undefined,
        };
    }

    public validate = (type: FieldType): boolean => {
        const event = this.events[type];

        if (!event || event.error) {
            this.dispatchError(type);

            return false;
        }

        return true;
    };

    private dispatchError = (type: FieldType) => {
        let message = '';

        switch (type) {
            case YunoFields.ELEMENT_PAN:
                message = i18n.t('lib.validation.cardNumber');
                break;
            case YunoFields.ELEMENT_CVV:
                message = i18n.t('lib.validation.cvv');
                break;
            case YunoFields.ELEMENT_EXPIRATION:
                message = i18n.t('lib.validation.expirationDate');
                break;
        }

        this.dispatch('error', {
            field: type,
            error: { message },
        });
    };

    private dispatchEmptyError = (type: FieldType) => {
        this.dispatch('error', {
            field: type,
            error: undefined
        });
    };

    public createPan() {
        const field = this.secureFieldsSDK.create({
            name: 'pan',
            options: {
                styles: UI.iframeInputStyles(),
                onChange: (event: YunoSecureFieldChangeEvent) => {
                    this.events[YunoFields.ELEMENT_PAN] = event;
                },
                onBlur: () => {
                    const lastChangeEvent = this.events[YunoFields.ELEMENT_PAN];

                    if (lastChangeEvent?.error) {
                        this.dispatchError(YunoFields.ELEMENT_PAN);
                    }
                },
                onFocus: () => {
                    this.dispatchEmptyError(YunoFields.ELEMENT_PAN);
                },
                onRenderedSecureField: () => {
                    this.setReady(YunoFields.ELEMENT_PAN);
                },
            },
        });

        field.render(`#${YunoFields.ELEMENT_PAN}`);
        this.fields[YunoFields.ELEMENT_PAN] = field;
    }

    public createCvv() {
        const field = this.secureFieldsSDK.create({
            name: 'cvv',
            options: {
                styles: UI.iframeInputStyles(),
                onChange: (event: YunoSecureFieldChangeEvent) => {
                    this.events[YunoFields.ELEMENT_CVV] = event;

                },
                onBlur: () => {
                    const lastChangeEvent = this.events[YunoFields.ELEMENT_CVV];
                    if (lastChangeEvent?.error) {
                        this.dispatchError(YunoFields.ELEMENT_CVV);
                    }
                },
                onFocus: () => {
                    this.dispatchEmptyError(YunoFields.ELEMENT_CVV);
                },
                onRenderedSecureField: () => {
                    this.setReady(YunoFields.ELEMENT_CVV);
                },
            },
        });

        field.render(`#${YunoFields.ELEMENT_CVV}`);
        this.fields[YunoFields.ELEMENT_CVV] = field;
    }

    public createExpiration() {
        const field = this.secureFieldsSDK.create({
            name: 'expiration',
            options: {
                styles: UI.iframeInputStyles(),
                onChange: (event: YunoSecureFieldChangeEvent) => {
                    this.events[YunoFields.ELEMENT_EXPIRATION] = event;
                },
                onBlur: () => {
                    const lastChangeEvent = this.events[YunoFields.ELEMENT_EXPIRATION];
                    if (lastChangeEvent?.error) {
                        this.dispatchError(YunoFields.ELEMENT_EXPIRATION);
                    }
                },
                onFocus: () => {
                    this.dispatchEmptyError(YunoFields.ELEMENT_EXPIRATION);
                },
                onRenderedSecureField: () => {
                    this.setReady(YunoFields.ELEMENT_EXPIRATION);
                },
            },
        });

        field.render(`#${YunoFields.ELEMENT_EXPIRATION}`);
        this.fields[YunoFields.ELEMENT_EXPIRATION] = field;
    }

    public updateColors = () => {
        Object.values(this.fields).forEach((field) => {
            if (!field) {
                return;
            }

            field.updateProps({
                styles: UI.iframeInputStyles(),
            });
        });
    };

    private setReady(type: FieldType) {
        this.readiness[type] = true;

        if (Object.values(this.readiness).every((rendered) => rendered)) {
            this.dispatch('allFieldsRendered', undefined);
        }
    }
}

export default YunoFields;
