import { FC } from 'react';

export type AutoTopupSuspendedProps = {
    size?: number;
    className?: string;
};

const AutoTopupSuspendedIcon: FC<AutoTopupSuspendedProps> = ({ size = 24, className }) => {
    return (
        <svg
            width={size}
            height={size}
            className={className}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                className='fill'
                fillRule='evenodd'
                clipRule='evenodd'
                d={
                    'M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228' +
                    ' 6.47715 22 12 22ZM12 7.125C11.4477 7.125 11 7.57272 11 8.125V12.125C11 12.6773 11.4477 13.125' +
                    ' 12 13.125C12.5523 13.125 13 12.6773 13 12.125V8.125C13 7.57272 12.5523 7.125 12 7.125ZM12 ' +
                    '16.875C11.3096 16.875 10.75 16.3154 10.75 15.625C10.75 14.9346 11.3096 14.375 12 14.375C12.6904 ' +
                    '14.375 13.25 14.9346 13.25 15.625C13.25 16.3154 12.6904 16.875 12 16.875Z'
                }
                fill='currentColor'
            />
        </svg>
    );
};

export default AutoTopupSuspendedIcon;
