import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import MarkdownViewer from '@/features/markdown-viewer';

type ImePayWalletProps = {
    referenceNumber: string;
    redirectUrl?: string;
    onRedirect: (redirectUrl: string) => void;
    onCopy: (paycode: string, title: string) => void;
}

const ImePayWallet: React.FC<ImePayWalletProps> = ({
    referenceNumber,
    redirectUrl,
    onRedirect,
    onCopy,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const handleSubmit = useCallback(() => {
        if (!redirectUrl) {
            return;
        }

        setLoading(true);
        onRedirect(redirectUrl);
    }, [redirectUrl]);

    const handleCopyPaycode = useCallback(() => {
        onCopy(referenceNumber, t('views.PaymentMethods.Wallet.ImePayTopUpSteps.referenceCopiedLabel'));
    }, [referenceNumber, onCopy]);

    return (
        <MarkdownViewer>
            {
                [
                    { type: MarkdownViewer.COPY_BLOCK, content: {
                        title: referenceNumber,
                        subtitle: t('views.PaymentMethods.Wallet.ImePayTopUpSteps.referenceNumberLabel'),
                        onClick: handleCopyPaycode,
                    } },
                    { type: MarkdownViewer.TITLE, content: t('features.HowToTopUpInfo.title') },
                    {
                        type: MarkdownViewer.NUMBERED_LIST,
                        content: [
                            t('views.PaymentMethods.Wallet.ImePayTopUpSteps.stepOne'),
                            t('views.PaymentMethods.Wallet.ImePayTopUpSteps.stepTwo'),
                            t('views.PaymentMethods.Wallet.ImePayTopUpSteps.stepThree'),
                        ],
                    },
                    { type: MarkdownViewer.SUBMIT, content: {
                        hide: !redirectUrl,
                        caption: t('views.PaymentMethods.Wallet.ImePayTopUpSteps.submitCapton'),
                        loading,
                        onClick: handleSubmit
                    } }
                ]
            }
        </MarkdownViewer>
    );
};

export default ImePayWallet;

