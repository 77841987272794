import { gql } from '@apollo/client';

import { PayrailsSession } from './index';

type PayrailsTokensV1 = {
    numberToken: string;
    securityCodeToken: string;
    vaultedToken: string
}

// TODO убрать после переезда https://indriver.atlassian.net/browse/PRC-2717

type PayrailsTokensV2 = {
    collectData: {
        vaultProviderConfigId: string;
        encryptedData: string;
    }
}

export type TopupWithCardPayrailsInput = {
    session: PayrailsSession;
    document: string;
    requestedResult?: string;
} & (PayrailsTokensV1 | PayrailsTokensV2);

export type TopupWithCardPayrailsResponse = {
    topupWithCardPayrails: {
        redirectUrl: string;
    }
}

export default gql(`
    mutation topupWithCardPayrails ($input: TopupWithCardPayrailsInput!){
            topupWithCardPayrails(
                input: $input
            ) {
                redirectUrl
            }
        }
`);
