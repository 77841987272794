import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import MarkdownViewer from '@/features/markdown-viewer';
import { Countries } from '@/lib/locale/countries';

type PuntoxpressCashProps = {
    referenceNumber: string;
    country: number;
    onCopy: (paycode: string, title: string) => void;
};

const PuntoxpressCash: FC<PuntoxpressCashProps> = ({
    referenceNumber,
    country,
    onCopy,
}) => {
    const { t } = useTranslation();

    const handleCopyPaycode = useCallback(() => {
        onCopy(referenceNumber, t('views.PaymentMethods.Cash.PuntoExpress.referenceNumberCopiedLabel'));
    }, [referenceNumber, onCopy]);

    return (
        <MarkdownViewer>
            {
                [
                    { type: MarkdownViewer.COPY_BLOCK, content: {
                        title: referenceNumber,
                        subtitle: t('views.PaymentMethods.Cash.PuntoExpress.referenceTitle'),
                        onClick: handleCopyPaycode,
                    } },
                    { type: MarkdownViewer.TITLE, content: t('features.HowToTopUpInfo.title') },
                    {
                        type: MarkdownViewer.NUMBERED_LIST,
                        content: (() => {
                            switch (country) {
                                case Countries.Salvador:
                                    return [
                                        t('views.PaymentMethods.Cash.PuntoExpress.puntoSalvadorInstructionStep1'),
                                        t('views.PaymentMethods.Cash.PuntoExpress.puntoSalvadorInstructionStep2'),
                                        t('views.PaymentMethods.Cash.PuntoExpress.puntoSalvadorInstructionStep3'),
                                    ];
                                case Countries.Nicaragua:
                                    return [
                                        t('views.PaymentMethods.Cash.PuntoExpress.puntoNicaraguaInstructionStep1'),
                                        t('views.PaymentMethods.Cash.PuntoExpress.puntoNicaraguaInstructionStep2'),
                                        t('views.PaymentMethods.Cash.PuntoExpress.puntoNicaraguaInstructionStep3'),
                                    ];
                                case Countries.Guatemala:
                                    return [
                                        t('views.PaymentMethods.Cash.PuntoExpress.puntoGuatemalaInstructionStep1'),
                                        t('views.PaymentMethods.Cash.PuntoExpress.puntoGuatemalaInstructionStep2'),
                                        t('views.PaymentMethods.Cash.PuntoExpress.puntoGuatemalaInstructionStep3'),
                                    ];
                                case Countries.Honduras:
                                    return [
                                        t('views.PaymentMethods.Cash.PuntoExpress.puntoHondurasInstructionStep1'),
                                        t('views.PaymentMethods.Cash.PuntoExpress.puntoHondurasInstructionStep2'),
                                        t('views.PaymentMethods.Cash.PuntoExpress.puntoHondurasInstructionStep3'),
                                    ];
                                default:
                                    return [];
                            }
                        })(),
                    },
                ]
            }
        </MarkdownViewer>
    );
};

export default PuntoxpressCash;
