import { variables } from '@indriver/yrel';
import styled from 'styled-components';

import { Text14Regular, Text18Bold } from '@/shared/typography/text';

export const Wrapper = styled.div`
    border: 1px solid ${variables['border-primary']};
    border-radius: 8px;
    width: 100%;
    overflow: hidden;
`;

export const Title = styled.h2`
    color: ${variables['text-and-icon-primary']};
    margin: 16px;
    ${Text18Bold};
`;

export const Content = styled.div<{ $opened: boolean }>`
    opacity: ${({ $opened }) => ($opened ? '1' : '0')};
    transition: all 0.3s;
    padding: 16px;
    background-color: ${variables['extensions-background-surface']};
    ${Text14Regular};
    ul {
        list-style: disc;
        list-style-position: inside;
    }
`;