import { gql } from '@apollo/client';

export type TopupWithIziCardMutation = {
    input: TopupWithIziCardInput,
}

export type TopupWithIziCardInput = {
    amount: number;
}

export type TopupWithIziCardResponse = {
    topupWithIziCard: {
        redirectUrl: string;
    }
}

export default gql(`
    mutation topupWithIziCard($input: TopupWithIziCardInput!) {
        topupWithIziCard(input: $input) {
            redirectUrl
        }
    }
`);
