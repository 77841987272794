import {
    DOCUMENT_ID,
    EMAIL_ID,
    FULL_ADDRESS_ID,
    FULL_BILLING_ADDRESS_ID,
    PHONE_ID
} from '@/lib/domain/forms/forms/indentificators';
import PaymentMethod from '@/lib/domain/payments/payment-method';
import { PaymentType } from '@/lib/domain/payments/payment-types';
import { PaymentSessions, } from '@/lib/domain/payments/session';
import Vendors, { VendorName } from '@/lib/domain/payments/vendors';
import DisplayedError from '@/lib/errors/displayed-error';
import JWTToken from '@/lib/jwt';
import { Countries } from '@/lib/locale/countries';
import EventEmitter from '@/lib/subscriber';
import ConfigurationService from '@/services/configuration-service';
import MobilePlatformService from '@/services/mobile-platform-service';
import PaymentProviderService from '@/services/payment-provider-service';
import {
    AutoTopupSettingsStatus,
    PaymentInfoData,
    SuggestionViewType
} from '@/services/payment-provider-service/api/init';

export enum MapLink {
    REDIRECT,
    FRAME
}

export type SuggestedAmountView =  {
    viewType?: SuggestionViewType;
    suggestedAmounts: Suggestion[];
}

export type Suggestion = {
    value: number;
    days?: number;
    rides?: number;
}

export type SavedInstrument = {
    bankCard?: SavedInstrumentBankCard[];
}

export type SavedInstrumentBankCard = {
    id: string;
    last4: string;
}

export type SavedAutoTopup = {
    enable: boolean;
    amount: number;
    balance_threshold: number;
    card: {
        uuid?: string;
        token: string;
        last4?: string;
    };
}

export type AutoTopupInfo = {
    visible: true;
    enable: boolean;
    thresholdSuggests: number[];
    amountSuggests: number[];
    userSettings: {
        status?: AutoTopupSettingsStatus;
        balanceThreshold: number;
        amount: number;
        savedCard: {
            uuid: string;
            last4: string;
            token: string;
        };
    }
} | {
    visible: false;
}

export type AutoTopupInfoDisabled = false

export type PaymentInfo = {
    country: Countries;
    paymentMethods: PaymentMethod<any>[];
    suggestedAmountsView: SuggestedAmountView | null;
    defaultMethod: number | null;
    savedDocuments: {
        [EMAIL_ID]: string;
        [DOCUMENT_ID]: string;
        [PHONE_ID]: string;
        [FULL_ADDRESS_ID]: string;
        [FULL_BILLING_ADDRESS_ID]: string;
    },
    autoTopup: AutoTopupInfo;
    defaultAmount: string;
}

class PaymentInfoService extends EventEmitter<{
    'onPaymentInfoUpdate': PaymentInfo;
    'onError': DisplayedError;
    'onShowPaymentInfo': void;
}> {
    private paymentInfo: PaymentInfo | null;
    private paymentProviderService: PaymentProviderService;
    private mobilePlatformService: MobilePlatformService;
    private configurationService: ConfigurationService;

    constructor(
        paymentProviderService: PaymentProviderService,
        mobilePlatformService: MobilePlatformService,
        configurationService: ConfigurationService,
    ) {
        super();

        this.paymentInfo = null;
        this.paymentProviderService = paymentProviderService;
        this.mobilePlatformService = mobilePlatformService;
        this.configurationService = configurationService;
    }

    // todo https://indriver.atlassian.net/browse/PRC-2052
    // todo двигаем установку дефолтных методов на бек
    private getDefaultMethod = async (
        country: Countries,
        methods: PaymentMethod<any>[]
    ) => {
        const selectedProvider = await this.mobilePlatformService.getSelectedProviderPayload();
        if (Vendors.isPaymentProviderName(selectedProvider)) {
            return this.getIndexOf(methods, selectedProvider);
        }

        switch (country) {
            case Countries.Kazakhstan:
                return this.getIndexOf(methods, VendorName.KaspiKz);
        }

        if (methods.length === 1) {
            return 0;
        }

        return null;
    };

    private getIndexOf = (
        methods: PaymentMethod<any>[],
        providerName: VendorName,
    ): number | null => {
        if (!methods.length) {
            return null;
        }

        if (methods.length === 1) {
            return 0;
        }

        for (let i = 0; i < methods.length; i++) {
            if (methods[i].providerName === providerName) {
                return i;
            }
        }

        return null;
    };

    private mapSession<T extends keyof PaymentSessions>(
        providerName: T,
        paymentType: string,
        sessionData: any,
        paymentMethodCode: string,
    ): PaymentSessions[T] {
        const sessionMapping: Record<keyof PaymentSessions, keyof typeof sessionData> = {
            [VendorName.Yuno]: 'yuno',
            [VendorName.Gifty]: 'gifty',
            [VendorName.ImePay]: 'imepay',
            [VendorName.Payrails]: 'payrails',
            [VendorName.Dlocal]: 'dlocal',
            [VendorName.Credorax]: 'credorax',
            [VendorName.Puntoxpress]: 'puntoxpress',
            [VendorName.QiwiKz]: 'qiwi_kz',
            [VendorName.Kassa24]: 'kassa24',
            [VendorName.JetPay]: 'jetpay',
            [VendorName.KaspiKz]: 'kaspi',
            [VendorName.Onoi]: 'onoi',
            [VendorName.InDriver]: '',
            [VendorName.Slick]: '',
            [VendorName.Fawry]: '',
            [VendorName.Fortumo]: '',
            [VendorName.Wooppay]: '',
            [VendorName.Iyzico]: '',
            [VendorName.Aamarpay]: '',
            [VendorName.Izi]: '',
            [VendorName.OneVision]: '',
            [VendorName.Simpaisa]: '',
            [VendorName.Nuvei]: '',
            [VendorName.Bcel]: '',
        };

        const sessionKey = sessionMapping[providerName];
        const session = sessionData[sessionKey] || {};

        if (providerName === VendorName.Yuno) {
            return {
                apiKey: session.apiKey,
                sessionToken: session.session,
                vaultedToken: session.token,
                methodType: paymentMethodCode || '',
                methodName: paymentType || '',
            } as PaymentSessions[T];
        }

        if (providerName === VendorName.Payrails) {
            return {
                instrumentId: session.instrument_id,
                paymentId: session.payment_id,
                session: session.session,
                paymentMethodCode: paymentMethodCode,
            } as PaymentSessions[T];
        }

        if (providerName === VendorName.Gifty) {
            return {
                referenceNumber: session.reference_number,
            } as PaymentSessions[T];
        }

        if (providerName === VendorName.Credorax) {
            return {
                staticKey: session.staticKey,
                merchantID: session.merchantID,
            } as PaymentSessions[T];
        }

        if (providerName === VendorName.ImePay) {
            return {
                referenceNumber: session.reference_number,
                redirectUrl: session.redirect_url,
            } as PaymentSessions[T];
        }

        if (providerName === VendorName.Dlocal) {
            return {
                apiKey: session.api_key,
                paymentMethodCode: paymentMethodCode,
            } as PaymentSessions[T];
        }

        if (providerName === VendorName.Puntoxpress) {
            return {
                referenceNumber: session.reference_number,
            } as PaymentSessions[T];
        }

        if (providerName === VendorName.QiwiKz) {
            return {
                referenceNumber: session.reference_number,
            } as PaymentSessions[T];
        }

        if (providerName === VendorName.Kassa24) {
            return {
                referenceNumber: session.reference_number,
            } as PaymentSessions[T];
        }

        if (providerName === VendorName.JetPay) {
            return {
                referenceNumber: session.reference_number,
                redirectUrl: 'indriver://web?https://wallet.jetpay.kz/cabinet/payment/list/135/item/3101',
            } as PaymentSessions[T];
        }

        if (providerName === VendorName.KaspiKz) {
            const KASPI_SERVICE_ID = 3027;
            const KASPI_PARAMETER_ID = 4169;
            const referenceNumber = session.reference_number;
            const params = new URLSearchParams();
            params.append('service_id', KASPI_SERVICE_ID.toString());
            params.append('browser', 'true');
            params.append(KASPI_PARAMETER_ID.toString(), referenceNumber);

            return {
                referenceNumber,
                redirectUrl: `https://kaspi.kz/pay/InDriverDirect?${params.toString()}`,
            } as PaymentSessions[T];
        }

        if (providerName === VendorName.Onoi) {
            return {
                referenceNumber: session.reference_number,
            } as PaymentSessions[T];
        }

        if (providerName === VendorName.OneVision) {
            return {
                paymentMethodCode,
            } as PaymentSessions[T];
        }

        return undefined as PaymentSessions[T];
    }

    private adaptPaymentInfo = (rawData: PaymentInfoData): PaymentInfo => {
        const autoTopup: AutoTopupInfo = rawData.auto_topup ? {
            visible: true,
            enable: rawData.auto_topup.enable,
            amountSuggests: rawData.auto_topup.amount_suggests,
            thresholdSuggests: rawData.auto_topup.threshold_suggests,
            userSettings: {
                status: rawData.auto_topup.user_settings.status ?? AutoTopupSettingsStatus.UNUSED,
                balanceThreshold: rawData.auto_topup.user_settings.balance_threshold,
                amount: rawData.auto_topup.user_settings.amount,
                savedCard: {
                    uuid: rawData.auto_topup.user_settings.saved_card.uuid,
                    last4: rawData.auto_topup.user_settings.saved_card.last4,
                    token: rawData.auto_topup.user_settings.saved_card.token
                }
            }
        } : {
            visible: false
        };

        const country = rawData.country_id;

        const paymentMethods = (rawData?.payment_methods || []).map(item => {
            const paymentType = item.payment_type as PaymentType;
            const providerName = item.provider as VendorName;

            return PaymentMethod.create(
                item,
                country,
                paymentType,
                providerName,
                this.mapSession(
                    providerName,
                    paymentType,
                    item.session || {},
                    item.provider_method_code,
                ),
                this.configurationService,
            );
        });

        const suggestedAmountsView = rawData.suggested_amounts_view ? {
            viewType: rawData.suggested_amounts_view.view_type,
            suggestedAmounts: rawData.suggested_amounts_view.suggested_amounts
        } : null;

        return {
            country,
            suggestedAmountsView,
            defaultMethod: null,
            paymentMethods: paymentMethods || [],
            savedDocuments: {
                email: rawData.fill_form?.email || '',
                document: rawData.fill_form?.document || '',
                phone: rawData.fill_form?.phone || '',
                address: rawData.fill_form?.address || '',
                billingAddress: rawData.fill_form?.billingAddress || '',
            },
            autoTopup,
            defaultAmount: '',
        };
    };

    private async deletePaymentMethod(method: PaymentMethod<any>) {
        if (method.isYuno()) {
            return this.paymentProviderService.deleteYunoPaymentMethod({
                token: method.session.vaultedToken
            });
        }

        // TODO https://indriver.atlassian.net/browse/PRC-2208
        if (
            method.isNuvei() ||
            method.isDlocal() ||
            method.isCredorax()
        ) {
            if (method.savedInstruments?.bankCard) {
                const card = method.savedInstruments?.bankCard[0];

                this.paymentProviderService.deleteCard({ cardId: card.id })
                    .finally(() => window.location.reload());

                return;
            }
        }

        if (method.isPayrails()) {
            return this.paymentProviderService.deletePayrailsPaymentMethod({
                instrumentID:  method.session.instrumentId,
            });
        }

        return;
    }

    public async deleteMethod(method: PaymentMethod<any>) {
        if (!this.paymentInfo) {
            return;
        }

        method.setAvailable(false);
        this.dispatch('onPaymentInfoUpdate', this.paymentInfo);

        this.deletePaymentMethod(method);
    }

    public async init(jwt: JWTToken) {
        try {
            const paymentInfoData = await this.paymentProviderService.getPaymentInfo();
            const paymentInfo = this.adaptPaymentInfo(paymentInfoData);
            const token = jwt.getToken();

            this.paymentInfo = {
                defaultMethod: await this.getDefaultMethod(
                    paymentInfo.country,
                    paymentInfo.paymentMethods
                ),
                suggestedAmountsView: paymentInfo.suggestedAmountsView,
                paymentMethods: paymentInfo.paymentMethods,
                country: token.country_id,
                savedDocuments: paymentInfo.savedDocuments,
                autoTopup: paymentInfo.autoTopup,
                defaultAmount: await this.mobilePlatformService.getTopupPayload(),
            };

            this.dispatch('onPaymentInfoUpdate', this.paymentInfo);
        } catch (e) {
            if (e instanceof DisplayedError) {
                this.dispatch('onError', e);
            }
        }
    }

    /** We are working only with Yuno for Auto top up */
    public getYunoSavedCards = () => {
        return this.paymentInfo?.paymentMethods.filter((method) => (
            method.isYuno() &&
            method.session.vaultedToken &&
            method.isBankCard() &&
            method.available
        )) || [];
    };

    public hasYunoSavedCards = () => {
        return this.getYunoSavedCards().length > 0;
    };

    public setFawryCashSessionBeforeDisplay = (data: PaymentSessions[VendorName.Fawry]) => {
        if (!this.paymentInfo) {
            return;
        }

        this.paymentInfo.paymentMethods = this.paymentInfo.paymentMethods.map(method => {
            if (method.isFawry() && method.isCash()) {
                method.session = data;
            }

            return method;
        });

        this.paymentInfo.defaultMethod = this.getIndexOf(
            this.paymentInfo.paymentMethods,
            VendorName.Fawry
        );

        this.dispatch('onPaymentInfoUpdate', this.paymentInfo);
        this.dispatch('onShowPaymentInfo', undefined);
    };

    public setIziCashSessionBeforeDisplay = (data: PaymentSessions[VendorName.Izi]) => {
        if (!this.paymentInfo) {
            return;
        }

        this.paymentInfo.paymentMethods = this.paymentInfo.paymentMethods.map(method => {
            if (method.isIzi() && method.isCash()) {
                method.session = data;
            }

            return method;
        });

        this.paymentInfo.defaultMethod = this.getIndexOf(
            this.paymentInfo.paymentMethods,
            VendorName.Izi
        );

        this.dispatch('onPaymentInfoUpdate', this.paymentInfo);
        this.dispatch('onShowPaymentInfo', undefined);
    };

    public onShowPaymentInfo = (callback: VoidFunction) => {
        this.addEventListener('onPaymentInfoUpdate', () => {
            callback();
        });
    };
}

export default PaymentInfoService;
