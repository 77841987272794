import * as Sentry from '@sentry/react';
import i18n from 'i18next';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormFieldView from '@/features/form-field-view';
import { FormValues } from '@/lib/domain/forms/forms';
import Yuno from '@/lib/domain/integrations/yuno';
import PaymentMethod from '@/lib/domain/payments/payment-method';
import SDK from '@/lib/domain/payments/sdk';
import { VendorName } from '@/lib/domain/payments/vendors';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { getCountryCode, getCountryLanguage } from '@/lib/locale';
import { Countries } from '@/lib/locale/countries';
import LogsService from '@/services/logs-service';
import NavigatorService from '@/services/navigator-service';
import { PaymentInfo } from '@/services/payment-info-service';
import TopupService from '@/services/topup-service';
import CenterLoader from '@/shared/ui/layout/center-loader';

type YunoCheckoutControllerProps = {
    amount: string;
    method: PaymentMethod<VendorName.Yuno>;
    paymentInfo: PaymentInfo;
    topupService: TopupService;
    logsService: LogsService;
    navigatorService: NavigatorService;
    onHideGoBack: VoidFunction;
}

const YunoCheckoutController: FC<YunoCheckoutControllerProps> = ({
    amount,
    method,
    paymentInfo,
    topupService,
    logsService,
    navigatorService,
    onHideGoBack,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [showFillForm, setShowFillForm] = useState(method.formFields.length > 0);

    useEffect(() => {
        onHideGoBack();

        if (method.formFields.length === 0) {
            handleSubmit({});
        }

    }, []);

    const handleError = useCallback((e: string) => {
        logsService.write(e);

        if (e === 'CANCELED_BY_USER') {
            navigatorService.goHome();

            return;
        }

        navigatorService.showFinalPage('error', {
            message: `yuno checkout: ${e}`
        });
    }, []);

    const handleSubmit = async (values: FormValues): Promise<GQLErrorType> => {
        return new Promise((resolve) => {
            setShowFillForm(false);
            setLoading(true);

            const sdk = new SDK(logsService);
            sdk.get(VendorName.Yuno).then((initialize => {
                const yunoInstance = initialize(method.session.apiKey);
                topupService.setYunoInstance(yunoInstance);

                try {
                    yunoInstance.startCheckout({
                        checkoutSession: method.session.sessionToken,
                        elementSelector: `#${Yuno.CHECKOUT_ELEMENT_ID}`,
                        countryCode: getCountryCode(paymentInfo.country),
                        language: Yuno.onlyYunoLanguages(getCountryLanguage(paymentInfo.country) || i18n.language),
                        showLoading: true,
                        card: {
                            // Battle of Jamaica Conversion
                            // https://indriver.slack.com/archives/C03TDLP7AQM/p1708959077412249
                            cardSaveEnable: paymentInfo.country !== Countries.Jamaica
                        },
                        yunoCreatePayment: async (oneTimeToken: string) => {
                            setLoading(true);
                            const result = await topupService.topUp(
                                amount,
                                method,
                                {
                                    ...values,
                                    oneTimeToken,
                                },
                                paymentInfo.country,
                            );

                            resolve(result);
                            setLoading(false);
                        },
                        yunoError: handleError,
                    });
                } catch (e) {
                    Sentry.captureException(`yuno checkout error: ${e}`);
                    logsService.write(`yuno checkout error: ${e}`);
                }

                yunoInstance.mountCheckoutLite({
                    paymentMethodType: method.session.methodType,
                    vaultedToken: method.session.vaultedToken
                }).then(() => setLoading(false));
            }));
        });
    };

    return (
        <CenterLoader
            loading={loading}
            overlay
        >
            <div id={Yuno.CHECKOUT_ELEMENT_ID} />

            {

                showFillForm ? (
                    <FormFieldView
                        country={paymentInfo.country}
                        savedDocuments={paymentInfo.savedDocuments}
                        formFields={method.formFields}
                        onSubmit={handleSubmit}
                        childrenTitle={t('views.PaymentMethods.BankCard.creditCardTitle')}
                    />
                ) : null
            }
        </CenterLoader>
    );
};

export default YunoCheckoutController;
