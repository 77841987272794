import { Accordion as AccordionNova, Divider } from '@indriver/nova';
import React, { useState } from 'react';

import * as UI from './ui';

type AccordionItemType = {
    label: string;
    content: React.ReactNode;
};

export type AccordionProps = {
    title?: string;
    items: AccordionItemType[];
};

const Accordion: React.FC<AccordionProps> = ({ title, items }) => {
    return (
        <UI.Wrapper>
            {title ? <UI.Title>{title}</UI.Title> : null}

            {
                items.map((item, index) => (
                    <>
                        <AccordionItem
                            key={index}
                            label={item.label}
                            content={item.content}
                        />

                        {items.length>1 && index < items.length - 1 && <Divider />}
                    </>
                ))
            }
        </UI.Wrapper>
    );
};

type AccordionItemProps = {
    label: string;
    content: React.ReactNode;
};

const AccordionItem: React.FC<AccordionItemProps> = ({ label, content }) => {
    const [opened, setOpened] = useState(false);

    const handleToggle = () => {
        setOpened(prev => !prev);
    };

    return (
        <AccordionNova
            label={label}
            opened={opened}
            onClick={handleToggle}
        >
            <UI.Content $opened={opened}>{content}</UI.Content>
        </AccordionNova>
    );
};

export default Accordion;
