import i18n from 'i18next';

import { VendorName } from '@/lib/domain/payments/vendors';
import Text from '@/lib/format/text';
import Validation from '@/lib/forms/validation';
import { Countries } from '@/lib/locale/countries';
import ConfigurationService from '@/services/configuration-service';

import PaymentMethod from '../../payments/payment-method';

import {
    getBillingCityField,
    getCityField,
    getDocumentField,
    getEmailField,
    getFirstnameField,
    getFullAddressField,
    getFullBillingAddressField,
    getHouseField,
    getLastnameField,
    getPayrailsRequestedResultField,
    getPhoneField,
    getStateField,
    getStreetField,
    getUsernameField,
    getZipField
} from '../forms/fields';
import {
    getBillingAddressSection,
    getHomeAddressSection,
    getPersonalDetailsSection
} from '../forms/sections';

/** @deprecated */
class FormFieldsClient {
    private configurationService: ConfigurationService;

    constructor(configurationService: ConfigurationService) {
        this.configurationService = configurationService;
    }

    public getFormFields = (
        method: PaymentMethod<any>,
        country: Countries,
    ) => {
        if (method.isPayrails()) {
            return this.payrails(method, country);
        }

        if (method.isNuvei()) {
            return this.nuvei();
        }

        if (method.isIyzico()) {
            return this.iyzico();
        }

        if (method.isSimpaisa()) {
            return this.simpaisa(method);
        }

        if (method.isWooppay()) {
            return this.wooppay(method, country);
        }

        if (method.isDlocal()) {
            return this.dlocal(method, country);
        }

        if (method.isCredorax()) {
            return this.credorax(method);
        }

        if (method.isOneVision()) {
            return this.onevision(method);
        }

        if (method.isIzi()) {
            return this.izi(method, country);
        }

        if (method.isYuno()) {
            return this.yuno(method, country);
        }

        if (method.isFawry()) {
            return this.fawry(method);
        }

        if (method.isBcel()) {
            return this.bcel();
        }

        return [];
    };

    public getAdditionalFormFields = (method: PaymentMethod<any>) => {
        if (method.isIyzico()) {
            return this.iyzicoAdditional();
        }

        return undefined;
    };

    private payrails = (
        method: PaymentMethod<VendorName.Payrails>,
        country: Countries,
    ) => {
        /**
         * @link {https://indriver.atlassian.net/browse/PRC-3170}
         */
        const hide = this.configurationService.isProd();

        if (method.isBankCard()) {
            return [
                getPersonalDetailsSection(
                    [
                        // TODO https://indriver.atlassian.net/browse/PRC-3074
                        getDocumentField(country, method.providerName),
                        getPayrailsRequestedResultField({
                            hide,
                        })
                    ]
                )
            ];
        }

        // https://indriver.slack.com/archives/C03TDLP7AQM/p1727101556899039
        switch (country) {
            case Countries.Egypt:
            case Countries.Pakistan:
            case Countries.India:
                return [
                    getPersonalDetailsSection(
                        [
                            getDocumentField(country),
                            getPayrailsRequestedResultField({
                                hide,
                            })
                        ]
                    )
                ];
            default:
                return [
                    getPersonalDetailsSection(
                        [
                            getPayrailsRequestedResultField({
                                hide
                            })
                        ]
                    )
                ];
        }
    };

    private nuvei = () => {
        return [
            getPersonalDetailsSection([
                getEmailField()
            ])
        ];
    };

    private iyzico = () => {
        return [
            getPersonalDetailsSection([getDocumentField(Countries.Turkey)]),
            getHomeAddressSection([
                getCityField({
                    validators: [
                        Validation.required,
                        Validation.stringSizeValidator(1, 50),
                    ],
                }),
                getFullAddressField({
                    validators: [
                        Validation.required,
                        Validation.stringSizeValidator(1, 255),
                    ],
                }),
            ]),
        ];
    };

    private iyzicoAdditional = () => {
        return {
            label: i18n.t('services.formBuilder.sections.sameAddressLabel'),
            fields: [
                getBillingAddressSection([
                    getBillingCityField({
                        validators: [
                            Validation.required,
                            Validation.stringSizeValidator(1, 50),
                        ],
                    }),
                    getFullBillingAddressField({
                        validators: [
                            Validation.required,
                            Validation.stringSizeValidator(1, 255),
                        ],
                    }),
                ]),
            ],
        };
    };

    private simpaisa = (method: PaymentMethod<VendorName.Simpaisa>) => {
        if (method.isProviderControlled()) {
            return [
                getPersonalDetailsSection([
                    getPhoneField(
                        {
                            mask: '+{0}00000000000000',
                            validators: [Validation.required]
                        }
                    )
                ])
            ];
        }

        return [];
    };

    private wooppay = (method: PaymentMethod<VendorName.Wooppay>, country: Countries) => {
        if (method.isPhoneBalance()) {
            switch (country) {
                case Countries.Kazakhstan:
                    return [
                        getPersonalDetailsSection([
                            getPhoneField({
                                mask: '+{7}(000)000-00-00',
                            }),
                        ]),
                    ];

                default:
                    return [
                        getPersonalDetailsSection([
                            getPhoneField(),
                        ]),
                    ];
            }
        }

        return [];
    };

    private dlocal = (method: PaymentMethod<VendorName.Dlocal>, country: Countries) => {
        if (method.isBankCard()) {
            if (method.savedInstruments?.bankCard) {
                switch (country) {
                    case Countries.SouthAfrica:
                        return [
                            getPersonalDetailsSection([
                                getEmailField({ formatter: Text.replaceIndriverEmail })
                            ])];
                    default:
                        return [
                            getPersonalDetailsSection([
                                getDocumentField(country),
                                getEmailField({ formatter: Text.replaceIndriverEmail })
                            ])
                        ];
                }
            }

            switch (country) {
                case Countries.India:
                    return [
                        getPersonalDetailsSection([
                            getEmailField({ formatter: Text.replaceIndriverEmail }),
                            getDocumentField(country),
                        ]),
                        getBillingAddressSection([
                            getStateField(),
                            getCityField(),
                            getZipField(),
                            getStreetField(),
                            getHouseField(),
                        ]),
                    ];
                case Countries.SouthAfrica:
                    if (method.isCustomerSaved) {
                        return [
                            getPersonalDetailsSection([
                                getEmailField({ formatter: Text.replaceIndriverEmail }),
                            ]),
                        ];
                    }

                    return [
                        getPersonalDetailsSection([
                            getEmailField({ formatter: Text.replaceIndriverEmail }),
                            getStateField(),
                            getCityField(),
                            getStreetField(),
                            getHouseField(),
                            getZipField(),
                        ]),
                    ];
                case Countries.Morocco:
                    return [
                        getPersonalDetailsSection([
                            getEmailField({ formatter: Text.replaceIndriverEmail }),
                            getDocumentField(country),
                        ])
                    ];
                default:
                    return [
                        getPersonalDetailsSection([
                            getEmailField({ formatter: Text.replaceIndriverEmail }),
                            getDocumentField(country),
                        ]),
                    ];
            }
        }

        if (method.isWallet() || method.isBankTransfer()) {
            switch (country) {
                case Countries.SouthAfrica:
                    if (method.isCustomerSaved) {
                        return [
                            getPersonalDetailsSection([
                                getUsernameField(),
                                getEmailField(),
                            ]),
                        ];
                    }

                    return [
                        getPersonalDetailsSection([
                            getUsernameField(),
                            getStateField(),
                            getCityField(),
                            getStreetField(),
                            getHouseField(),
                            getZipField(),
                            getEmailField(),
                        ]),
                    ];
                default:
                    return [
                        getPersonalDetailsSection([
                            getUsernameField(),
                            getDocumentField(country),
                            getEmailField(),
                        ]),
                    ];
            }
        }

        if (method.isCash()) {
            switch (country) {
                case Countries.Morocco:
                    return [
                        getPersonalDetailsSection([
                            getUsernameField(),
                            getEmailField(),
                        ]),
                    ];
                default:
                    return [
                        getPersonalDetailsSection([
                            getUsernameField(),
                            getEmailField(),
                            getDocumentField(country),
                        ]),
                    ];
            }
        }

        return [];
    };

    private credorax = (method: PaymentMethod<VendorName.Credorax>) => {
        if (method.isBankCard()) {
            if (method.savedInstruments?.bankCard) {
                return [
                    getPersonalDetailsSection([
                        getFirstnameField(),
                        getLastnameField(),
                    ]),
                ];
            }

            return [
                getBillingAddressSection([
                    getCityField({
                        validators: [
                            Validation.required,
                            Validation.stringSizeValidator(3, 30),
                        ]
                    }),
                    getStreetField({
                        validators: [
                            Validation.required,
                            Validation.stringSizeValidator(4, 50),
                        ]
                    }),
                    getHouseField({
                        validators: [
                            Validation.required,
                            Validation.stringSizeValidator(1, 16),
                        ]
                    }),
                    getZipField({
                        validators: [
                            Validation.required,
                            Validation.stringSizeValidator(1, 9),
                            Validation.zipCode,
                        ]
                    }),
                    getEmailField(),
                ]),
            ];
        }

        return [];
    };

    private onevision = (method: PaymentMethod<VendorName.OneVision>) => {
        if (method.isBankCard()) {
            return [
                getPersonalDetailsSection([
                    getPhoneField({
                        mask: '+{000} 000 000 000',
                    }),
                ]),
            ];
        }

        if (method.isPhoneBalance()) {
            return [
                getPersonalDetailsSection([
                    getPhoneField({
                        mask: '+{000} 000 000 000',
                    }),
                ]),
            ];
        }

        return [];
    };

    private izi = (method: PaymentMethod<VendorName.Izi>, country: Countries) => {
        if (method.isBankCard()) {
            return [];
        }

        if (method.isCash()) {
            return [
                getPersonalDetailsSection([
                    getEmailField(),
                    getDocumentField(country),
                ]),
            ];
        }

        return [];
    };

    private yuno = (method: PaymentMethod<VendorName.Yuno>, country: Countries) => {
        if (method.isBankCard()) {
            if (country === Countries.Brazil) {
                return [
                    getPersonalDetailsSection([
                        getDocumentField(country),
                        getEmailField({ formatter: Text.replaceIndriverEmail }),
                    ]),
                ];
            }

            if (
                country === Countries.Mexico ||
                country === Countries.Peru ||
                country === Countries.Chile ||
                country === Countries.Panama
            ) {
                return [
                    getPersonalDetailsSection([
                        getFirstnameField(),
                        getLastnameField(),
                        getEmailField({ formatter: Text.replaceIndriverEmail }),
                    ]),
                ];
            }

            if (
                country === Countries.Colombia ||
                country === Countries.Argentina
            ) {
                return [
                    getPersonalDetailsSection([
                        getEmailField({ formatter: Text.replaceIndriverEmail }),
                        getDocumentField(country),
                    ]),
                ];
            }

            return [
                getPersonalDetailsSection([
                    getEmailField({ formatter: Text.replaceIndriverEmail }),
                ]),
            ];
        }

        return [];
    };

    private fawry = (method: PaymentMethod<VendorName.Fawry>) => {
        if (method.isCash()) {
            return [
                getPersonalDetailsSection([
                    getEmailField(),
                ]),
            ];
        }

        return [];
    };

    private bcel = () => {
        return [
            getPersonalDetailsSection([
                getEmailField(),
            ]),
            getHomeAddressSection(
                [
                    getStateField(),
                    getCityField(),
                    getStreetField(),
                    getHouseField(),
                    getZipField(),
                ]
            )
        ];
    };
}

export default FormFieldsClient;
