import { FC, useEffect, useState } from 'react';

import { FormValues } from '@/lib/domain/forms/forms';
import PaymentMethod from '@/lib/domain/payments/payment-method';
import SDK from '@/lib/domain/payments/sdk';
import { VendorName } from '@/lib/domain/payments/vendors';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { COMMON_LOCALE_INFO } from '@/lib/locale';
import ConfigurationService from '@/services/configuration-service';
import LogsService from '@/services/logs-service';
import NavigatorService from '@/services/navigator-service';
import NuveiService from '@/services/nuvei-service';
import { PaymentInfo } from '@/services/payment-info-service';
import PaymentProviderService from '@/services/payment-provider-service';
import TopupService from '@/services/topup-service';
import CenterLoader from '@/shared/ui/layout/center-loader';


import NuveiNewBankCardView from './new-card';
import NuveiSavedBankCardView from './saved-card';

import { TopupWithNuveiResponse } from '../../../../../../graphql/bank-card/nuvei/top-up-with-nuvei';
import Resources from '../../../../../../resources';

type NuveiBankCardControllerProps = {
    amount: string;
    method: PaymentMethod<VendorName.Nuvei>;
    paymentInfo: PaymentInfo;
    topupService: TopupService;
    navigatorService: NavigatorService;
    logsService: LogsService;
    paymentProviderService: PaymentProviderService;
    configurationService: ConfigurationService;
}

const NuveiBankCardController: FC<NuveiBankCardControllerProps> = ({
    amount,
    method,
    paymentInfo,
    topupService,
    navigatorService,
    logsService,
    paymentProviderService,
    configurationService,
}) => {
    const [nuveiService, setNuveiService] = useState<NuveiService | undefined>();

    const initService = async () => {
        const sdk = new SDK(logsService);
        let nuveiData: TopupWithNuveiResponse | null | undefined;

        await paymentProviderService.process(async (req) => {
            const { data } = await req.topupWithNuvei({
                amount: parseInt(amount, 10)
            });

            nuveiData = data;
        });

        if (!nuveiData) {
            return;
        }

        const SafeCharge = await sdk.get(VendorName.Nuvei);
        const safeCharge = new SafeCharge({
            env: configurationService.isProd() ? 'prod' : 'int',
            merchantId: Resources.NUVEI_SDK.MERCHANT_ID,
            merchantSiteId: Resources.NUVEI_SDK.MERCHANT_SITE_ID,
            sessionToken: nuveiData.topupWithNuvei.providerSession
        });

        setNuveiService(new NuveiService({
            configurationService,
            safeCharge,
            paymentID: nuveiData.topupWithNuvei.paymentID,
            sessionToken: nuveiData.topupWithNuvei.providerSession,
            country: COMMON_LOCALE_INFO[paymentInfo.country][0]
        }));
    };

    const formFields = method.formFields;
    const isInstrument = method.isInstrument();

    useEffect(() => {
        if (!isInstrument) {
            initService();
        }
    }, []);

    const handleSubmitSavedCard = async (values: FormValues): Promise<GQLErrorType> => {
        const paymentInstrumentID = method.savedInstruments?.bankCard?.[0]?.id;
        if (!paymentInstrumentID) {
            return;
        }

        return paymentProviderService.process(async (req) => {
            const { data } = await req.topupWithNuveiSavedCard({
                email: values.email,
                amount: parseInt(amount, 10),
                paymentInstrumentID
            });

            if (data) {
                await navigatorService.showFinalPage('success');

                return;
            }
        });
    };

    const handleSubmitCard = async (values: FormValues): Promise<GQLErrorType> => {
        return topupService.topUp(
            amount,
            method,
            values,
            paymentInfo.country,
        );
    };

    if (isInstrument) {
        return (
            <NuveiSavedBankCardView
                country={paymentInfo.country}
                savedDocuments={paymentInfo.savedDocuments}
                formFields={formFields}
                onSubmit={handleSubmitSavedCard}
            />
        );
    }

    if (!nuveiService) {
        return <CenterLoader loading />;
    }

    return (
        <NuveiNewBankCardView
            nuveiService={nuveiService}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={formFields}
            onSubmit={handleSubmitCard}
        />
    );
};

export default NuveiBankCardController;
