import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import MarkdownViewer from '@/features/markdown-viewer';

type KaspiBankTransferProps = {
    referenceNumber: string;
    redirectUrl: string;
    onRedirect: (redirectUrl: string) => void;
    onCopy: (paycode: string, title: string) => void;
}

const KaspiBankTransfer: FC<KaspiBankTransferProps> = ({
    referenceNumber,
    redirectUrl,
    onRedirect,
    onCopy,
}) => {
    const { t } = useTranslation();

    const handleSubmit = useCallback(() => {
        onRedirect(redirectUrl);
    }, [redirectUrl]);

    const handleCopyPaycode = useCallback(() => {
        onCopy(referenceNumber, t('views.PaymentMethods.BankTransfer.Kaspi.referenceCopiedLabel'));
    }, [referenceNumber, onCopy]);

    return (
        <MarkdownViewer>
            {
                [
                    { type: MarkdownViewer.COPY_BLOCK, content: {
                        title: referenceNumber,
                        subtitle: t('views.PaymentMethods.BankTransfer.Kaspi.referenceTitle'),
                        onClick: handleCopyPaycode,
                    } },
                    { type: MarkdownViewer.TITLE, content: t('features.HowToTopUpInfo.title') },
                    {
                        type: MarkdownViewer.NUMBERED_LIST,
                        content: [
                            t('views.PaymentMethods.BankTransfer.Kaspi.stepOne'),
                            t('views.PaymentMethods.BankTransfer.Kaspi.stepTwo'),
                        ],
                    },
                    { type: MarkdownViewer.SUBMIT, content: {
                        caption: t('views.PaymentMethods.BankTransfer.Kaspi.submitCaption'),
                        onClick: handleSubmit
                    } }
                ]
            }
        </MarkdownViewer>
    );
};

export default KaspiBankTransfer;
