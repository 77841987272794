import { FC, useCallback, useState } from 'react';

import { FormValues } from '@/lib/domain/forms/forms';
import { PHONE_ID } from '@/lib/domain/forms/forms/indentificators';
import { getCodeConfirmationSections } from '@/lib/domain/forms/forms/sections';
import PaymentMethod from '@/lib/domain/payments/payment-method';
import { VendorName } from '@/lib/domain/payments/vendors';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { PaymentInfo } from '@/services/payment-info-service';
import PaymentProviderService from '@/services/payment-provider-service';
import TopupService from '@/services/topup-service';

import WooppayPhoneBalanceView from './balance-view';

import { WooppaySession } from '../../../../../../graphql/phone-balance/woopay';


type WooppayPhoneTransferControllerProps = {
    amount: string;
    method: PaymentMethod<VendorName.Wooppay>;
    paymentInfo: PaymentInfo;
    topupService: TopupService;
    paymentProviderService: PaymentProviderService;
}

// Test numbers: 77478074392|77478074393
// Confirmation code: 111111
const WooppayPhoneTransferController: FC<WooppayPhoneTransferControllerProps> = ({
    amount,
    method,
    paymentInfo,
    topupService,
    paymentProviderService,
}) => {
    const [confirmationFields] = useState(getCodeConfirmationSections());
    const [session, setSession] = useState<WooppaySession|undefined>(undefined);

    const handleCreateSession = useCallback((values: FormValues): Promise<GQLErrorType> => {
        return paymentProviderService.process(async (req) => {
            const { data } = await req.createWoopaySession({
                [PHONE_ID]: values.phone,
                amount: parseInt(amount, 10)
            });

            if (data?.createWooppaySession) {
                topupService.setWooppaySession(data.createWooppaySession);
                setSession(data.createWooppaySession);
            }
        });
    }, []);

    const handleCodeConfirm = useCallback((values: FormValues): Promise<GQLErrorType> => {
        return topupService.topUp(
            amount,
            method,
            values,
            paymentInfo.country,
        );
    }, [session]);

    if (!session) {
        return (
            <WooppayPhoneBalanceView
                country={paymentInfo.country}
                savedDocuments={paymentInfo.savedDocuments}
                formFields={method.formFields}
                onSubmit={handleCreateSession}
            />
        );
    }

    return (
        <WooppayPhoneBalanceView
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={confirmationFields}
            onSubmit={handleCodeConfirm}
        />
    );
};

export default WooppayPhoneTransferController;
