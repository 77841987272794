import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import MarkdownViewer from '@/features/markdown-viewer';

type OnoiCashProps = {
    referenceNumber: string;
    onCopy: (paycode: string, title: string) => void;
}

const OnoiCash: FC<OnoiCashProps> = ({ referenceNumber, onCopy }) => {
    const { t } = useTranslation();

    const handleCopyPaycode = useCallback(() => {
        onCopy(referenceNumber, t('views.PaymentMethods.Cash.Izi.voucherCopiedLabel'));
    }, [referenceNumber, onCopy]);

    return (
        <MarkdownViewer>
            {
                [
                    { type: MarkdownViewer.COPY_BLOCK, content: {
                        title: referenceNumber,
                        subtitle: t('views.PaymentMethods.Cash.Onoi.referenceTitle'),
                        onClick: handleCopyPaycode,
                    } },
                    { type: MarkdownViewer.TITLE, content: t('views.PaymentMethods.Cash.Onoi.instruction1.title') },
                    {
                        type: MarkdownViewer.NUMBERED_LIST,
                        content: [
                            t('views.PaymentMethods.Cash.Onoi.instruction1.steps1'),
                        ],
                    },
                    {
                        type: MarkdownViewer.ACCORDION,
                        content: {
                            items: [
                                {
                                    label: t('views.PaymentMethods.Cash.Onoi.instruction2.title'), content: (
                                        <ul>
                                            <li>{t('views.PaymentMethods.Cash.Onoi.instruction2.steps1')}</li>
                                            <li>{t('views.PaymentMethods.Cash.Onoi.instruction2.steps2')}</li>
                                            <li>{t('views.PaymentMethods.Cash.Onoi.instruction2.steps3')}</li>
                                            <li>{t('views.PaymentMethods.Cash.Onoi.instruction2.steps4')}</li>
                                            <li>{t('views.PaymentMethods.Cash.Onoi.instruction2.steps5')}</li>
                                            <li>{t('views.PaymentMethods.Cash.Onoi.instruction2.steps6')}</li>
                                            <li>{t('views.PaymentMethods.Cash.Onoi.instruction2.steps7')}</li>
                                            <li>{t('views.PaymentMethods.Cash.Onoi.instruction2.steps8')}</li>
                                            <li>{t('views.PaymentMethods.Cash.Onoi.instruction2.steps9')}</li>
                                        </ul>
                                    )
                                },
                                {
                                    label: t('views.PaymentMethods.Cash.Onoi.instruction3.title'), content:(
                                        <ul>
                                            <li>{t('views.PaymentMethods.Cash.Onoi.instruction3.steps1')}</li>
                                            <li>{t('views.PaymentMethods.Cash.Onoi.instruction3.steps2')}</li>
                                            <li>{t('views.PaymentMethods.Cash.Onoi.instruction3.steps3')}</li>
                                            <li>{t('views.PaymentMethods.Cash.Onoi.instruction3.steps4')}</li>
                                            <li>{t('views.PaymentMethods.Cash.Onoi.instruction3.steps5')}</li>
                                            <li>{t('views.PaymentMethods.Cash.Onoi.instruction3.steps6')}</li>
                                            <li>{t('views.PaymentMethods.Cash.Onoi.instruction3.steps7')}</li>
                                            <li>{t('views.PaymentMethods.Cash.Onoi.instruction3.steps8')}</li>
                                        </ul>
                                    )
                                }
                            ]
                        }
                    },
                ]
            }
        </MarkdownViewer>
    );
};

export default OnoiCash;
