import * as Sentry from '@sentry/react';

import EventEmitter from '@/lib/subscriber';
import ConfigurationService from '@/services/configuration-service';

import Resources from '../../resources';

declare global {
    interface Window {
        /** out of app log method, see index.html */
        access: (level: string) => void;
        accessLog: string[];
        globalError: (e: any) => void;
    }
}

class LogsService extends EventEmitter<{ 'onLogsUpdate': string[] }>{
    private messages: string[];
    private configurationService: ConfigurationService;

    constructor(configurationService: ConfigurationService) {
        super();

        this.messages = [];
        this.configurationService = configurationService;
    }
    public write = (msg: string): void => {
        const message = `${msg}:${performance.now().toFixed()}`;
        this.messages.push(message);
        (window.accessLog || []).push(message);
        this.dispatch('onLogsUpdate', this.messages);

        console.info(message);
    };

    public getLogs = (): string[] => {
        return this.messages;
    };

    public writeInitialLogLine = () => {
        this.write([
            'payment-provider-webview-log',
            `ua_${window.navigator.userAgent}`,
            `androidcheck_${window.Android?.postMessage ? 1 : 0}`,
            `ioscheck_${window.webkit?.messageHandlers?.inDriveriOSHandler ? 1 : 0}`,
            `${navigator?.languages?.toString()}`
        ].join(''));
    };

    public initBugtracker = () => {
        if (!this.configurationService.isDev()) {
            Sentry.init({
                dsn: Resources.SENTRY_DSN,
                debug: true
            });
        }
    };

    public handleGlobalError = () => {
        window.globalError = function (e: any) {
            console.log('err', e);
            Sentry.captureException(e);
        };
    };

    public handleCSPErrors = () => {
        window.addEventListener('securitypolicyviolation', (event) => {
            const message = `CSP error - ${event.blockedURI}`;
            const error = new Error(message);
            const extra = {
                directive: event.violatedDirective,
                blockedURI: event.blockedURI,
                sourceFile: event.sourceFile,
            };

            Sentry.captureException(error, {
                extra
            });
        });
    };
}

export default LogsService;
