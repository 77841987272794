import * as Sentry from '@sentry/react';

import JWTToken from '@/lib/jwt';
import LogsService from '@/services/logs-service';
import MobilePlatformService from '@/services/mobile-platform-service';

// https://indriver.atlassian.net/wiki/spaces/UXD/pages/1343422465/balance+New+Order
export type AnalyticEventMap = {
    'processing.topup_saved_card_delete.click': undefined;
    'processing.topup_pay.click': {
        payment_option: string;
        provider: string;
        sum: number;
    };
    'processing.topup_pay.amount_click': {
        amount?: string;
        days?: number;
        rides?: number;
    };
    'processing.topup_pay_securely.click': {
        amount_is_from_tips: boolean;
        amount: string;
    };
    'processing.topup_saved_card_pay.click': undefined;
    'processing.topup_payment_option.click': {
        payment_option: string;
        provider: string;
    };
    'processing.topup_pay_auto.click': undefined;
    'processing.topup_auto.view': undefined;
    'processing.topup_auto_enable.click': {
        enable: boolean;
        amount: string;
        balance_threshold: string;
        payment_option: string;
        provider: string;
    };
    'processing.topup_auto_save.click': {
        enable: boolean;
        amount: string;
        balance_threshold: string;
        payment_option: string;
        provider: string;
    }
    'processing.topup_auto_disable.click': {
        enable: boolean;
        amount: string;
        balance_threshold: string;
        payment_option: string;
        provider: string;
    }
};

class AnalyticsService {
    private logsService: LogsService;
    private mobilePlatformService: MobilePlatformService;
    private jwt?: JWTToken;

    constructor(
        logsService: LogsService,
        mobilePlatformService: MobilePlatformService,
    ) {
        this.logsService = logsService;
        this.mobilePlatformService = mobilePlatformService;
    }

    public async setJWT(jwt: JWTToken): Promise<void> {
        this.jwt = jwt;
    }

    public event<K extends keyof AnalyticEventMap> (eventName: K, data: AnalyticEventMap[K]): void {
        this.logsService.write(`event_${eventName}_${JSON.stringify(data)}`);

        if (!this.jwt) {
            Sentry.captureEvent({
                message: 'Event declined, no JWT',
                level: 'error',
            });

            return;
        }

        const { city_id, country_id, user_id } = this.jwt.getToken();

        const payload = {
            country_id,
            city_id,
            user_id,
            ...data,
        };

        this.mobilePlatformService.sendAnalyticsMessage({
            name: eventName,
            payload
        });
    }
}

export default AnalyticsService;
