import { FC, useEffect, useState } from 'react';

import DisplayedError from '@/lib/errors/displayed-error';
import AnalyticsService from '@/services/analytics-service';
import AuthorizationService from '@/services/authorization-service';
import ConfigurationService from '@/services/configuration-service';
import LogsService from '@/services/logs-service';
import MobilePlatformService from '@/services/mobile-platform-service';
import NavigatorService from '@/services/navigator-service';
import NotificationService from '@/services/notification-service';
import PaymentInfoService, { PaymentInfo } from '@/services/payment-info-service';
import PaymentProviderService from '@/services/payment-provider-service';
import TopupService from '@/services/topup-service';
import CenterLoader from '@/shared/ui/layout/center-loader';

import ViewService from '../../services/view-service';

import InternalErrorPage from './pages/internal-error-page';
import Main from './pages/main';

export type AppData = {
    paymentInfo: PaymentInfo;
    services: {
        configurationService: ConfigurationService;
        authorizationService: AuthorizationService;
        paymentProviderService: PaymentProviderService,
        paymentInfoService: PaymentInfoService,
        topupService: TopupService;
        viewService: ViewService;
        analyticService: AnalyticsService;
        navigatorService: NavigatorService;
        mobilePlatformService: MobilePlatformService;
        logsService: LogsService;
        notificationService: NotificationService;
    };
};

type SinglepageProps = {
    authorizationService: AuthorizationService;
    navigatorService: NavigatorService;
    notificationService: NotificationService
    mobilePlatformService: MobilePlatformService;
    logsService: LogsService;
    configurationService: ConfigurationService;
}

const Singlepage: FC<SinglepageProps> = ({
    authorizationService,
    navigatorService,
    notificationService,
    mobilePlatformService,
    logsService,
    configurationService,
}) => {
    const [paymentProviderService] = useState(new PaymentProviderService(
        navigatorService,
        authorizationService,
        logsService,
        mobilePlatformService,
        configurationService,
    ));

    const [paymentInfoService] = useState(new PaymentInfoService(
        paymentProviderService,
        mobilePlatformService,
        configurationService,
    ));

    const [viewService] = useState(new ViewService());

    const [analyticService] = useState(new AnalyticsService(logsService, mobilePlatformService));

    const [topupService] = useState(new TopupService(
        paymentProviderService,
        paymentInfoService,
        analyticService,
        navigatorService,
    ));

    const [error, setError] = useState<DisplayedError | undefined>();
    const [paymentInfo, setPaymentInfo] = useState<PaymentInfo | undefined>();

    useEffect(() => {
        window.access('getbackenddata');
        paymentInfoService.addEventListener('onPaymentInfoUpdate', (info) => {
            setPaymentInfo({ ...info });
        });
        paymentInfoService.addEventListener('onError', setError);
        authorizationService.addEventListener('onTimeout', setError);

        authorizationService.refresh((jwt) => {
            window.access('receivedauth');
            paymentInfoService.init(jwt);
            analyticService.setJWT(jwt);
        });
    }, []);

    if (error) {
        return (
            <InternalErrorPage
                navigatorService={navigatorService}
                error={error}
            />
        );
    }

    if (!paymentInfo) {
        return <CenterLoader loading />;
    }

    return (
        <Main
            paymentInfo={paymentInfo}
            services={
                {
                    authorizationService,
                    notificationService,
                    analyticService,
                    paymentInfoService,
                    viewService,
                    paymentProviderService,
                    navigatorService,
                    logsService,
                    configurationService,
                    mobilePlatformService: mobilePlatformService,
                    topupService,
                }
            }
        />
    );
};

export default Singlepage;
