import { FC, useCallback, useEffect, useState } from 'react';

import { FormValues } from '@/lib/domain/forms/forms';
import PaymentMethod from '@/lib/domain/payments/payment-method';
import { VendorName } from '@/lib/domain/payments/vendors';
import ConfigurationService from '@/services/configuration-service';
import LogsService from '@/services/logs-service';
import NavigatorService from '@/services/navigator-service';
import { PaymentInfo } from '@/services/payment-info-service';
import PaymentProviderService from '@/services/payment-provider-service';
import TopupService from '@/services/topup-service';
import ViewService from '@/services/view-service';
import CenterLoader from '@/shared/ui/layout/center-loader';

import PayrailsNewBankCardView from './new-card';
import PayrailsSavedCardView from './saved-card';

import { PayrailsInitOptions } from '../../../../../../graphql/payrails';

type PayrailsBankCardControllerProps = {
    amount: string;
    method: PaymentMethod<VendorName.Payrails>;
    paymentInfo: PaymentInfo;
    topupService: TopupService;
    navigatorService: NavigatorService;
    logsService: LogsService;
    paymentProviderService: PaymentProviderService;
    viewService: ViewService;
    configurationService: ConfigurationService;
}

const PayrailsBankCardController: FC<PayrailsBankCardControllerProps> = ({
    amount,
    method,
    paymentInfo,
    topupService,
    navigatorService,
    logsService,
    paymentProviderService,
    viewService,
    configurationService,
}) => {
    const [initOptions, setInitOptions] = useState<PayrailsInitOptions | undefined>();

    useEffect(() => {
        paymentProviderService.process(async (req) => {
            const { data } = await req.getPayrailsInitOptions({
                instrumentID: method.session.instrumentId || '',
            });

            setInitOptions(data?.payrailsInitOptions);
        });
    }, []);

    const handleSubmit = useCallback((values: FormValues) => {
        return topupService.topUp(
            amount,
            method,
            values,
            paymentInfo.country,
        );
    }, []);

    const handleError = useCallback((error: string) => {
        navigatorService.showFinalPage('error', {
            message: error,
        });
    }, []);

    if (!initOptions) {
        return <CenterLoader loading />;
    }

    return method.isInstrument() ? (
        <PayrailsSavedCardView
            initOptions={initOptions}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            panValue={
                viewService.formatPayrailsMethodName(
                    method.methodName,
                )
            }
            cvvOnly={method.isCvvForSavedCards()}
            formFields={method.formFields}
            logsService={logsService}
            configurationService={configurationService}
            onSubmit={handleSubmit}
            onError={handleError}
        />
    ) : (
        <PayrailsNewBankCardView
            initOptions={initOptions}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={method.formFields}
            logsService={logsService}
            configurationService={configurationService}
            onSubmit={handleSubmit}
            onError={handleError}
        />
    );
};

export default PayrailsBankCardController;
