import Fields from '@/lib/domain/forms/fields';
import FormFieldsClient from '@/lib/domain/forms/form-fields-client';
import FormSection from '@/lib/domain/forms/forms/form-section';
import { Countries } from '@/lib/locale/countries';
import ConfigurationService from '@/services/configuration-service';
import { MapLink, SavedInstrument, } from '@/services/payment-info-service';
import {
    PaymentMethodData
} from '@/services/payment-provider-service/api/init';

import { PaymentType } from '../payment-types';
import { PaymentSessions } from '../session';
import { VendorName } from '../vendors';

class PaymentMethod <T extends keyof PaymentSessions> {
    public available: boolean;
    public methodName: string;
    public providerName: VendorName;
    public isCustomerSaved: boolean;
    public paymentMethodCode?: string;
    public limits: {
        min: number;
        max: number;
    };
    public session: PaymentSessions[T];
    public savedInstruments?: SavedInstrument;
    /** @deprecated */
    public formFields: FormSection[];
    public fields: FormSection[];
    /** @deprecated */
    public additionalFormFields?: {
        label: string;
        fields: FormSection[];
    };
    private country: Countries;
    private paymentType: PaymentType;
    private instrument: boolean;
    private removable: boolean;

    constructor(init: {
        country: Countries,
        methodName: string,
        paymentType: PaymentType,
        providerName: VendorName,
        isCustomerSaved: boolean,
        limits: {
            min: number;
            max: number;
        },
        session: PaymentSessions[T],
    }) {
        this.country = init.country;
        this.paymentType = init.paymentType;

        this.available = true;
        this.methodName = init.methodName;
        this.providerName = init.providerName;
        this.isCustomerSaved = init.isCustomerSaved;
        this.limits = init.limits;
        this.session = init.session;

        this.instrument = false;
        this.removable = false;
        this.formFields = [];
        this.fields = [];
    }

    public isCash = () => this.paymentType === PaymentType.CASH;
    public isBankCard = () => this.paymentType === PaymentType.BANK_CARD;
    public isBankTransfer = () => this.paymentType === PaymentType.BANK_TRANSFER;
    public isWallet = () => this.paymentType === PaymentType.WALLET;
    public isPhoneBalance = () => this.paymentType === PaymentType.PHONE_BALANCE;
    public isTerminal = () => this.paymentType === PaymentType.TERMINAL;
    public isProviderControlled = () => this.paymentType === PaymentType.PROVIDER_CONTROLLED;
    public isYuno = (): this is PaymentMethod<VendorName.Yuno> => this.providerName === VendorName.Yuno;
    public isGifty = (): this is PaymentMethod<VendorName.Gifty> => this.providerName === VendorName.Gifty;
    public isImePay = (): this is PaymentMethod<VendorName.ImePay> => this.providerName === VendorName.ImePay;
    public isPayrails = (): this is PaymentMethod<VendorName.Payrails> => this.providerName === VendorName.Payrails;
    public isDlocal = (): this is PaymentMethod<VendorName.Dlocal> => this.providerName === VendorName.Dlocal;
    public isCredorax = (): this is PaymentMethod<VendorName.Credorax> => this.providerName === VendorName.Credorax;
    public isPuntoxpress = (): this is PaymentMethod<VendorName.Puntoxpress> =>
        this.providerName === VendorName.Puntoxpress;
    public isQiwiKz = (): this is PaymentMethod<VendorName.QiwiKz> => this.providerName === VendorName.QiwiKz;
    public isKassa24 = (): this is PaymentMethod<VendorName.Kassa24> => this.providerName === VendorName.Kassa24;
    public isJetPay = (): this is PaymentMethod<VendorName.JetPay> => this.providerName === VendorName.JetPay;
    public isKaspiKz = (): this is PaymentMethod<VendorName.KaspiKz> => this.providerName === VendorName.KaspiKz;
    public isOnoi = (): this is PaymentMethod<VendorName.Onoi> => this.providerName === VendorName.Onoi;
    public isInDriver = (): this is PaymentMethod<VendorName.InDriver> => this.providerName === VendorName.InDriver;
    public isSlick = (): this is PaymentMethod<VendorName.Slick> => this.providerName === VendorName.Slick;
    public isFawry = (): this is PaymentMethod<VendorName.Fawry> => this.providerName === VendorName.Fawry;
    public isFortumo = (): this is PaymentMethod<VendorName.Fortumo> => this.providerName === VendorName.Fortumo;
    public isWooppay = (): this is PaymentMethod<VendorName.Wooppay> => this.providerName === VendorName.Wooppay;
    public isIyzico = (): this is PaymentMethod<VendorName.Iyzico> => this.providerName === VendorName.Iyzico;
    public isAamarpay = (): this is PaymentMethod<VendorName.Aamarpay> => this.providerName === VendorName.Aamarpay;
    public isIzi = (): this is PaymentMethod<VendorName.Izi> => this.providerName === VendorName.Izi;
    public isOneVision = (): this is PaymentMethod<VendorName.OneVision> => this.providerName === VendorName.OneVision;
    public isSimpaisa = (): this is PaymentMethod<VendorName.Simpaisa> => this.providerName === VendorName.Simpaisa;
    public isNuvei = (): this is PaymentMethod<VendorName.Nuvei> => this.providerName === VendorName.Nuvei;
    public isBcel = (): this is PaymentMethod<VendorName.Bcel> => this.providerName === VendorName.Bcel;
    public isRemovable = () => this.removable;
    public isInstrument = () => this.instrument;
    public haveNoFields = () => this.formFields.length === 0;

    public isOneClickPayForm = (): boolean => {
        if (this.formFields.length === 0 || this.formFields.every(formSection => formSection.isHidden())) {
            if (this.isPayrails()) {
                return true;
            }

            if (this.isSlick()) {
                return true;
            }
        }
        if(this.isIzi() && this.isBankCard()){
            return true;
        }

        {return false;}
    };

    public getPaymentType = () => {
        return this.paymentType;
    };

    public isCvvForSavedCards = () => {
        switch (this.providerName) {
            case VendorName.Dlocal:
                return this.country === Countries.India;
            case VendorName.Payrails:
                return this.country === Countries.India;
        }

        return false;
    };

    public getSupportPhones = () => {
        if (this.isPuntoxpress()) {
            switch (this.country) {
                case Countries.Nicaragua:
                    return ['75167893'];
                case Countries.Honduras:
                    return ['(+504) 2280-8646'];
            }
        }

        return [];
    };

    public getSupportEmails = () => {
        if (this.isPuntoxpress()) {
            switch (this.country) {
                case Countries.Salvador:
                    return ['soporte-sv@puntoxpress.com'];
                case Countries.Nicaragua:
                    return ['soporte-ni@puntoxpress.com'];
                case Countries.Guatemala:
                    return ['servicioalcliente@akisi.com'];
                case Countries.Honduras:
                    return ['servicioalcliente@tengo.hn'];
            }
        }

        return [];
    };

    public getMapLink = () => {
        if (this.isFawry()) {
            const map = {
                link: MapLink.FRAME,
                src: ''
            };

            switch (this.country) {
                case Countries.Egypt:
                    // eslint-disable-next-line max-len
                    map.src = 'https://www.google.com/maps/embed?pb=!1m12!1m8!1m3!1d884584.9286779079!2d30.9832197!3d29.9963851!3m2!1i1024!2i768!4f13.1!2m1!1sfawry!5e0!3m2!1sen!2s!4v1688134429892!5m2!1sen!2s';
                    break;
                default:
                    // eslint-disable-next-line max-len
                    map.src = 'https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1774752.2077379352!2d31.383808372678853!3d29.68170651863243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sFawry!5e0!3m2!1sen!2s!4v1688134504525!5m2!1sen!2s';
            }

            return map;
        }

        if (this.isPuntoxpress()) {
            const map = {
                link: MapLink.FRAME,
                src: ''
            };

            switch (this.country) {
                case Countries.Salvador:
                    map.src = 'https://www.puntoxpress.com/elsalvador/adonde-pago.html';
                    break;
                case Countries.Nicaragua:
                    // eslint-disable-next-line max-len
                    map.src = 'https://www.google.com/maps/d/embed?mid=1wN6rVk9tvrBKB0CVW9TRqBD_oZlnlHg&ehbc=2E312F';
                    break;
                case Countries.Guatemala:
                    // eslint-disable-next-line max-len
                    map.src = 'https://www.google.com/maps/d/embed?mid=1-fdEW7_-nHGtcqOzIMlfuD9bapkk0kDe&ehbc=2E312F';
                    break;
                case Countries.Honduras:
                    // eslint-disable-next-line max-len
                    map.src = 'https://www.google.com/maps/d/embed?mid=1mDFRSqIiH5LhAF4nqfkk8moQo9InhuE&ehbc=2E312F';
                    break;
                default:
                    return undefined;
            }

            return map;
        }

        if (this.isGifty()) {
            return {
                link: MapLink.REDIRECT,
                src: 'https://linktr.ee/indrive.stores'
            };
        }

        if (this.isIzi()) {
            return {
                link: MapLink.FRAME,
                // eslint-disable-next-line max-len
                src: 'https://www.google.com/maps/d/embed?mid=1mdMhkfWsGGzr7ASGqkfyYy6izrMUidw&ll=35.14333147883067%2C9.82947890000002&z=7'
            };
        }

        return undefined;
    };

    public setAvailable = (available: boolean) => {
        this.available = available;
    };

    public setPaymentMethodCode = (code: string) => {
        // TODO https://indriver.atlassian.net/browse/PRC-2356
        this.paymentMethodCode = code;
    };

    public setSavedInstruments = (instruments: SavedInstrument) => {
        this.savedInstruments = instruments;

        // TODO https://indriver.atlassian.net/browse/PRC-2208
        this.setIsInstrument(true);
        this.setIsRemovable(true);
    };

    public setIsInstrument = (isInstrument: boolean) => {
        this.instrument = isInstrument;
    };

    public setIsRemovable = (removable: boolean) => {
        this.removable = removable;
    };

    static create(
        data: PaymentMethodData,
        country: Countries,
        paymentType: PaymentType,
        providerName: VendorName,
        session: PaymentSessions[keyof PaymentSessions],
        configurationService: ConfigurationService,
    ): PaymentMethod<keyof PaymentSessions> {
        const methodName = data.method_name || data.payment_type;

        const method = new PaymentMethod({
            country,
            methodName,
            paymentType,
            providerName,
            isCustomerSaved: data.is_customer_saved,
            limits: {
                min: data.limits.min,
                max: data.limits.max,
            },
            session,
        });

        if (data.provider_method_code) {
            method.setPaymentMethodCode(data.provider_method_code);
        }

        if (data.saved_instruments?.bank_card) {
            method.setSavedInstruments({
                bankCard: data.saved_instruments.bank_card.map(card => ({
                    id: card.bank_card_id,
                    last4: card.last4,
                }))
            });
        }

        if (data.is_removable) {
            method.setIsRemovable(true);
        }

        if (method.isYuno() && method.session) {
            // https://indriver.atlassian.net/browse/PRC-2208
            method.setIsInstrument(data.is_removable);
            method.setIsRemovable(data.is_removable);
        }

        if (method.isPayrails() && method.session.instrumentId) {
            method.setIsInstrument(true);
        }

        const formFieldsClient = new FormFieldsClient(configurationService);
        method.formFields = formFieldsClient.getFormFields(method, country);
        method.additionalFormFields = formFieldsClient.getAdditionalFormFields(method);

        const formFields = new Fields(configurationService);
        const formFieldsConfig = formFields.getFormFieldsConfig(method, country);
        method.fields = formFields.buildSections(formFieldsConfig, country);

        return method;
    }
}

export default PaymentMethod;
