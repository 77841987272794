import { CopyClone } from '@indriver/mireska';
import { Button } from '@indriver/nova';
import { Fragment, Component } from 'react';

import Accordion, { AccordionProps } from '@/shared/ui/core/accordion';

import Resources from '../../resources';

import * as UI from './ui';

type MarkdownBlock =
    | { type: typeof MarkdownViewer.COPY_BLOCK; content: {
        title: string;
        subtitle?: string,
        onClick?: VoidFunction
    } }
    | { type: typeof MarkdownViewer.ALERT; content: string }
    | { type: typeof MarkdownViewer.TITLE; content: string }
    | { type: typeof MarkdownViewer.SUBTITLE; content: string }
    | { type: typeof MarkdownViewer.PARAGRAPH; content: string }
    | { type: typeof MarkdownViewer.NUMBERED_LIST; content: string[] }
    | { type: typeof MarkdownViewer.BULLET_LIST; content: string[] }
    | { type: typeof MarkdownViewer.SUBMIT; content: {
        hide?: boolean;
        caption: string;
        loading?: boolean,
        onClick?: VoidFunction
    } }
    | {
    type: typeof MarkdownViewer.ACCORDION;
    content: AccordionProps;
};

type MarkdownViewerProps = {
    children: MarkdownBlock[];
};

type Block<T> = Extract<MarkdownBlock, { type: T }>

class MarkdownViewer extends Component<MarkdownViewerProps> {
    static COPY_BLOCK = 'copy_block_slug' as const;
    static ALERT = 'alert_slug' as const;
    static TITLE = 'title_slug' as const;
    static SUBTITLE = 'subtitle_slug' as const;
    static PARAGRAPH = 'paragraph_slug' as const;
    static NUMBERED_LIST = 'numbered_list_slug' as const;
    static BULLET_LIST = 'bullet_list_slug' as const;
    static SUBMIT = 'SUBMIT_SLUG' as const;
    static ACCORDION = 'accordion_slug' as const;

    private renderCopyBlock(block: Block<typeof MarkdownViewer.COPY_BLOCK>) {
        return (
            <UI.CopyBlockWrapper
                data-id={Resources.test.markdownViewer.copyBlock}
                onClick={block.content.onClick}
            >
                <UI.CopyBlockContent>
                    <UI.CopyBlockTitle>{block.content.title}</UI.CopyBlockTitle>

                    <UI.CopyBlockSubtitle>
                        {block.content.subtitle}
                    </UI.CopyBlockSubtitle>
                </UI.CopyBlockContent>

                <CopyClone
                    size={24}
                />
            </UI.CopyBlockWrapper>
        );
    }

    private renderAlert(block: Block<typeof MarkdownViewer.ALERT>) {
        return (
            <UI.Alert data-id={Resources.test.markdownViewer.alert}>
                {block.content}
            </UI.Alert>
        );
    }

    private renderTitle(block: Block<typeof MarkdownViewer.TITLE>) {
        return (
            <UI.Title data-id={Resources.test.markdownViewer.title}>
                {block.content}
            </UI.Title>
        );
    }

    private renderSubtitle(block: Block<typeof MarkdownViewer.SUBTITLE>) {
        return (
            <UI.Subtitle data-id={Resources.test.markdownViewer.subtitle}>
                {block.content}
            </UI.Subtitle>
        );
    }

    private renderParagraph(block: Block<typeof MarkdownViewer.PARAGRAPH>) {
        return (
            <UI.Paragraph data-id={Resources.test.markdownViewer.paragraph}>
                {block.content}
            </UI.Paragraph>
        );
    }

    private renderNumberedList(block: Block<typeof MarkdownViewer.NUMBERED_LIST>) {
        return (
            <UI.NumberedList data-id={Resources.test.markdownViewer.numberedList}>
                <ul>
                    {
                        block.content.map((step, index) => (
                            <ol key={index}>{index + 1}. {step}</ol>
                        ))
                    }
                </ul>
            </UI.NumberedList>
        );
    }

    private renderBulletList(block: Block<typeof MarkdownViewer.BULLET_LIST>) {
        return (
            <UI.BulletList data-id={Resources.test.markdownViewer.bulletList}>
                <ul>
                    {
                        block.content.map((step, index) => (
                            <li key={index}>{step}</li>
                        ))
                    }
                </ul>
            </UI.BulletList>
        );
    }

    private renderSubmit(block: Block<typeof MarkdownViewer.SUBMIT>) {
        if (block.content.hide) {
            return null;
        }

        return (
            <UI.SubmitWrapper data-id={Resources.test.markdownViewer.submit}>
                <Button
                    type='submit'
                    loading={block.content.loading}
                    onClick={block.content.onClick}
                >
                    {block.content.caption}
                </Button>
            </UI.SubmitWrapper>
        );
    }

    private renderAccordion(block: Block<typeof MarkdownViewer.ACCORDION>) {
        return (
            <Accordion
                title={block.content.title}
                items={block.content.items}
            />
        );
    }

    public render() {
        return (
            <>
                {
                    this.props.children.map((block, index) => {
                        switch (block.type) {
                            case MarkdownViewer.COPY_BLOCK:
                                return <Fragment key={index}>{this.renderCopyBlock(block)}</Fragment>;
                            case MarkdownViewer.ALERT:
                                return <Fragment key={index}>{this.renderAlert(block)}</Fragment>;
                            case MarkdownViewer.TITLE:
                                return <Fragment key={index}>{this.renderTitle(block)}</Fragment>;
                            case MarkdownViewer.SUBTITLE:
                                return <Fragment key={index}>{this.renderSubtitle(block)}</Fragment>;
                            case MarkdownViewer.PARAGRAPH:
                                return <Fragment key={index}>{this.renderParagraph(block)}</Fragment>;
                            case MarkdownViewer.NUMBERED_LIST:
                                return <Fragment key={index}>{this.renderNumberedList(block)}</Fragment>;
                            case MarkdownViewer.BULLET_LIST:
                                return <Fragment key={index}>{this.renderBulletList(block)}</Fragment>;
                            case MarkdownViewer.SUBMIT:
                                return <Fragment key={index}>{this.renderSubmit(block)}</Fragment>;
                            case MarkdownViewer.ACCORDION:
                                return <Fragment key={index}>{this.renderAccordion(block)}</Fragment>;
                            default:
                                return null;
                        }
                    })
                }
            </>
        );
    }
}

export default MarkdownViewer;
