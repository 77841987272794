export const EMAIL_ID = 'email';
export const AMOUNT_ID = 'amount';
export const STATE_ID = 'state';
export const CITY_ID = 'city';
export const BILLING_CITY_ID = 'billingCity';
export const AMOUNT_SECTION_ID = 'topUpAmount';
export const PERSONALDETAILS_SECTION_ID = 'personalDetails';
export const DOCUMENT_ID = 'document';
export const BILLING_ADDRESS_SECTION_ID = 'billingAddressSection';
export const HOME_ADDRESS_SECTION_ID = 'homeAddressSection';
export const FULL_ADDRESS_ID = 'address';
export const FULL_BILLING_ADDRESS_ID = 'billingAddress';
export const STREET_ID = 'street';
export const HOUSE_ID = 'houseNumber';
export const ZIP_ID = 'zipCode';
export const USERNAME_ID = 'username';
export const FIRSTNAME_ID = 'firstName';
export const LASTNAME_ID = 'lastName';
export const PHONE_ID = 'phone';
export const CONFIRMATION_CODE_ID = 'confirmationCode';
export const PAYRAILS_REQUESTED_RESULT_ID = 'payrailsRequestedResult';

// Fields that can be saved
export type RegularField = (typeof REGULAR_FIELDS)[number];

export const REGULAR_FIELDS = [
    EMAIL_ID,
    STATE_ID,
    CITY_ID,
    DOCUMENT_ID,
    FULL_ADDRESS_ID,
    STREET_ID,
    HOUSE_ID,
    ZIP_ID,
    USERNAME_ID,
    FIRSTNAME_ID,
    LASTNAME_ID,
    PHONE_ID,
];

// Fields that cannot be saved
export const SECURED_FIELDS = [CONFIRMATION_CODE_ID];

type SecuredField = (typeof SECURED_FIELDS)[number];

// Fields that won't need to save
export const DYNAMIC_FIELDS = [AMOUNT_ID];

type DynamicField = (typeof DYNAMIC_FIELDS)[number];

// Section fields
export const SECTION_FIELDS = [
    AMOUNT_SECTION_ID,
    PERSONALDETAILS_SECTION_ID,
    BILLING_ADDRESS_SECTION_ID,
];

type SectionField = (typeof SECTION_FIELDS)[number];

export type FieldId = RegularField & SecuredField & DynamicField & SectionField;
