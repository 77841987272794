import { FC, useCallback, useRef, useState } from 'react';

import DlocalCard, { DlocalCardData } from '@/features/credit-card/dlocal-card';
import { CreditCardForm } from '@/lib/forms/actions';
import { Countries } from '@/lib/locale/countries';
import LogsService from '@/services/logs-service';
import { CashlessRedirectPayload } from '@/services/navigator-service';
import SubmitForm from '@/shared/ui/domain/submit-form';

import * as UI from './ui';

import Resources from '../../../../../resources';

type CashlessFormProps = {
    logsService: LogsService;
    onTokenData: (payload: CashlessRedirectPayload) => void;
    onError: (error: string) => void;
}

const cashlessKeyRouting = (country: Countries) => {
    switch (country) {
        case Countries.Brazil:
            return Resources.DLOCAL_API_KEY.CASHLESS.BRAZIL;
        default:
            return Resources.DLOCAL_API_KEY.DEFAULT;
    }
};

const CashlessForm: FC<CashlessFormProps> = ({
    logsService,
    onTokenData,
    onError,
}) => {
    const creditCardFormRef = useRef<CreditCardForm<DlocalCardData>>(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = useCallback(async () => {
        setLoading(true);


        if (!creditCardFormRef.current || !creditCardFormRef.current?.validate()) {
            setLoading(false);

            return;
        }

        const creditCardData = await creditCardFormRef.current.getData();
        if (creditCardData === null) {
            setLoading(false);

            return;
        }

        const { token, lastFour, binInfo } = creditCardData;

        onTokenData({
            token,
            bin: binInfo.bin,
            country: binInfo.country,
            lastFour: lastFour
        });
    }, []);

    return (
        <UI.Wrapper>
            <SubmitForm
                loading={loading}
                onSubmit={handleSubmit}
            >
                <DlocalCard
                    ref={creditCardFormRef}
                    country={Countries.Brazil} // Because cashless team working in Brazil only
                    apiKey={cashlessKeyRouting(Countries.Brazil)}
                    logsService={logsService}
                    onError={onError}
                />
            </SubmitForm>
        </UI.Wrapper>
    );
};

export default CashlessForm;
