import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import MarkdownViewer from '@/features/markdown-viewer';

type Kassa24TerminalProps = {
    referenceNumber: string;
    onCopy: (paycode: string, title: string) => void;
  }

const Kassa24Terminal: FC<Kassa24TerminalProps> = ({
    referenceNumber,
    onCopy,
}) => {
    const { t } = useTranslation();

    const handleCopyPaycode = useCallback(() => {
        onCopy(referenceNumber, t('views.PaymentMethods.Terminal.Kassa24.numberCopiedLabel'));
    }, [referenceNumber, onCopy]);

    return (
        <MarkdownViewer>
            {
                [
                    { type: MarkdownViewer.COPY_BLOCK, content: {
                        title: referenceNumber,
                        subtitle: t('views.PaymentMethods.Terminal.Kassa24.caption'),
                        onClick: handleCopyPaycode,
                    } },
                    { type: MarkdownViewer.TITLE, content: t('features.HowToTopUpInfo.title') },
                    {
                        type: MarkdownViewer.NUMBERED_LIST,
                        content: [
                            t('views.PaymentMethods.Terminal.Kassa24.instructionStep1'),
                            t('views.PaymentMethods.Terminal.Kassa24.instructionStep2'),
                            t('views.PaymentMethods.Terminal.Kassa24.instructionStep3')
                        ],
                    },
                ]
            }
        </MarkdownViewer>
    );
};

export default Kassa24Terminal;
