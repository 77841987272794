import styled from 'styled-components';

export const OneStepPaymentFormWrapper = styled.div`
  padding: 16px;
`;

export const PaymentPointsWrapper = styled.div`
  padding: 0 0 16px;
`;

export const AmountInputWrapper = styled.div`
  padding: 16px;
`;

export const SupportWrapper = styled.div`
  padding: 16px 0 0;
`;

export const AdditionalContent = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    box-sizing: border-box;
    padding: 16px;
    flex: 1;
`;

export const FlexSpacer = styled.div``;

export const SubmitWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    button {
        height: 56px;
        width: 100%;
    }
`;
