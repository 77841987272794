import i18n from 'i18next';
import { useNavigate } from 'react-router-dom';

import ErrorStatus from '@/lib/errors/status';
import { copyToClipboard } from '@/lib/forms/clipboard';
import AuthorizationService from '@/services/authorization-service';
import ConfigurationService, { Platform } from '@/services/configuration-service';
import LogsService from '@/services/logs-service';
import MobilePlatformService from '@/services/mobile-platform-service';
import NotificationService from '@/services/notification-service';

import Resources from '../../resources';

export type CashlessRedirectPayload = {
    token: string;
    bin: string;
    country: string;
    lastFour: string;
};

export type AppRoute = (typeof NavigatorService.AppRoutes)[keyof typeof NavigatorService.AppRoutes];

class NavigatorService {
    static AppRoutes = {
        MAIN: '/',

        INTERNAL_ERROR: '/internal-error',
        SUCCESS_PAGE: '/success-page',
        RETURN_PAGE: '/return-page',
        PROVIDER_ERROR: '/provider-error',
        PROFILE_ERROR: '/profile-error',

        CASHLESS: '/cashless',
        CASHLESS_FORM: '/cashless/dlocal-form',

        PCI_DSS_TEST_FORM: '/pcidss-test-form'
    };

    static LANGUAGE_PARAM = 'lng';
    static INDRIVER_CABINET_DEEPLINK = 'indriver://open/driver/cabinet';
    static INDRIVER_PROFILE_DEEPLINK = 'indriver://open/driver/profile';
    static INDRIVER_PROFILE_DATA_DEEPLINK = 'indrive://open/driver/profile?route=profile_changed';
    static INDRIVER_SUPPORT_DEEPLINK = 'indriver://open/driver/support';
    static INDRIVER_WALLET_DEEPLINK = 'indriver://open/driver/wallet';

    private redirect: ReturnType<typeof useNavigate>;
    private logsService: LogsService;
    private configurationService: ConfigurationService;
    private authorizationService: AuthorizationService;
    private notificationService: NotificationService;
    private mobilePlatformService: MobilePlatformService;

    constructor(
        redirect: ReturnType<typeof useNavigate>,
        logsService: LogsService,
        configurationService: ConfigurationService,
        authorizationService: AuthorizationService,
        notificationService: NotificationService,
        mobilePlatformService: MobilePlatformService,
    ) {
        this.redirect = redirect;
        this.logsService = logsService;
        this.configurationService = configurationService;
        this.authorizationService = authorizationService;
        this.notificationService = notificationService;
        this.mobilePlatformService = mobilePlatformService;
    }

    private buildUrlWithParams = (url: string, params?: Record<string, string>): string => {
        const currentParameters = new URLSearchParams(window.location.search);
        const lngParam = currentParameters.get(NavigatorService.LANGUAGE_PARAM);

        const urlParams = new URLSearchParams();

        if (params) {
            Object.entries(params).forEach(([key, value]) => {
                urlParams.append(key, value);
            });
        }

        if (lngParam) {
            urlParams.append(NavigatorService.LANGUAGE_PARAM, lngParam);
        }

        const rawParams = urlParams.toString();

        return `${url}${rawParams ? `?${rawParams}` : ''}`;
    };

    private parseUrlParams() {
        const params = new URLSearchParams(window.location.search);

        return Array.from(params.entries()).reduce<Record<string, string>>((query, parameter) => {
            query[parameter[0]] = parameter[1];

            return query;
        }, {});
    }

    public clearUrlParams = () => window.history.pushState({}, document.title, window.location.pathname);

    public getQuery(): Record<string, string> {
        return this.parseUrlParams();
    }

    public goOutside(url: string): void {
        window.open(url, '_self');
    }

    public goHome(): void {
        if (location.pathname === NavigatorService.AppRoutes.MAIN) {
            window.location.reload();

            return;
        }

        this.redirect(this.buildUrlWithParams(NavigatorService.AppRoutes.MAIN));
    }

    public goWallet(): void {
        const deviceType = this.configurationService.getPlatform();
        if (deviceType) {
            try {
                this.authorizationService.getJWT();
                this.goHome();

                return;
            } catch (e) {
                this.goOutside(NavigatorService.INDRIVER_PROFILE_DEEPLINK);

                return;
            }
        }

        this.goHome();
    }

    public goProfile(): void {
        this.goOutside(NavigatorService.INDRIVER_PROFILE_DATA_DEEPLINK);
    }

    public async showFinalPage(type: 'success' | 'error' | 'profile', params?: Record<string, string>): Promise<void> {
        if (type === 'success') {
            this.redirect(this.buildUrlWithParams(NavigatorService.AppRoutes.SUCCESS_PAGE));

            return;
        }

        if (type === 'error') {
            this.logsService.write(`${NavigatorService.AppRoutes.INTERNAL_ERROR} ${JSON.stringify(params || {})}`);
            this.redirect(this.buildUrlWithParams(NavigatorService.AppRoutes.INTERNAL_ERROR, params));

            if (params?.errorStatus === ErrorStatus.PAYMENT_PROVIDER_INTERNAL_SERVER_ERROR) {
                return;
            }

            return;
        }

        if (type === 'profile') {
            this.redirect(this.buildUrlWithParams(NavigatorService.AppRoutes.PROFILE_ERROR, params));

            return;
        }

        throw new Error('Please setup type of redirect');
    }

    public showPaymentMethod(): void {
        this.redirect(this.buildUrlWithParams(NavigatorService.AppRoutes.MAIN));
    }

    public openSupportChat(): void {
        this.goOutside(NavigatorService.INDRIVER_SUPPORT_DEEPLINK);
    }

    public openEmail(email: string): void {
        // TODO https://indriver.atlassian.net/browse/PRC-2879 сделать проверку версий приложения
        if (this.configurationService.getPlatform() === Platform.IOS) {
            copyToClipboard(email);
            this.notificationService.success(i18n.t('features.Support.supportEmailCopied'));
        } else {
            window.location.href = `mailto:${email}`;
        }
    }

    public openPhone(phone: string): void {
        window.location.href = `tel:${phone}`;
    }

    /**
     * Info
     * @link {https://indriver.atlassian.net/browse/PRC-1585}
     */
    public cashlessRedirect({ token, bin, country, lastFour }: CashlessRedirectPayload): void {
        // eslint-disable-next-line max-len
        window.location.replace(`${Resources.CASHLESS_LOADER_URL}?token=${token}&bin=${bin}&country=${country}&lastFour=${lastFour}`);
    }

    public showNativeBackArrow = () => {
        const jwt = this.authorizationService.getJWT();

        if (jwt.getToken().city_id === 5831) { // Turkey Batman
            this.mobilePlatformService.showNativeNavgationBar();
        }
    };

    public hideNativeBackArrow = () => {
        console.log('todo implement hide');
    };

    public onNativeBackArrowClick = (callback: VoidFunction) => {
        this.mobilePlatformService.addEventListener('onBackArrowLeftClick', callback);
    };
}

export default NavigatorService;
