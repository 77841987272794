import { CardType } from '@/lib/domain/payments/credit-card';

import Resources from '../../../../../resources';

export const getCardIcon = (
    cardType: string | CardType | null
): JSX.Element => {
    switch (cardType) {
        case CardType.AMEX:
            return (
                <img
                    src={Resources.files.cardtype.amex}
                    alt='amex'
                />
            );
        case CardType.DISCOVER:
            return (
                <img
                    src={Resources.files.cardtype.discover}
                    alt='discover'
                />
            );
        case CardType.DINERSCLUB:
            return (
                <img
                    src={Resources.files.cardtype.dinersclub}
                    alt='dinners'
                />
            );
        case CardType.JCB:
            return (
                <img
                    src={Resources.files.cardtype.jcb}
                    alt='jcb'
                />
            );
        case CardType.MASTERCARD:
            return (
                <img
                    src={Resources.files.cardtype.mastercard}
                    alt='mc'
                />
            );
        case CardType.MIR:
            return (
                <img
                    src={Resources.files.cardtype.mir}
                    alt='mir'
                />
            );
        case CardType.VISA:
            return (
                <img
                    src={Resources.files.cardtype.visa}
                    alt='visa'
                />
            );
        case CardType.MAESTRO:
            return (
                <img
                    src={Resources.files.cardtype.maestro}
                    alt='maestro'
                />
            );
        default:
            return (
                <img
                    src={Resources.files.cardtype.default}
                    alt='default'
                />
            );
    }
};
