import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import ConfigurationService from '@/services/configuration-service';
import LogsService from '@/services/logs-service';
import MobilePlatformService from '@/services/mobile-platform-service';
import NavigatorService from '@/services/navigator-service';

import CashlessPage from './pages/cashless-page';
import InternalErrorPage from './pages/internal-error-page';
import PCIDSSBankCardPage from './pages/pcidss-bank-card-page';
import ProfileErrorPage from './pages/profile-error-page';
import ProviderErrorPage from './pages/provider-error-page';
import ReturnPage from './pages/return-page';
import SuccessPage from './pages/success-page';

const NON_AUTH_PATHS = [
    NavigatorService.AppRoutes.SUCCESS_PAGE,
    NavigatorService.AppRoutes.RETURN_PAGE,
    NavigatorService.AppRoutes.INTERNAL_ERROR,
    NavigatorService.AppRoutes.PROVIDER_ERROR,
    NavigatorService.AppRoutes.PROFILE_ERROR,
    NavigatorService.AppRoutes.CASHLESS,
    NavigatorService.AppRoutes.CASHLESS_FORM,
    NavigatorService.AppRoutes.PCI_DSS_TEST_FORM,
] as const;

export const isNonAuth = () =>
    NON_AUTH_PATHS.includes(window.location.pathname as (typeof NON_AUTH_PATHS)[number]);

type NonAuthRouterProps = {
    mobilePlatformService: MobilePlatformService;
    navigatorService: NavigatorService;
    logsService: LogsService;
    configurationService: ConfigurationService;
}

const NonAuthRouter: FC<NonAuthRouterProps> = (props) => {
    return (
        <Routes>
            <Route
                path={NavigatorService.AppRoutes.SUCCESS_PAGE}
                element={<SuccessPage {...props} />}
            />

            <Route
                path={NavigatorService.AppRoutes.RETURN_PAGE}
                element={<ReturnPage {...props} />}
            />

            <Route
                path={NavigatorService.AppRoutes.INTERNAL_ERROR}
                element={<InternalErrorPage {...props} />}
            />

            <Route
                path={NavigatorService.AppRoutes.PROVIDER_ERROR}
                element={<ProviderErrorPage {...props} />}
            />

            <Route
                path={NavigatorService.AppRoutes.PROFILE_ERROR}
                element={<ProfileErrorPage {...props} />}
            />

            <Route
                path={NavigatorService.AppRoutes.CASHLESS}
                element={<CashlessPage {...props} />}
            />

            <Route
                path={NavigatorService.AppRoutes.CASHLESS_FORM}
                element={<CashlessPage {...props} />}
            />

            <Route
                path={NavigatorService.AppRoutes.PCI_DSS_TEST_FORM}
                element={<PCIDSSBankCardPage {...props} />}
            />
        </Routes>
    );
};

export default NonAuthRouter;
