import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import AdditionalFormFieldView from '@/features/additional-form-field-view';
import FormFieldView from '@/features/form-field-view';
import { FormValues } from '@/lib/domain/forms/forms';
import PaymentMethod from '@/lib/domain/payments/payment-method';
import { VendorName } from '@/lib/domain/payments/vendors';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { PaymentInfo } from '@/services/payment-info-service';
import TopupService from '@/services/topup-service';

type GenericCheckoutControllerProps = {
    amount: string;
    method: PaymentMethod<VendorName.Nuvei>;
    paymentInfo: PaymentInfo;
    topupService: TopupService;
}
const GenericCheckoutController: FC<GenericCheckoutControllerProps> = ({
    amount,
    method,
    paymentInfo,
    topupService,
}) => {
    const { t } = useTranslation();

    let title = '';

    if (
        method.isCash() ||
        method.isBankTransfer() ||
        method.isWallet()
    ) {
        title = t('views.PaymentMethods.getVoucherSubmitCaption');
    }

    const handleSubmit = async (values: FormValues): Promise<GQLErrorType> => {
        return topupService.topUp(
            amount,
            method,
            values,
            paymentInfo.country,
        );
    };

    if (method.additionalFormFields) {
        return (
            <AdditionalFormFieldView
                country={paymentInfo.country}
                savedDocuments={paymentInfo.savedDocuments}
                formFields={method.formFields}
                label={method.additionalFormFields?.label}
                additionalFields={method.additionalFormFields?.fields}
                onSubmit={handleSubmit}
            />
        );
    }

    return (
        <FormFieldView
            title={title}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={method.formFields}
            onSubmit={handleSubmit}
        />
    );
};

export default GenericCheckoutController;
