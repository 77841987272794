import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import MarkdownViewer from '@/features/markdown-viewer';
import { formatExpDate } from '@/lib/format/time';

type FawryCashProps = {
    referenceNumber: string;
    paymentExpiry: number;
    onCopy: (paycode: string, title: string) => void;
};

const FawryCash: FC<FawryCashProps> = ({
    referenceNumber,
    paymentExpiry,
    onCopy,
}) => {
    const { t } = useTranslation();
    const handleCopyPaycode = useCallback(() => {
        onCopy(referenceNumber, t('views.PaymentMethods.Cash.Fawry.referenceCopiedLabel'));
    }, [referenceNumber, onCopy]);

    return (
        <MarkdownViewer>
            {
                [
                    { type: MarkdownViewer.COPY_BLOCK, content: {
                        title: referenceNumber,
                        subtitle: t('views.PaymentMethods.Cash.Fawry.referenceTitle'),
                        onClick: handleCopyPaycode,
                    } },
                    { type: MarkdownViewer.ALERT,
                        content: t('views.PaymentMethods.Cash.Fawry.howToTopUpDescription', {
                            date: formatExpDate(new Date(paymentExpiry)),
                        })
                    },
                ]
            }
        </MarkdownViewer>
    );
};

export default FawryCash;
