import i18n from 'i18next';
import IMask from 'imask';

import FormField from '@/lib/domain/forms/forms/form-field';
import { VendorName } from '@/lib/domain/payments/vendors';
import Validation, { Validator } from '@/lib/forms/validation';
import { Countries } from '@/lib/locale/countries';

import { getDocumentMeta } from './documents';
import {
    FULL_ADDRESS_ID,
    AMOUNT_ID,
    FULL_BILLING_ADDRESS_ID,
    BILLING_CITY_ID,
    CITY_ID,
    CONFIRMATION_CODE_ID,
    DOCUMENT_ID,
    EMAIL_ID,
    FIRSTNAME_ID,
    HOUSE_ID,
    LASTNAME_ID,
    PHONE_ID,
    STATE_ID,
    STREET_ID,
    USERNAME_ID,
    ZIP_ID,
    PAYRAILS_REQUESTED_RESULT_ID,
} from './indentificators';

type CreacteFieldOptions = {
    validators?: Validator[];
    mask?: string | IMask.AnyMaskedOptions;
    formatter?: (value: string) => string;
    hide?: boolean;
};

type CreateFieldFunction = (options?: CreacteFieldOptions) => FormField;

export const getPhoneField: CreateFieldFunction = (options) => ({
    id: PHONE_ID,
    title: i18n.t('services.formBuilder.fields.phoneTitle'),
    mask: options?.mask,
    validators: options?.validators || [Validation.required],
});

export const getEmailField: CreateFieldFunction = (options) => ({
    id: EMAIL_ID,
    title: i18n.t('services.formBuilder.fields.emailTitle'),
    formatter: options?.formatter,
    validators: [Validation.required, Validation.email],
});

export const getAmountField: CreateFieldFunction = () => ({
    id: AMOUNT_ID,
    title: i18n.t('services.formBuilder.fields.amountTitle'),
    mask: Number,
    type: 'number',
    validators: [Validation.required],
});

export const getStateField: CreateFieldFunction = (options) => ({
    id: STATE_ID,
    title: i18n.t('services.formBuilder.fields.stateTitle'),
    validators: options?.validators || [Validation.required, Validation.stringSizeValidator(3, 50)],
});

export const getCityField: CreateFieldFunction = (options) => ({
    id: CITY_ID,
    title: i18n.t('services.formBuilder.fields.cityTitle'),
    validators: options?.validators || [Validation.required, Validation.stringSizeValidator(3, 50)],
});

export const getBillingCityField: CreateFieldFunction = (options) => ({
    id: BILLING_CITY_ID,
    title: i18n.t('services.formBuilder.fields.cityTitle'),
    validators: options?.validators || [Validation.required, Validation.stringSizeValidator(3, 50)],
});

export const getStreetField: CreateFieldFunction = (options) => ({
    id: STREET_ID,
    title: i18n.t('services.formBuilder.fields.streetTitle'),
    validators: options?.validators || [Validation.required, Validation.stringSizeValidator(3, 50)],
});

export const getHouseField: CreateFieldFunction = (options) => ({
    id: HOUSE_ID,
    title: i18n.t('services.formBuilder.fields.houseTitle'),
    mask: Number,
    validators: options?.validators || [Validation.required, Validation.stringSizeValidator(1, 16)],
});

export const getZipField: CreateFieldFunction = (options) => ({
    id: ZIP_ID,
    title: i18n.t('services.formBuilder.fields.zipTitle'),
    validators: options?.validators || [Validation.required, Validation.stringSizeValidator(1, 9)],
});

export const getFirstnameField: CreateFieldFunction = () => ({
    id: FIRSTNAME_ID,
    title: i18n.t('services.formBuilder.fields.firstnameTitle'),
    validators: [Validation.required, Validation.stringSizeValidator(3, 255)],
});

export const getLastnameField: CreateFieldFunction = () => ({
    id: LASTNAME_ID,
    title: i18n.t('services.formBuilder.fields.lastnameTitle'),
    validators: [Validation.required, Validation.stringSizeValidator(3, 255)],
});

export const getUsernameField: CreateFieldFunction = () => ({
    id: USERNAME_ID,
    title: i18n.t('services.formBuilder.fields.usernameTitle'),
    validators: [Validation.required, Validation.stringSizeValidator(3, 255)],
});

export const getFullAddressField: CreateFieldFunction = (options) => ({
    id: FULL_ADDRESS_ID,
    title: i18n.t('services.formBuilder.fields.fullAddressTitle'),
    validators: options?.validators || [Validation.required, Validation.stringSizeValidator(3, 255)],
});

export const getFullBillingAddressField: CreateFieldFunction = (options) => ({
    id: FULL_BILLING_ADDRESS_ID,
    title: i18n.t('services.formBuilder.fields.fullAddressTitle'),
    validators: options?.validators || [Validation.required, Validation.stringSizeValidator(3, 255)],
});

export const getConfirmationCodeField: CreateFieldFunction = () => ({
    id: CONFIRMATION_CODE_ID,
    title: i18n.t('services.formBuilder.fields.confirmationCodeTitle'),
    validators: [Validation.required, Validation.stringSizeValidator(1, 9)],
});

// TODO https://indriver.atlassian.net/browse/PRC-3074
export const getDocumentField = (country: Countries, provider?: VendorName) => {
    return {
        id: DOCUMENT_ID,
        ...getDocumentMeta(country, provider),
    };
};

export const getPayrailsRequestedResultField: CreateFieldFunction = (options) => ({
    id: PAYRAILS_REQUESTED_RESULT_ID,
    title: 'Payrails requested result',
    validators: [],
    hidden: options?.hide,
});
