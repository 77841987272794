import { FC, useCallback, useEffect, useState } from 'react';

import PaymentMethod  from '@/lib/domain/payments/payment-method';
import { copyToClipboard } from '@/lib/forms/clipboard';
import { Countries } from '@/lib/locale/countries';

import FawryCash from './fawry-cash';
import GiftyCash from './gifty-cash';
import ImePayWallet from './ime-pay-wallet';
import IziCash from './izi-cash';
import JetPayWallet from './jetpay-wallet';
import KaspiBankTransfer from './kaspi-bank-transfer';
import Kassa24Terminal from './kassa24-terminal';
import OnoiCash from './onoi-cash';
import PuntoxpressCash from './puntoxpress-cash';
import QiwiKzWallet from './qiwi-kz-wallet';
import QiwiKzTerminal from './qiwi-terminal';

import { AppData } from '../../../index';

type OneStepPaymentFormProps = {
    country: Countries;
    method: PaymentMethod<any>;
    services: AppData['services'];
}

const OneStepPaymentForm: FC<OneStepPaymentFormProps> = ({
    country,
    method,
    services
}) => {
    const [userId, setUserId] = useState<string>();

    useEffect(() => {
        const token = services.authorizationService.getJWT().getToken();

        if (!token) {
            return;
        }
        setUserId(token.user_id.toString());
    }, []);

    const handleCopyPaycode = useCallback((paycode: string, title: string) => {
        copyToClipboard(paycode);
        services.notificationService.success(title);
    }, []);

    const handleRedirect = useCallback((redirectUrl: string) => {
        services.navigatorService.goOutside(redirectUrl);
    }, []);

    if (method.isImePay()) {
        const referenceNumber = method.session.referenceNumber;
        const redirectUrl = method.session.redirectUrl;

        return (
            <ImePayWallet
                redirectUrl={redirectUrl}
                referenceNumber={referenceNumber ?? userId}
                onCopy={handleCopyPaycode}
                onRedirect={handleRedirect}
            />
        );
    }

    if (method.isPuntoxpress()) {
        const referenceNumber = method.session.referenceNumber;

        return (
            <PuntoxpressCash
                referenceNumber={referenceNumber ?? userId}
                country={country}
                onCopy={handleCopyPaycode}
            />
        );
    }

    if (method.isGifty()) {
        const referenceNumber = method.session?.referenceNumber;

        return (
            <GiftyCash
                referenceNumber={referenceNumber ?? userId}
                onCopy={handleCopyPaycode}
            />
        );
    }

    if (method.isKassa24()) {
        const referenceNumber = method.session?.referenceNumber;

        return (
            <Kassa24Terminal
                referenceNumber={referenceNumber ?? userId}
                onCopy={handleCopyPaycode}
            />
        );
    }

    if (method.isJetPay()) {
        const referenceNumber = method.session?.referenceNumber;
        const redirectUrl = method.session.redirectUrl;

        return (
            <JetPayWallet
                redirectUrl={redirectUrl}
                onRedirect={handleRedirect}
                referenceNumber={referenceNumber}
                onCopy={handleCopyPaycode}
            />
        );
    }

    if (method.isQiwiKz()) {
        const referenceNumber = method.session.referenceNumber;
        const tempRef = referenceNumber ?? userId;

        if (method.isTerminal()) {
            return (
                <QiwiKzTerminal
                    referenceNumber={tempRef}
                    onCopy={handleCopyPaycode}
                />
            );
        }

        if (method.isWallet()) {
            return (
                <QiwiKzWallet
                    referenceNumber={tempRef}
                    onCopy={handleCopyPaycode}
                />
            );
        }
    }

    if (method.isKaspiKz()) {
        const referenceNumber = method.session.referenceNumber;
        const redirectUrl = method.session.redirectUrl;
        const tempRef = referenceNumber ?? userId;

        return (
            <KaspiBankTransfer
                referenceNumber={tempRef}
                redirectUrl={redirectUrl}
                onRedirect={handleRedirect}
                onCopy={handleCopyPaycode}
            />
        );
    }

    if (method.isFawry()) {
        const referenceNumber = method.session?.referenceNumber;
        const paymentExpiry = method.session?.paymentExpiry;

        if (!paymentExpiry || !referenceNumber) {
            return null;
        }

        return (
            <FawryCash
                referenceNumber={referenceNumber}
                paymentExpiry={paymentExpiry}
                onCopy={handleCopyPaycode}
            />
        );
    }

    if (method.isIzi()) {
        const referenceNumber = method.session?.voucherReference;

        if (!referenceNumber) {
            return null;
        }

        return (
            <IziCash
                referenceNumber={referenceNumber}
                onCopy={handleCopyPaycode}
            />
        );
    }

    if (method.isOnoi()) {
        return (
            <OnoiCash
                referenceNumber={method.session.referenceNumber}
                onCopy={handleCopyPaycode}
            />
        );
    }

    return null;
};

export default OneStepPaymentForm;
