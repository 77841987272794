import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import YunoCard, { YunoCardData } from '@/features/credit-card/yuno-card';
import FormFieldView from '@/features/form-field-view';
import { FormValues } from '@/lib/domain/forms/forms';
import FormSection from '@/lib/domain/forms/forms/form-section';
import { YunoSecureFields } from '@/lib/domain/integrations/yuno/types';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { CreditCardForm } from '@/lib/forms/actions';
import { Countries } from '@/lib/locale/countries';

import { AppData } from '../../../../index';

type YunoNewBankCardViewProps = {
    secureFields: YunoSecureFields;
    country: Countries;
    savedDocuments: AppData['paymentInfo']['savedDocuments'];
    formFields: FormSection[];
    onSubmit: (values: FormValues) => Promise<GQLErrorType>;
}

const YunoNewBankCardView: FC<YunoNewBankCardViewProps> = ({
    secureFields,
    country,
    savedDocuments,
    formFields,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const creditCardFormRef = useRef<CreditCardForm<YunoCardData>>(null);

    const handleSubmit = async (paymentFillFormData: FormValues) => {
        if (!creditCardFormRef.current) {
            return;
        }

        if (!creditCardFormRef.current.validate()) {
            return;
        }

        const creditCardData = await creditCardFormRef.current.getData();
        if (creditCardData === null) {
            return;
        }

        const error = await onSubmit({
            ...creditCardData,
            ...paymentFillFormData
        });

        return error;
    };

    return (
        <FormFieldView
            country={country}
            savedDocuments={savedDocuments}
            formFields={formFields}
            onSubmit={handleSubmit}
            childrenTitle={t('views.PaymentMethods.BankCard.creditCardTitle')}
        >
            <YunoCard
                ref={creditCardFormRef}
                secureFields={secureFields}
            />
        </FormFieldView>
    );
};

export default YunoNewBankCardView;
