import { SecureField } from '@/lib/domain/payments/credit-card/securefields';

type FieldElementType = 'number' | 'expiration' | 'cvv';

type BaseEvent = {
    autofilled: boolean;
    complete: boolean;
    empty: boolean;
    error: null | {
        code: number;
        message: string;
        param: FieldElementType;
    }
};

type BlurEvent = {
    element: FieldElementType;
    hasFocus: boolean;
} & BaseEvent;

type FieldProps = {
    locale: string;
    country: string;
}

type CreateFieldOptions = {
    style?: {
        base: {
            fontSize?: string;
            fontFamily?: string;
            lineHeight?: string;
            fontSmoothing?: string;
            fontWeight?: string;
            color?: string;
            '::placeholder'?: {
                color: string;
            };
            iconColor?: string;
        };
    };
    placeholder?: string;
    maskInput?: boolean;
}

export enum DlocalFieldType {
    PAN = 'pan',
    CVV = 'cvv',
    CVV_ONLY = 'cvv-only',
    EXPIRATION = 'expiration',
}

type CreateTokenOptions = {
    name: string;
}

export type CreateTokenResponse = {
    token: string;
};

export type GetBinInformationResponse = {
    bin: string;
    country: string
    brand: string;
    type: string
}

export type GetLastFourResponse = {
    lastFour: string;
};

export type DlocalSecureField = SecureField<BaseEvent, BlurEvent>;

export type DlocalFields = {
    create: (type: DlocalFieldType, options: CreateFieldOptions) => DlocalSecureField;
}

export type DLocalInstance = {
    fields: (props: FieldProps) => DlocalFields;
    createToken: (cvv: DlocalSecureField, options: CreateTokenOptions) => Promise<CreateTokenResponse>;
    getBinInformation: (pan: DlocalSecureField) => Promise<GetBinInformationResponse>
    getLastFour: (pan: DlocalSecureField) => Promise<GetLastFourResponse>
}

export const getFieldOptions = (type: DlocalFieldType, theme: string): CreateFieldOptions => {
    const fontColor = theme === 'theme-dark' ? '#ffffff' : '#000000';

    switch (type) {
        case DlocalFieldType.PAN:
            return {
                style: {
                    base: {
                        fontSize: '16px',
                        fontFamily: 'Noto Sans, sans-serif',
                        lineHeight: '22px',
                        fontSmoothing: 'antialiased',
                        fontWeight: '500',
                        color: fontColor,
                        '::placeholder': {
                            color: fontColor,
                        },
                        iconColor: fontColor,
                    },
                },
                placeholder: ' ',
            };
        case DlocalFieldType.EXPIRATION:
            return {
                style: {
                    base: {
                        fontSize: '16px',
                        fontFamily: 'Noto Sans, sans-serif',
                        lineHeight: '22px',
                        fontSmoothing: 'antialiased',
                        fontWeight: '500',
                        color: fontColor,
                        '::placeholder': {
                            color: fontColor,
                        },
                        iconColor: fontColor,
                    },
                },
                placeholder: ' ',
            };
        case DlocalFieldType.CVV:
            return {
                style: {
                    base: {
                        fontSize: '16px',
                        fontFamily: 'Noto Sans, sans-serif',
                        lineHeight: '22px',
                        fontSmoothing: 'antialiased',
                        fontWeight: '500',
                        color: fontColor,
                        '::placeholder': {
                            color: fontColor,
                        },
                        iconColor: fontColor,
                    },
                },
                placeholder: ' ',
                maskInput: true,
            };
        case DlocalFieldType.CVV_ONLY:
            return {
                style: {
                    base: {
                        fontSize: '16px',
                        fontFamily: 'Noto Sans, sans-serif',
                        lineHeight: '22px',
                        fontSmoothing: 'antialiased',
                        fontWeight: '500',
                        color: fontColor,
                        '::placeholder': {
                            color: fontColor,
                        },
                        iconColor: fontColor,
                    },
                },
                placeholder: ' ',
            };
        default:
            return {};
    }
};
