import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import MarkdownViewer from '@/features/markdown-viewer';

type JetPayWalletProps = {
    referenceNumber: string;
    redirectUrl: string;
    onRedirect: (redirectUrl: string) => void;
    onCopy: (referenceNumber: string, title: string) => void;
}

const JetPayWallet: FC<JetPayWalletProps> = ({
    referenceNumber,
    redirectUrl,
    onRedirect,
    onCopy
}) => {
    const { t } = useTranslation();

    const handleCopyReferenceNumber = useCallback(() => {
        onCopy(referenceNumber, t('views.PaymentMethods.Wallet.JetPay.numberCopiedLabel'));
    }, [referenceNumber, onCopy]);

    const handleSubmit = useCallback(() => {
        onRedirect(redirectUrl);
    }, [redirectUrl]);

    return (
        <MarkdownViewer>
            {
                [
                    {
                        type: MarkdownViewer.COPY_BLOCK, content: {
                            title: referenceNumber,
                            subtitle: t('views.PaymentMethods.Wallet.JetPay.referenceNumberCaption'),
                            onClick: handleCopyReferenceNumber
                        }
                    },
                    { type: MarkdownViewer.TITLE, content: t('features.HowToTopUpInfo.title') },
                    {
                        type: MarkdownViewer.NUMBERED_LIST,
                        content: [
                            t('views.PaymentMethods.Wallet.JetPay.instructionStep1'),
                            t('views.PaymentMethods.Wallet.JetPay.instructionStep2'),
                            t('views.PaymentMethods.Wallet.JetPay.instructionStep3'),
                            t('views.PaymentMethods.Wallet.JetPay.instructionStep4')
                        ]
                    },
                    {
                        type: MarkdownViewer.SUBMIT, content: {
                            caption: t('views.PaymentMethods.Wallet.JetPay.submitCaption'),
                            onClick: handleSubmit
                        }
                    }
                ]
            }
        </MarkdownViewer>
    );
};

export default JetPayWallet;
