import {
    FC,
    forwardRef,
    Ref,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import { useTranslation } from 'react-i18next';

import SDK from '@/lib/domain/payments/sdk';
import { VendorName } from '@/lib/domain/payments/vendors';
import { CreditCardForm } from '@/lib/forms/actions';
import { ValidationError } from '@/lib/forms/validation';
import { Countries } from '@/lib/locale/countries';
import DlocalService, { DlocalCardInfo } from '@/services/dlocal-service';
import { DlocalError } from '@/services/dlocal-service/error';
import { DlocalFieldType } from '@/services/dlocal-service/options';
import LogsService from '@/services/logs-service';

import DlocalInput from '../../secure-field/dlocal';

import { DefaultInput } from './ui';

import CardHolder from '../../../shared/ui/domain/credit-card-field/card-holder';
import CreditCardOutline, { CreditCardViewType } from '../../../shared/ui/domain/credit-card-outline';
import CenterLoader from '../../../shared/ui/layout/center-loader';

export type DlocalCardData = DlocalCardInfo;

type DlocalCardProps = {
    ref: Ref<CreditCardForm<DlocalCardData>>;
    apiKey: string;
    country: Countries;
    cvvOnly?: boolean;
    logsService: LogsService;
    onError: (error: string) => void;
}

const DlocalCard: FC<DlocalCardProps> = forwardRef(({
    apiKey,
    country,
    cvvOnly,
    logsService,
    onError,
}, ref) => {
    const { t } = useTranslation();

    const [cardNumberError, setCardNumberError] = useState<ValidationError>();
    const [expirationError, setExpirationError] = useState<ValidationError>();

    const [cvvError, setCvvError] = useState<ValidationError>();

    const [cardHolder, setCardHolder] = useState('');
    const [cardHolderError, setCardHolderError] = useState<ValidationError>();
    const cardholderRef = useRef<CardHolder>(null);

    const [dlocalService, setDlocalService] = useState<DlocalService | undefined>();
    const [show, setShow] = useState(false);

    useEffect(() => {
        const sdk = new SDK(logsService);
        sdk.get(VendorName.Dlocal)
            .then((sdkRef) => {
                const service = new DlocalService(
                    country,
                    sdkRef(apiKey),
                    logsService,
                );

                service.addEventListener('ready', () => {
                    setShow(true);
                });
                setDlocalService(service);
            })
            .catch(onError);
    }, []);

    useImperativeHandle(ref, () => ({
        getCvvOnly: async () => {
            if (!dlocalService) {
                return null;
            }

            try {
                return await dlocalService.getCvvTokenOnly();
            } catch (e) {
                if (e instanceof DlocalError) {
                    if (e.isCvvError()) {
                        setCvvError({
                            message: e.getDlocalErrorMessage(),
                        });
                    }
                }
            }

            return null;
        },
        validate: (): boolean => {
            const cardholderErr = cardholderRef.current?.validate();

            return Boolean(cardholderErr);
        },
        setCardholderError: (error) => {
            if (error?.message) {
                setCardHolderError(error);
            }
        },
        getData: async () => {
            if (!dlocalService) {
                return null;
            }

            try {
                return await dlocalService.getCardInfo(cardHolder.trim());
            } catch (e) {
                if (e instanceof DlocalError) {
                    if (e.isCardNumberError()) {
                        setCardNumberError({
                            message: e.getDlocalErrorMessage(),
                        });
                    }

                    if (e.isExpError()) {
                        setExpirationError({
                            message: e.getDlocalErrorMessage(),
                        });
                    }

                    if (e.isCvvError()) {
                        setCvvError({
                            message: e.getDlocalErrorMessage(),
                        });
                    }

                    if (e.isCardHolderError()) {
                        setCardHolderError({
                            message: e.getDlocalErrorMessage(),
                        });
                    }
                }

                if (e instanceof Error) {
                    onError(e.message);
                }
            }

            return null;
        }
    }), [cardHolder, cardholderRef]);

    if (cvvOnly) {
        return (
            <>
                <CreditCardOutline
                    cvvOnly
                    cvvInput={
                        dlocalService ? (
                            <DlocalInput
                                dlocalService={dlocalService}
                                type={DlocalFieldType.CVV_ONLY}
                                onError={setCvvError}
                            />
                        ) : (
                            <DefaultInput />
                        )
                    }
                    cvvError={cvvError?.message}
                />
            </>
        );
    }

    return (
        <CenterLoader
            title={t('features.CreditCard.loadingTitle')}
            overlay
            loading={!show}
        >
            <CreditCardOutline
                cardNumberInput={
                    dlocalService ? (
                        <DlocalInput
                            dlocalService={dlocalService}
                            type={DlocalFieldType.PAN}
                            onError={setCardNumberError}
                        />
                    ) : (
                        <DefaultInput />
                    )
                }
                cardNumberError={cardNumberError?.message}
                expirationInput={
                    {
                        type: CreditCardViewType.COMMON,
                        children:
                            dlocalService ? (
                                <DlocalInput
                                    dlocalService={dlocalService}
                                    type={DlocalFieldType.EXPIRATION}
                                    onError={setExpirationError}
                                />
                            ) : (
                                <DefaultInput />
                            ),
                        expirationError: expirationError?.message,
                    }
                }
                cvvInput={
                    dlocalService ? (
                        <DlocalInput
                            dlocalService={dlocalService}
                            type={DlocalFieldType.CVV}
                            onError={setCvvError}
                        />
                    ) : (
                        <DefaultInput />
                    )
                }
                cvvError={cvvError?.message}
                cardHolderInput={
                    <CardHolder
                        ref={cardholderRef}
                        onError={setCardHolderError}
                        onChange={setCardHolder}
                    />
                }
                cardHolderError={cardHolderError?.message}
            />
        </CenterLoader>
    );
});

export default DlocalCard;
