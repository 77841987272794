import { gql } from '@apollo/client';

export type CreateCardDlocalMutation = {
    input: CreateCardDlocalInput,
}

type NewDlocalCard = {
    token: string;
    cardHolderName: string;
    amount: number;
    email: string;
    document: string;
    state?: string;
    city?: string;
    zipCode?: string;
    street?: string;
    houseNumber?: string;
}

type SavedDlocalCard = {
    cardId: string;
    token?: string; // Dlocal requires CVV only token for India for saved cards
    amount: number;
    document: string;
    email: string;
}

export type CreateCardDlocalInput = NewDlocalCard | SavedDlocalCard;

export type DlocalTokenizedCardData = Pick<NewDlocalCard, 'token' | 'cardHolderName'>;

export type CreateCardDlocalResponse = {
    createCardDlocal: {
        threeDSecureRedirectUrl?: string;
    }
}

export default gql(`
    mutation createCardDlocal($input: CreateCardDlocalInput!) {
        createCardDlocal(input: $input) {
            threeDSecureRedirectUrl
        }
    }
`);
