import i18n from 'i18next';

import FormField from '@/lib/domain/forms/forms/form-field';
import FormSection from '@/lib/domain/forms/forms/form-section';

import {
    getAmountField,
    getConfirmationCodeField,
} from './fields';
import {
    BILLING_ADDRESS_SECTION_ID,
    PERSONALDETAILS_SECTION_ID,
    AMOUNT_SECTION_ID,
    HOME_ADDRESS_SECTION_ID
} from './indentificators';

export const getAmountSection = () => new FormSection(
    AMOUNT_SECTION_ID,
    i18n.t('services.formBuilder.sections.amountTitle'),
    [getAmountField()],
);

export const getPersonalDetailsSection = (fields: FormField[]) => new FormSection(
    PERSONALDETAILS_SECTION_ID,
    i18n.t('services.formBuilder.sections.personalDetailTitle'),
    fields,
);

export const getBillingAddressSection = (fields: FormField[]) => new FormSection(
    BILLING_ADDRESS_SECTION_ID,
    i18n.t('services.formBuilder.sections.billingTitle'),
    fields,
);

export const getHomeAddressSection = (fields: FormField[]) => new FormSection(
    HOME_ADDRESS_SECTION_ID,
    i18n.t('services.formBuilder.sections.homeAddress'),
    fields,
);

export const getCodeConfirmationSections =  () => ([
    getPersonalDetailsSection([
        getConfirmationCodeField(),
    ])
]);
