import { CardType } from '@/lib/domain/payments/credit-card';
import EventEmitter from '@/lib/subscriber';

declare global {
    interface Window {
        indriveSecure: {
            collect: new (options: {
                env: 'prod' | 'dev';
            }) => InDriveSecureCollect;
            apiReady: boolean;
        }
    }
}

type InDriveSecureCollect = {
    createToken: (cardholderName: string) => Promise<string>;
    createFields: (options: {
        cardnumberId: string,
        expirationId: string,
        cvvId: string
    }) => void;
}

type CardNumberError = 'required' | 'invalid_cardnumber';
type ExpirationError = 'required' | 'invalid_expiration_month' | 'invalid_expiration_date' | 'date_expired';
type CvvError = 'required' | 'incorrect_cvv';

class InDriverSecureService extends EventEmitter<{
    'cardnumber-input': {
        cardType: CardType;
    };
    'cardnumber-error': {
        type: CardNumberError;

    };
    'cardnumber-complete': void;
    'expiration-input': void;
    'expiration-error': {
        type: ExpirationError
    };
    'expiration-complete': void;
    'cvv-input': void;
    'cvv-error': {
        type: CvvError;
    };
    'cvv-complete': void;
    'on-token': string;
    'on-token-error': void;
}> {
    private instance: InDriveSecureCollect;

    constructor(collectSdk: InDriveSecureCollect) {
        super();
        this.instance = collectSdk;

        this.startListenFrames();
    }

    private startListenFrames() {
        window.addEventListener('message', ({ data }) => {
            if (!data?.inDriverFrameEventFlag) {
                return;
            }

            switch (data.event) {
                case 'indrivesfield-on-cardnumber-input':
                    this.dispatch('cardnumber-input', data.data);
                    break;
                case 'indrivesfield-on-cardnumber-error':
                    this.dispatch('cardnumber-error', data.data.error);
                    break;
                case 'indrivesfield-on-cardnumber-complete':
                    this.dispatch('cardnumber-complete', data.data);
                    break;
                case 'indrivesfield-on-expiration-input':
                    this.dispatch('expiration-input', data.data);
                    break;
                case 'indrivesfield-on-expiration-error':
                    this.dispatch('expiration-error', data.data.error);
                    break;
                case 'indrivesfield-on-expiration-complete':
                    this.dispatch('expiration-complete', data.data);
                    break;
                case 'indrivesfield-on-cvv-input':
                    this.dispatch('cvv-input', data.data);
                    break;
                case 'indrivesfield-on-cvv-error':
                    this.dispatch('cvv-error', data.data.error);
                    break;
                case 'indrivesfield-on-cvv-complete':
                    this.dispatch('cvv-complete', data.data);
                    break;
                case 'indrivesfield-on-token':
                    this.dispatch('on-token', data.cardToken);
                    break;
                case 'indrivesfield-on-token-error':
                    this.dispatch('on-token-error', undefined);
                    break;
            }
        });
    }

    public createToken = (cardholderName: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            this.addEventListener('on-token', (cardToken: string) => {
                resolve(cardToken);
            });

            this.addEventListener('on-token-error', () => {
                reject();
            });

            this.instance.createToken(cardholderName);
        });
    };

    public createFields = (options: {
        cardnumberId: string,
        expirationId: string,
        cvvId: string,
        style?: Record<string, string>,
    }): void => {
        return this.instance.createFields(options);
    };
}

export default InDriverSecureService;
