import { variables } from '@indriver/yrel';
import styled from 'styled-components';

import {
    Header24Bold,
    Text16Medium,
    Text18Bold
} from '@/shared/typography/text';

export const Alert = styled.div`
  ${Text16Medium};

  color: ${variables['extensions-text-and-icon-onbrand']};
  background-color: ${variables['extensions-background-lightwarning']};
  padding: 12px 16px;
  margin: 24px 0;
  border-radius: 16px;
`;

export const Title = styled.div`
  ${Text18Bold};

  margin-bottom: ${variables['spacers-m']};
`;

export const Subtitle = styled.div`
    ${Text16Medium};

    margin-top: 12px 0;
`;

export const Paragraph = styled.div`
    ${Text16Medium};

    margin: 24px 0;
`;

export const NumberedList = styled.div`
  ${Text16Medium};

  ol {
    list-style: decimal;
    margin-bottom: 12px;

  }

  ul {
    list-style: disc;
  }

  margin-bottom: 11px;
`;


export const BulletList = styled.div`
  ${Text16Medium};

  ul {
    list-style: disc;
    list-style-position: inside;
  }

  margin-bottom: 11px;
`;

export const SubmitWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 12px;

  button {
    height: 56px;
    width: 100%;
  }
`;

export const CopyBlockWrapper = styled.div`
  background: ${variables['background-secondary']};
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 16px;
  margin-top: 16px;
  margin-bottom: 24px;
  cursor: pointer;
  & svg > path {
    fill: ${variables['text-and-icon-primary']};
  }
`;

export const CopyBlockTitle = styled.div`
  ${Header24Bold};
`;

export const CopyBlockSubtitle = styled.div`
  ${Text16Medium};
  color: ${variables['text-and-icon-secondary']};
`;

export const CopyBlockContent = styled.div`
  width: 100%;
`;
