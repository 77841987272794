import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import MarkdownViewer from '@/features/markdown-viewer';

type GiftyCashProps = {
    referenceNumber: string;
    onCopy: (paycode: string, title: string) => void;
};

const GiftyCash: React.FC<GiftyCashProps> = ({
    referenceNumber,
    onCopy,
}) => {
    const { t } = useTranslation();

    const handleCopyPaycode = useCallback(() => {
        onCopy(referenceNumber, t('views.PaymentMethods.Cash.GiftyTopUpSteps.referenceCopiedLabel'));
    }, [referenceNumber, onCopy]);

    return (
        <MarkdownViewer>
            {
                [
                    { type: MarkdownViewer.COPY_BLOCK, content: {
                        title: referenceNumber,
                        subtitle: t('views.PaymentMethods.Cash.GiftyTopUpSteps.referenceNumberLabel'),
                        onClick: handleCopyPaycode,
                    } },
                    { type: MarkdownViewer.TITLE, content: t('features.HowToTopUpInfo.title') },
                    {
                        type: MarkdownViewer.NUMBERED_LIST,
                        content: [
                            t('views.PaymentMethods.Cash.GiftyTopUpSteps.stepOne'),
                            t('views.PaymentMethods.Cash.GiftyTopUpSteps.stepTwo'),
                            t('views.PaymentMethods.Cash.GiftyTopUpSteps.stepThree'),
                            t('views.PaymentMethods.Cash.GiftyTopUpSteps.stepFour')
                        ],
                    },
                ]
            }
        </MarkdownViewer>

    );
};

export default GiftyCash;
