import IMask from 'imask';

import { FormId } from '@/lib/domain/forms/forms/index';
import { Validator } from '@/lib/forms/validation';

class FormField {
    public id: FormId;
    public title: string;
    public mask?: string | typeof Number | typeof String | IMask.AnyMaskedOptions;
    /** @deprecated */
    public type?: string;
    public formatter?: (value: string) => string;
    public validators: Validator[];
    public hidden?: boolean;

    constructor(init: FormField) {
        this.id = init.id;
        this.title = init.title;
        this.mask = init.mask;
        /** @deprecated */
        this.type = init.type;
        this.formatter = init.formatter;
        this.validators = init.validators;
        this.hidden = init.hidden;
    }

    static create = (options: {
        id: FormId;
        mask?: string | typeof Number | typeof String | IMask.AnyMaskedOptions;
        title: string;
        /** @deprecated */
        type?: string;
        formatter?: (value: string) => string;
        validators: Validator[];
        hidden?: boolean;
    }) => {
        return new FormField({
            id: options.id,
            title: options.title,
            mask: options.mask,
            type: options.type,
            formatter: options.formatter,
            validators: options.validators,
            hidden: options.hidden,
        });
    };
}

export default FormField;
