import { Transfer, Card, Calculate, Money2, Mobile, BankAccount  } from '@indriver/mireska';
import { RoundIcon } from '@indriver/nova';
import { FC } from 'react';

import PaymentMethod  from '@/lib/domain/payments/payment-method';
import Vendors from '@/lib/domain/payments/vendors';

import * as UI from './ui';


const BRAND_IMAGE_TYPE = 'brand-image';
const UI_ICON_TYPE = 'ui-icon';

type Icon = {
    type: typeof BRAND_IMAGE_TYPE;
    src: string;
} | {
    type: typeof UI_ICON_TYPE;
    element: typeof Transfer;
}

const DEFAULT_ICON: Icon = {
    type: UI_ICON_TYPE,
    element: Transfer,
};

const getProviderImg = (method: PaymentMethod<any>): Icon | null => {
    let brandImg = Vendors.getBrandImg(method.methodName);

    if (!brandImg) {
        brandImg = Vendors.getBrandImg(method.providerName);
    }

    if (brandImg) {
        return {
            type: BRAND_IMAGE_TYPE,
            src: brandImg,
        };
    }

    return null;
};

const getMethodIconType = (method: PaymentMethod<any>): Icon => {
    const icon = getProviderImg(method);

    if (icon !== null) {
        return icon;
    }

    if (method.isBankCard()) {
        return {
            type: UI_ICON_TYPE,
            element: Card,
        };
    } else if (method.isBankTransfer()) {
        return {
            type: UI_ICON_TYPE,
            element: BankAccount,
        };
    } else if (method.isCash()) {
        return {
            type: UI_ICON_TYPE,
            element: Money2,
        };
    } else if (method.isTerminal()) {
        return {
            type: UI_ICON_TYPE,
            element: Calculate,
        };
    } else if (method.isPhoneBalance()) {
        return {
            type: UI_ICON_TYPE,
            element: Mobile,
        };
    } else {
        return DEFAULT_ICON;
    }
};

type PaymentMethodIconProps = {
    method?: PaymentMethod<any>;
}

const PaymentMethodIcon: FC<PaymentMethodIconProps> = ({ method }) => {
    const icon = method ? getMethodIconType(method) : DEFAULT_ICON;

    if (icon.type === BRAND_IMAGE_TYPE) {
        return (
            <RoundIcon content={<UI.Image src={icon.src} />} />
        );
    }

    return (
        <RoundIcon
            design='secondary'
            content={<icon.element size={40} />}
        />
    );
};

export default PaymentMethodIcon;
