import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import MarkdownViewer from '@/features/markdown-viewer';

type QiwiKzWalletProps = {
    onCopy: (paycode: string, title: string) => void;
    referenceNumber: string;
}

const QiwiKzWallet: FC<QiwiKzWalletProps> = ({
    referenceNumber,
    onCopy,
}) => {
    const { t } = useTranslation();

    const handleCopyPaycode = useCallback(() => {
        onCopy(referenceNumber, t('views.PaymentMethods.Cash.QiwiKz.numberCopiedLabel'));
    }, [referenceNumber, onCopy]);

    return (
        <MarkdownViewer>
            {
                [
                    { type: MarkdownViewer.COPY_BLOCK, content: {
                        title: referenceNumber,
                        subtitle: t('views.PaymentMethods.Cash.QiwiKz.caption'),
                        onClick: handleCopyPaycode,
                    } },
                    { type: MarkdownViewer.TITLE, content: t('features.HowToTopUpInfo.title') },
                    {
                        type: MarkdownViewer.NUMBERED_LIST,
                        content: [
                            t('views.PaymentMethods.Cash.QiwiKz.instructionStep1'),
                            t('views.PaymentMethods.Cash.QiwiKz.instructionStep2'),
                            t('views.PaymentMethods.Cash.QiwiKz.instructionStep3'),
                            t('views.PaymentMethods.Cash.QiwiKz.instructionStep4')
                        ],
                    },
                ]
            }
        </MarkdownViewer>
    );
};

export default QiwiKzWallet;
